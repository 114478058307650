import React, { useRef, useState, useCallback } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
//import TreeView from "devextreme-react/tree-view";
//import Box, { Item } from 'devextreme-react/box'
import {TreeList} from "devextreme-react/tree-list";
import {Column, Pager, Paging, Scrolling, Selection} from "devextreme-react/tree-list";
import {FilterRow} from "devextreme-react/tree-list";
import './points-tree.scss'
export default function PointsTreeHook(props) {
  const treeDataSource = props.points
  const selectionMode = props.selectionMode
  const onSelect = props.onSelect

  let treeViewRef = useRef();
  const [treeBoxValue, setTreeBoxValue] = useState([]);
  const [opened, setOpened] = useState(false)

  const syncSelection = useCallback((treeView) => {
    //console.log('select')
    try {
        const selectedEmployees = treeView//.getSelectedNodes()
        //.map((node) => node.itemData);
        //setSelectedItems(selectedEmployees)
        //if (selectionMode === 'single') setIsBoxOpen(false)
        onSelect(selectedEmployees)
        
        //console.log(selectedEmployees)
    } catch (e) {
        onSelect([])
    }
   
  },[onSelect])

  const syncTreeViewSelection = useCallback(
    (e) => {
      let treeView =
        (e.component.selectItem && e.component) ||
        (treeViewRef && treeViewRef.instance);
      //syncSelection(e.component)
      if (treeView) {
        if (e.value === null) {
          treeView.unselectAll();
        } else {
          let values = e.value || treeBoxValue;
          values &&
            values.forEach(function (value) {
              treeView.selectItem(value);
            });
        }
        
      }

      if (e.value !== undefined) {
        //setTreeBoxValue(e.value.map(obj => obj.id));
      }
    },
    [treeBoxValue]
  );

  const treeView_itemSelectionChanged = useCallback(
    (e) => {
      setTreeBoxValue(e.component.getSelectedRowsData(selectionMode).map((obj) => obj.id));//e.selectedRowsData //e.component.getSelectedNodeKeys()
      syncSelection(e.component.getSelectedRowsData(selectionMode))//e.selectedRowsData //e.component
      if (selectionMode === 'single') setOpened(false)
    },
    [setTreeBoxValue,syncSelection,selectionMode]
  );


  let treeViewRender = useCallback(() => {
    return (
      //<ScrollView width='100%' height='100%'>
        <TreeList
            height="100%"
            id="tree-list-energoobjects"
            ref={treeViewRef}
            dataSource={treeDataSource}
            autoExpandAll={true}
            columnAutoWidth={true}
            showBorders={true}
            dataStructure="plain"
            parentIdExpr="pId"
            keyExpr="id"
            onSelectionChanged={treeView_itemSelectionChanged}
            //itemsExpr="items"
        >
            <FilterRow visible={true} />
            <Selection mode={selectionMode} recursive={true}/>
            <Scrolling preloadEnabled={true} mode="virtual" />
            <Paging
                enabled={true}
                defaultPageSize={20} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={true}
                showInfo={true} />
            {/* <Column
                dataField="id" /> */}
            <Column
                dataField="t"
                caption="Название">
            </Column>
        </TreeList>
      //</ScrollView>
      // <TreeView
      //   dataSource={treeDataSource}
      //   ref={treeViewRef}
      //   dataStructure="plain"
      //   keyExpr="id"
      //   parentIdExpr="pId"
      //   selectionMode={selectionMode}
      //   showCheckBoxesMode="normal"
      //   selectNodesRecursive={true}
      //   displayExpr="t"
      //   selectByClick={true}
      //   //onContentReady={syncTreeViewSelection}
      //   onItemSelectionChanged={treeView_itemSelectionChanged}
      //   // searchMode={'contains'}
      //   // searchExpr={['search_tu','num_pu']}
      //   // searchEnabled={true}
      //   // searchEditorOptions={{placeholder: 'Найти по ТУ и ПУ..'}}
      //   noDataText={'Результатов не найдено'}
      // />
    );
  }, [treeView_itemSelectionChanged, treeDataSource, selectionMode]);

  return (
    <div>
      {/*<Box*/}
      {/*  direction="row"*/}
      {/*  width="100%"*/}
      {/*  className={'tree-box'}*/}
      {/*>*/}
      {/*  <Item ratio={1}>*/}
          <DropDownBox
              id={'ddb'}
            value={treeBoxValue}
            valueExpr="id"
            displayExpr="t"
            placeholder="Выберите объекты.."
            showClearButton={false}
            dataSource={treeDataSource}
            onValueChanged={syncTreeViewSelection}
            contentRender={treeViewRender}
            disabled={treeDataSource === null}
            // dropDownOptions={{ minHeight:200}}
            deferRendering={false}
            // onOpened={}
            opened={opened}
            onOptionChanged={(e)=>{if (e.name === 'opened') setOpened(e.value)}}
          />
      {/*  </Item>*/}
      {/*</Box>*/}
    </div>
  );
}
