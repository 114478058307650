const authStorage = 'authData'
const dogovorStorage = 'lastDogovor'
const userStorage = 'lastUser'
const adrdogStorage = 'lastAgrdog'

const setLocalAuth = (access, refresh, fingerprint) => {
    localStorage.setItem(authStorage, JSON.stringify({
        access: access, refresh: refresh, fingerprint: fingerprint
    }))
}

const getLocalAuth = () => {
    return localStorage.getItem(authStorage) ? JSON.parse(localStorage.getItem(authStorage)) : null
}

const removeLocalAuth = () => {
    localStorage.removeItem(authStorage)
}

const setLocalDogovor = (dogovor) => {
    localStorage.setItem(dogovorStorage, JSON.stringify(dogovor))
}

const getLocalDogovor = () => {
    return localStorage.getItem(dogovorStorage) !== null ? JSON.parse(localStorage.getItem(dogovorStorage)) : null
}

const removeLocalDodovor = () => {
    localStorage.removeItem(dogovorStorage)
}

const setLocalUser = (data) => {
    localStorage.setItem(userStorage,JSON.stringify({id: data.id}))
}

const getLocalUser = () => {
    return localStorage.getItem(userStorage) !== null ? JSON.parse(localStorage.getItem(userStorage)) : null
}

const setLocalAgrDogovor = (agrdog) => {
    localStorage.setItem(adrdogStorage, JSON.stringify(agrdog))
}

const getLocalAgrDogovor = () => {
    return localStorage.getItem(adrdogStorage) !== null ? JSON.parse(localStorage.getItem(adrdogStorage)) : null
}

const removeLocalAgrDodovor = () => {
    localStorage.removeItem(adrdogStorage)
}

const StorageService = {
    setLocalAuth,
    getLocalAuth,
    removeLocalAuth,
    setLocalDogovor,
    getLocalDogovor,
    removeLocalDodovor,
    setLocalUser,
    getLocalUser,
    setLocalAgrDogovor,
    getLocalAgrDogovor,
    removeLocalAgrDodovor
}

export default StorageService