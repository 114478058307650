import request from './api'

export async function getRegions(){
    try {
        const data = await request('/outer/regions', 'POST', null)
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}