import SelectPeriod from "../../components/select-period/select-period"
import { getLastDayOfMounth } from "../../utils/date.utils"
import DataGrid, {
    Column,
    Format,
    Grouping,
    Pager,
    Paging
  } from 'devextreme-react/data-grid'
import React, { useEffect, useState } from "react"
import { useAuth } from '../../contexts/auth'
import { getDogovorOpls } from "../../api/dogovor"
import LoadPanel from 'devextreme-react/load-panel'
import notify from "devextreme/ui/notify"
import { usePeriod } from "../../contexts/period"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill1} from "@fortawesome/free-solid-svg-icons";
import { verifyAccess } from "../../utils/admin.utils"
import AccessDenied from "../../components/access-denied/access-denied"

const Pays = () => {

    const { periodStart, periodEnd } = usePeriod()
    const [loading, setLoading] = useState(false)
    const [opls, setOpls] = useState([])
    const {selectedDog} = useAuth()
    
    
    const pageSizes = [10, 25, 50, 100]

    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [3]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            setOpls([])
            const kodDog = selectedDog.kodDog
            setLoading(true)
            const res = await getDogovorOpls(kodDog,periodStart,getLastDayOfMounth(periodEnd))
            if (res.isOk) {
                setOpls(res.data)
            } else {
                notify(res.message, 'error', 5000)
            }
            setLoading(false)
        })()
    },[selectedDog,periodStart,periodEnd])

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            {/*<h5>Оплаты</h5>*/}
            {/*<div className="row">*/}
            {/*    <SelectPeriod mode={'multiple'}/>*/}
            {/*    <Button text="Показать" icon="refresh" onClick={getOpls}></Button>*/}
            {/*</div>*/}

            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faMoneyBill1} />
            <h3><strong>Оплаты</strong></h3>
            <div className="mt20 mb20">
                <SelectPeriod mode={'multiple'}/>
            </div>
            {!loading ? 
                <DataGrid
                    dataSource={opls}
                    keyExpr="kodLink"
                    //columnHidingEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    //onContentReady={this.onContentReady}
                    wordWrapEnabled={true}
                    noDataText="Нет оплат за указанный период"
                >
                    {/* <GroupPanel visible={true} /> */}
                    {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
                    <Grouping autoExpandAll={false} />

                    <Column dataField="datOpl" caption="Дата оплаты" dataType="date" width="120px"/>
                    <Column dataField="datFact" caption="Дата поступления" dataType="date" width="100px"/>
                    <Column dataField="opl" caption="Оплата" dataType="numeric" width="150px">
                        <Format
                            type="currency"
                            precision={2}
                            currency={'RUB'}
                        />
                    </Column>
                    {/*format={"#0.##"}*/}
                    <Column dataField="bistName" caption="Банк" dataType="string" />
                    <Column dataField="remark" caption="Назначение" dataType="string" />

                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                    <Paging defaultPageSize={10} />
                </DataGrid>
                :
                <LoadPanel visible={loading} />
            }
        </div>
        : <AccessDenied/>}</>
    )
}

export default Pays
