import {faLock} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "devextreme-react/button";
import { useReqPopup } from "../../contexts/req-popup";


const AccessDenied = () => {

    const {openReqPopup} = useReqPopup()

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faLock} />
            <h3><strong>Доступ отсутствует!</strong></h3>
            <strong>На выбранном договоре у Вас недостаточно прав для отображения этой страницы.</strong>
            <br></br>
            <br></br>
            <Button text="Подать заявку на изменение прав" icon="edit" type="default" onClick={()=>openReqPopup(true,7)}></Button>
        </div>
    )
}

export default AccessDenied