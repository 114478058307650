import {SelectBox} from "devextreme-react/select-box"
import {Button} from "devextreme-react/button"
import Popup from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import Form, {
    ButtonItem,
    ButtonOptions,
    EmptyItem,
    GroupItem,
    Item,
    Label,
    NumericRule,
    RequiredRule,
    StringLengthRule
} from "devextreme-react/form"
import {useEffect, useRef, useState} from "react"
import {
    addFilesToOldReq,
    checkINN,
    getDogovorZip,
    getFileTypes,
    getFinalDogovor,
    getMessages,
    getRequestAttach,
    getRequests,
    sendMessage,
    sendNewdogRequest
} from "../../api/newdog"
import notify from "devextreme/ui/notify"
import LoadIndicator from "devextreme-react/load-indicator"
import LoadPanel from 'devextreme-react/load-panel'
import {formatTimestamp} from "../../utils/string.utils"
import {useAuth} from "../../contexts/auth"
import './add-dogovor.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines, faKey} from "@fortawesome/free-solid-svg-icons";
import {PopupTitleWithIcon} from "../../components/popup-title-with-icon/popup-title-with-icon";
import {getResource} from "../../api/resource"
import NewdogFilesList from "../../components/newdog-files/newdog-files-list"
import {useReqPopup} from "../../contexts/req-popup"
import NewdogFiles from "../../components/newdog-files/newdog-files"
import {getRegions} from "../../api/outer"
import PopSelectCert from "../../components/cryptopro/PopSelectCert"

const AddDogovorPage = () => {

    const [reqVisible, setReqVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState(false)
    const [reqs, setReqs] = useState([])
    const [selectedReq, setSelectedReq] = useState(null)
    const [selectedFile, setSelectedFile] = useState([])
    const [messages, setMessages] = useState([])
    const [checkedInn, setCheckedInn] = useState(null)
    const [labelCheckInn, setLabelCheckInn] = useState(null)
    const [policyUrl, setPoilicyUrl] = useState(null)
    const [fileTypes, setFileTypes] =useState([])
    const [visibleAddOldFiles, setVisibleAddOldFiles] = useState(false)
    const [nameCompany, setNameCompany] = useState(null)
    const [regions, setRegions] = useState([])
    const [finalDog, setFinalDog] = useState(null)

    const formData = useRef({})
    const innData = useRef({})
    const messageFormData = useRef({})
    const { user, selectedDog } = useAuth()
    const {newdogFiles, setNewdogFiles} = useReqPopup()

    const dogovorTypes = [
        {id: 1, name: 'Договор энергоснабжения'},
        {id: 5, name: 'Договор купли-продажи'}
    ]

    const [inn, setInn] = useState(null)
    const innEditorOptions = {
        value: inn,
        format: '#',
        valueChangeEvent:"keyup",
        onValueChanged: function(e) {
            setInn(e.value)
            e.value && setInn(parseInt(e.value.toString().substr(0,12)))
        }
    }
   
    const createNewReq = () => {setReqVisible(true)}
    //const openConfiguratorPopup = () => {setConfiguratorVisible(true)}

    const onSendRequest = async (e) => {
        e.preventDefault();
        const {/*dogovorType,*/ region, nameCompany, kpp, kppAuto, textMessage, checkPolicy, fio, phone} = formData.current
        //const {checkPolicy} = finalForm.current
        const {inn} = innData.current
        //console.log(nameCompany,kpp,textMessage,aktTehnFile,aktBalanceFile,aktVvodFile,aktPravoFile, checkPolicy)

        const kppVal = checkedInn ? kppAuto : kpp
        if (!checkPolicy) {
            return
        }

        if (newdogFiles.length === 0) {
            notify('Необходимо прикрепить хотя бы один документ!', 'error', 5000)
            return
        }

        const fd = new FormData()
        //fd.append('dogovorType', dogovorType)
        fd.append('region', region)
        fd.append('nameCompany',nameCompany)
        fd.append('inn',inn)
        fd.append('kpp', kppVal)
        fd.append('contact', '')
        fd.append('fio', fio)
        fd.append('phone', phone)
        fd.append('textMessage',textMessage ? textMessage : null)
        //прикрепляем все файлы к заявке
        let docTypes = []
        for (let i=0; i<newdogFiles.length; i++){
            fd.append('document',newdogFiles[i].file)
            //docTypes.push({file: i, type: filesSource[i].type_id})
            docTypes.push(newdogFiles[i].type_id)

        }
        fd.append('doc_types', docTypes)
        
        setLoading(true)
        const result = await sendNewdogRequest(fd)
        if (result.isOk) {
            setReqVisible(false)
            hidingPopup()
            notify(result.message,'success',10000)
            //обновим список заявок
            const res = await getRequests()
            if (res.isOk) {
                setReqs(res.data)
                if (res.data && res.data.length > 0) {
                    setSelectedReq(res.data[0])
                    //обновим переписку
                    setLoadingMessage(true)       
                    const resMessages = await getMessages(res.data[0].id)
                    if (resMessages.isOk) {
                        setMessages(resMessages.data)
                    } else notify(resMessages.message, 'error', 10000)
                    setLoadingMessage(false)
                }
            }
        } else notify(result.message,'error',10000)

        setLoading(false)
    }

    function hidingPopup() {
        setReqVisible(false)
        setCheckedInn(null)
        setLabelCheckInn(null)
        //setInn(null)
        setKppList([])
        setNameCompany(null)
        //setCheckPolicy(false)
        setNewdogFiles([])
    }

    useEffect(() => {
        (async function(){
            setLoading(true)
            const res = await getRequests()
            if (res.isOk) {
                setReqs(res.data)
                if (res.data && res.data.length > 0) {
                    setSelectedReq(res.data[0])
                    //получаем финальный договор
                    const resFinalDog = await getFinalDogovor(res.data[0].id)
                    if (resFinalDog.isOk) {
                        setFinalDog(resFinalDog.data.dogovor)
                    }
                    //обновим переписку
                    const resMessages = await getMessages(res.data[0].id)
                    if (resMessages.isOk) {
                        setMessages(resMessages.data)
                    } else notify(resMessages.message, 'error', 10000)
                }
            } else {
                notify(res.message,'error',10000)
            }
            const resPolicy = await getResource('POLICY_URL')
            if (resPolicy.isOk) setPoilicyUrl(resPolicy.data[0])
            const resTypes = await getFileTypes()
            if (resTypes.isOk) setFileTypes(resTypes.data)
            const resRegions = await getRegions()
            if (resRegions.isOk) setRegions(resRegions.data)
            setLoading(false)
        })()
    },[selectedDog])

    const onChangeReq =  async (e) => {
        const selected = reqs.find(item => item.id === e.value)
        setSelectedReq(selected) 
        //получаем финальный договор
        const resFinalDog = await getFinalDogovor(selected.id)
        if (resFinalDog.isOk) {
            setFinalDog(resFinalDog.data.dogovor)
        }
        //обновим переписку
        setLoadingMessage(true)
        const resMessages = await getMessages(selected.id)
        if (resMessages.isOk) {
            setMessages(resMessages.data)
        } else notify(resMessages.message, 'error', 10000)
        setLoadingMessage(false)
    }

    const downloadAkt = async (e) => {
        const idAttach = e.target.id
        setLoading(true)
        const res = await getRequestAttach(idAttach)
        if (res.isOk) {
            const data = res.data.attachment
            const filename  = res.data.filename

            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(data), filename)
        } else notify(res.message, 'error',10000)
        setLoading(false)
    }

    const messageFileOptions = {
        selectButtonText: "Выберите файл(ы)", 
        labelText: "Или перетащите сюда",
        accept: "*/*",
        multiple: true,
        maxFileSize: 50000000,
        uploadMode: "useForm",
        value: selectedFile,
        invalidFileExtensionMessage: 'Тип файлов не разрешен!',
        invalidMaxFileSizeMessage: 'Файл слишком большой!',
        invalidMinFileSizeMessage: 'Файл слишком маленький'
    }

    const onSendMessage = async (e) => {
        e.preventDefault()
        const {message, attachments} = messageFormData.current
        //console.log('message',Новое_сообщение, Вложение)
        if (!message) return;
        setLoadingMessage(true)
        
        const formData = new FormData()
        formData.append('request',selectedReq.id)
        formData.append('message', message)
        for (let i=0; i< attachments.length; i++) {
            formData.append('file', attachments[i])
        }
        
        const res = await sendMessage(formData)
        if (res.isOk) {
            e.target.reset()
            messageFormData.current = {message: null, attachments: []}
            setSelectedFile([])
            //обновим переписку
            const resMessages = await getMessages(selectedReq.id)
            if (resMessages.isOk) {
                setMessages(resMessages.data)
            } else notify(resMessages.message, 'error', 10000)
        } else notify(res.message,'error',10000)
        setLoadingMessage(false)
    }

    //проверяем инн по апи и заполняем поля
    const [loadingInn, setLoadingInn] = useState(false)
    const validateINN = async (e) => {
        e.preventDefault()
        setLoadingInn(true)
        const {inn} = innData.current
        //console.log(inn)
        const res = await checkINN(inn.toString())
        if (res.isOk){
            //setInn(inn)
            const parsedData = parseKonturRes(res.data)

            if (!parsedData) {
                setNameCompany(null)
                setKppList([])
                setKpp(null)
                setCheckedInn(false)
                setLoadingInn(false)
                setLabelCheckInn('Введен неверный ИНН!')
                return false
            }
            setCheckedInn(true)
            setLabelCheckInn('ИНН успешно проверен!')
            setNameCompany(parsedData.name)
            setKppList(parsedData.listKpp)
            setKpp(parsedData.listKpp[0])

            setLoadingInn(false)
            return true
        } else { //значит проблема с апи
            setCheckedInn(false)
            setLabelCheckInn('Неизвестная ошибка при проверке ИНН, заполните поля вручную!')
            setLoadingInn(false)
            return true
        }
    }

    function parseKonturRes(json) {
        if (json.length === 0) return null
        const companyObj = json[0]
        const companyUl = json[0].UL
        const nameCompany = companyUl.legalName.full
        const ogrn = companyObj.ogrn
        const okpo = companyUl.okpo
        const okato = companyUl.okato
        const branches = companyUl.branches
        var listKpp = [companyUl.kpp]
        if (branches)
        for (let i=0; i<branches.length; i++){
            listKpp.push(branches[i].kpp)
        }
        return {name: nameCompany, listKpp: listKpp, ogrn: ogrn, okpo: okpo, okato: okato}
    }

    const [kppList, setKppList] = useState([])
    const [kpp, setKpp] = useState(null)
    const kppEditor = {
        dataSource: kppList,
        value: kpp,
        noDataText: 'Не найдено КПП для выбора',
        onValueChanged: function(e){
            setKpp(e.value)
        }
    }
    
    const nameCompanyEditor = {
        value: nameCompany,
        onValueChanged: function(e){
            setNameCompany(e.value)
        }
    }

    const addFileToOldreq = async (type, files) => {
        console.log(type, files)
        const fd = new FormData()
        const s_req = selectedReq
        let types = []
        for (let i=0; i<files.length; i++) {
            fd.append('document', files[i])
            types.push(type.id)
        }
        fd.append('types', types)
        fd.append('id_req', s_req.id)
        setLoading(true)
        const resAdd = await addFilesToOldReq(fd)
        if (resAdd.isOk) {
            //обновим список заявок
            const res = await getRequests()
            if (res.isOk) {
                setReqs(res.data)
                if (res.data && res.data.length > 0) {
                    const r = res.data.find(item => item.id === s_req.id)
                    setSelectedReq(r ? r : res.data[0])
                    //обновим переписку
                    await updateMessages(r ? r.id :  res.data[0].id)
                }
            }
            //notify(res.message, 'success', 5000)
        } else notify(resAdd.message, 'error', 5000)
        setLoading(false)
    }

    async function updateMessages(idReq){
        setLoadingMessage(true)       
        const resMessages = await getMessages(idReq)
        if (resMessages.isOk) {
            setMessages(resMessages.data)
        } else notify(resMessages.message, 'error', 10000)
        setLoadingMessage(false)
    }

    // const removeFile = (idFile) => {
    //     let source = newdogFiles.filter(item => item.id !== idFile)
    //     setNewdogFiles(source)
    // }

    //const position = { of: '#request-form' };

    const [region, setRegion] = useState(user.region ? user.region : null)
    const regionOptions = {
        value: region,
        dataSource: regions,
        valueExpr: 'idRegion',
        displayExpr: 'nameRegion',
        placeholder: 'Регион',
        onValueChanged: function(e){
            setRegion(e.value)
        },
        searchEnabled: true
    }

    // const dogTypesOptions = {
    //     dataSource: dogovorTypes,
    //     valueExpr: 'id',
    //     displayExpr: 'name'
    // }

    const onSuccessSign = async () => {
        //получаем финальный договор
        const resFinalDog = await getFinalDogovor(selectedReq.id)
        if (resFinalDog.isOk) {
            setFinalDog(resFinalDog.data.dogovor)
            notify('Договор успешно подписан!', 'success', 5000)
        }
    }

    async function downloadZip(){
        const resZip = await getDogovorZip(finalDog.id)
        if (resZip.isOk) {
            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(resZip.data.zip), resZip.data.zipName)
        }
    }

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <LoadPanel visible={loading} />
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faFileLines} />
            <h3><strong>Заключение нового договора</strong></h3>
            <div className={'row mb20 mt20'}>
                <div className={'col-12 col-md-3'}>
                    <Button className="block" type={'default'} text="Подать заявку" hint="Подать заявку" onClick={createNewReq}/>
                </div>
                {/* <div className={'col-12 col-md-3 mt10md'}>
                    <Button className="block" type={'default'} text="Конфигуратор тарифов"  hint="Конфигуратор тарифов" onClick={openConfiguratorPopup}></Button>
                </div> */}
            </div>
            <div>
                {/*<h6>Мои заявки</h6>*/}
                <SelectBox labelMode={'floating'} label={'Мои заявки'} className="big-input"
    noDataText="Нет заявок для отображения"
    placeholder="Выберите заявку.."
    dataSource={reqs}
    displayExpr="name_company"
    valueExpr="id"
    value={selectedReq ? selectedReq.id : null}
    defaultValue={selectedReq ? selectedReq.id : null}
    onValueChanged={onChangeReq}
    />
                { !!selectedReq &&
                    <div>
                        <div style={{margin: '20px 0px 20px 0px'}}>
                            <label><b>Информация по заявке:</b></label>
                            <ul>
                                <li>Статус заявки: {selectedReq ? selectedReq.status : ''}</li>
                                <li>ИНН: {selectedReq ? selectedReq.inn : ''}</li>
                                <li>КПП: {selectedReq ? selectedReq.kpp : ''}</li>
                                <li>Текст сообщения: {selectedReq ? selectedReq.text_message : ''}</li>
                                <li>Регион: {selectedReq && selectedReq.region && regions.length > 0 ? regions.find(r => r.idRegion === selectedReq.region).nameRegion : ''}</li>
                                <li>Контактное лицо: {selectedReq && selectedReq.fio ? selectedReq.fio : selectedReq.contact}</li>
                                <li>Телефон: {selectedReq && selectedReq.phone ? selectedReq.phone : 'н/д'}</li>
                                {/*<li>Контактная информация: {selectedReq && selectedReq.contact ? selectedReq.contact : ''}</li>*/}
                                <li>Тип договора: {selectedReq && selectedReq.dogovor_type ? dogovorTypes.find(d=>d.id === selectedReq.dogovor_type ).name : ''}</li>
                                {
                                    selectedReq && selectedReq.attachments &&
                                    selectedReq.attachments.map(file => <li key={file.id}>{file.name_type}: <a href={'#/add-dogovor'} onClick={downloadAkt} id={file.id} key={file.id}> {file.filename}; </a></li>)
                                }
                            </ul>
                            <Button text="Добавить документы" icon="add" onClick={()=>{setVisibleAddOldFiles(true)}}/>
                            <NewdogFiles visible={visibleAddOldFiles} types={fileTypes} onClose={() => setVisibleAddOldFiles(false)} onAddFiles={addFileToOldreq}/>
                            <div className="mt20">
                                <label><b>Итоговый договор: </b></label>
                                <span>{finalDog ? <a href="#/add-dogovor" onClick={downloadZip}> {finalDog.filename}</a> : <i> Пока нет прикрепленного договора</i>}</span>
                                <br></br>
                                {
                                    finalDog && 
                                    <>
                                        <i>Подписан сбытовой компанией: <b>{finalDog.companyKeyName ? 'Да' : 'Нет'}</b><span> <FontAwesomeIcon icon={faKey} color={finalDog.companyKeyName ? 'green' : 'red'}/></span>; </i>
                                        <i>Подписан клиентом: <b>{finalDog.clientKeyName ? 'Да' : 'Нет'}</b><span> <FontAwesomeIcon icon={faKey}  color={finalDog.clientKeyName ? 'green' : 'red'}/></span> 
                                        {!!!finalDog.clientKeyName && <a href="#/add-dogovor"><u><PopSelectCert companyKeyName={finalDog.companyKeyName} companyKey={finalDog.companyKey} idFile={finalDog.id} onSuccessSign={onSuccessSign}/></u></a>}</i>
                                    </>
                                }
                                
                            </div>
                        </div>
                        <div className="mt20">
                            <label><b>Переписка по заявке:</b><LoadIndicator width={'24px'} height={'24px'} visible={loadingMessage}/></label>
                            <div style={{height: '350px',border: '1px solid lightgray',overflowY: 'auto', padding: '10px'}}>
                                {
                                    messages.map((message, i) => {
                                        return <div key={i}>
                                            <label><b>[{formatTimestamp(new Date(message.date_create))}] {message.type_message === 0 ? user.firstName+' '+user.lastName : message.username}:</b></label>
                                            <p>{message.text_message}</p>
                                            {message.attachments.map((attach) => <span key={attach.id}> <a href="#/add-dogovor" id={attach.id} onClick={downloadAkt}>{attach.filename}</a>; </span>)}
                                        </div>

                                    })
                                }
                            </div>
                            <div>
                                <form onSubmit={onSendMessage}>
                                    <Form colCount={2} labelMode={'floating'} formData={messageFormData.current} disabled={loadingMessage}>
                                        <Item dataField="message" editorType='dxTextArea'>
                                            <Label text={"Новое сообщение"}/>
                                        </Item>
                                        <Item dataField="attachments" editorType="dxFileUploader" editorOptions={messageFileOptions}>
                                            <Label text={"Вложение"}/>
                                        </Item>
                                        <EmptyItem/>
                                        <ButtonItem
                                            horizontalAlignment="right"
                                        >
                                            <ButtonOptions
                                                type={'default'}
                                                useSubmitBehavior={true}
                                                text="Отправить"
                                                icon="email"               
                                            />
                                        </ButtonItem>
                                    </Form>
                                </form>
                            </div>
                        </div>
                    </div>
                }

            </div>

            <Popup 
                id="req-popup"
                visible={reqVisible}
                onHiding={hidingPopup}
                //maxHeight="600px"
                maxWidth="800px"
                title="Заключение нового договора"
                //onHidden={onHiddenPopup}
                showCloseButton={true}
                closeOnOutsideClick={false}
                maxHeight={'90%'}
                height={'auto'}
                titleRender={()=>PopupTitleWithIcon(faFileLines, "Заключение нового договора",hidingPopup)}
            >
                <ScrollView className="big-input" width='100%' height='100%'>
                    <div className="px-3">
                    <form onSubmit={validateINN}>
                        <Form
                            formData={innData.current}
                            id="inn-form"
                            colCount={1}
                            labelMode= "floating"
                            labelLocation="left"
                        >    
                            <Item dataField="inn" editorType="dxNumberBox" editorOptions={innEditorOptions}>
                                <Label text={"ИНН"}/>
                                <RequiredRule message="Введите ИНН. От 10 до 12 цифр" />
                                <StringLengthRule min={10} max={12} message="От 10 до 12 цифр!" />
                                {/*<RangeRule min={1000000000} max={999999999999} message="От 10 до 12 цифр"></RangeRule>*/}
                                {/* <StringLengthRule max={12} message="Слишком длинное значение поля!" /> */}
                                <NumericRule message={'Возможно только числовое значение поля!'} />
                                {/* <AsyncRule message={"Введенный ИНН не существует!"} validationCallback={validateINN}></AsyncRule> */}
                            </Item>
                            <ButtonItem horizontalAlignment="left">
                                    <ButtonOptions
                                        type={'default'}
                                        useSubmitBehavior={true}
                                        text="Проверить ИНН"
                                        icon="check"
                                    >
                                    </ButtonOptions>
                            </ButtonItem>
                        </Form>
                    </form>
                    <LoadIndicator width={'24px'} height={'24px'} visible={loadingInn}/>
                    <i>{(checkedInn !== null && !loadingInn) ? labelCheckInn : ''}</i>
                    <NewdogFilesList types={fileTypes} checkedInn={checkedInn === null} />
                    {/* <div>
                        <span><b>Документы:</b></span>
                        <ul style={filesSource.length > 0 ? {border: '1px solid lightgray'} : {}}>
                            {
                                filesSource.map((item) => 
                                    <li key={item.id}>
                                        {item.type_name}: <u>{item.file_name}</u> (<a href="#/add-dogovor" onClick={() => {removeFile(item.id)}}>Удалить</a>)
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <Button text="Добавить документы" icon="add" disabled={checkedInn === null} onClick={()=>{setVisibleAddFile(true)}}></Button>
                    <NewdogFiles mode="newreq" visible={visibleAddFile} types={fileTypes} onClose={()=>{setVisibleAddFile(false)}} onAddFiles={addFileToNewreq} /> */}
                    <form className="" onSubmit={onSendRequest}>
                        <Form
                            formData={formData.current}
                            id="request-form"
                            colCount={1}
                            labelMode= "floating"
                            labelLocation="left"
                        >    
                            <GroupItem cssClass={'mt-3'} disabled={checkedInn === null}>
                                <Item dataField={"region"} editorType={"dxSelectBox"} editorOptions={regionOptions}>
                                    <Label text="Регион" />
                                    <RequiredRule message="Выберите регион" />
                                </Item>
                                {/*<Item dataField={"dogovorType"} editorType={"dxSelectBox"} editorOptions={dogTypesOptions}>*/}
                                {/*    <Label text="Тип договора" />*/ }
                                {/*    <RequiredRule message="Выберите тип договора" />*/}
                                {/*</Item>*/}
                                <Item dataField="nameCompany" editorType="dxTextBox" editorOptions={nameCompanyEditor} disabled={false}>
                                    <Label text={"Название компании"}/>
                                    <RequiredRule message="Введите название компании!" />
                                    {/* <StringLengthRule min={2} message="Слишком короткое значение поля!" /> */}
                                </Item>
                                {checkedInn && <Item dataField="kppAuto" editorType="dxSelectBox" editorOptions={kppEditor}>
                                    <Label text={"КПП"}/>
                                    <RequiredRule message="Введите КПП!" />
                                    {/*<StringLengthRule min={9} message="Слишком короткое значение поля!" />
                                    <StringLengthRule max={9} message="Слишком длинное значение поля!" />
                                    <NumericRule message={'Возможно только числовое значение поля!'} /> */}
                                </Item>}
                                {checkedInn === false && 
                                <Item dataField="kpp" editorType="dxTextBox" editorOptions={{mask: '000000000'}}>
                                    <Label text={"КПП"}/>
                                    <RequiredRule message="Введите КПП!" />
                                    <StringLengthRule min={9} message="Слишком короткое значение поля!" />
                                    <StringLengthRule max={9} message="Слишком длинное значение поля!" />
                                    <NumericRule message={'Возможно только числовое значение поля!'} />
                                </Item>}
                                <GroupItem colCount={2} cssClass={'mt-2'} >
                                <Item dataField="fio" editorType="dxTextBox">
                                    <Label text={"Контактное лицо"}/>
                                    <RequiredRule message="Введите контактное лицо!" />
                                </Item>
                                <Item dataField="phone" editorOptions={phoneEditorOptions} editorType="dxTextBox">
                                    <Label text={"Телефон"}/>
                                    <RequiredRule message="Введите телефон!" />
                                </Item>
                                </GroupItem>
                                <Item dataField="textMessage" editorType="dxTextArea">
                                    <Label text={"Текст сообщения"}/>
                                </Item>
                                <Item dataField="checkPolicy" editorType="dxCheckBox">
                                <Label text={"Принимаю условия обработки данных"}/>
                                <RequiredRule message="Необходимо принять условия!" />
                                </Item>
                                <ButtonItem horizontalAlignment="left">
                                    <ButtonOptions
                                        type={'default'}
                                        useSubmitBehavior={true}
                                        text="Отправить заявку"
                                        icon="email"
                                    >
                                    </ButtonOptions>
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                        <div className="text-center">
                            <a href={policyUrl} target="blank">Ознакомиться с условиями</a>
                        </div>
                    </form>
                    </div>
                </ScrollView>
            </Popup>
        </div>
    )

}
const phoneEditorOptions = {mask: '+7 (YX0) 000-0000', maskRules: { X: /[01-9]/, Y: /[9]/ } };
export default AddDogovorPage

// const openConfigurator = async () => {
    //     setLoadingConfigurator(true)
    //     //получаем регионы сразу с тарифами
    //     const regionRes = await getRegionsWithTariffs()
    //     if (regionRes.isOk) {
    //         setRegionsData(regionRes.data)
    //         setSelectedRegion(regionRes.data.length > 0 ? regionRes.data[0].region : null)
    //         const defaultTariffs = regionRes.data[0].tariffs
    //         setTariffsData(defaultTariffs ? defaultTariffs : [])
    //         setTariffsValues(defaultTariffs && defaultTariffs[0] ? defaultTariffs[0].values : [])
    //         setSelectedTariff(defaultTariffs && defaultTariffs[0] ? defaultTariffs[0] : null)
    //     } else {
    //         notify(regionRes.message,'error',10000)
    //     }
    //     setLoadingConfigurator(false)
    // }

    // const onChangeRegion = (e) => {
    //     const selected = regionsData.find(item => item.region.idRegion === e.value)
    //     setSelectedRegion(selected.region)
    //     setTariffsData(selected.tariffs)
    //     setSelectedTariff(selected.tariffs.length > 0 ? selected.tariffs[0] : null)
    //     setTariffsValues(selected.tariffs.length > 0 ? selected.tariffs[0].values : null)
    // }

    // const onChangeTariff = (e) => {
    //     const selected = tariffsData.find(item => item.idTariff === e.value)
    //     setSelectedTariff(selected)
    //     setTariffsValues(selected ? selected.values : [])
    // }

    // function hidingTariffPopup() {
    //     setConfiguratorVisible(false)
    // }

// const [configuratorVisible, setConfiguratorVisible] = useState(false)
    // const [loadingConfigurator, setLoadingConfigurator] = useState(false)
    // const [regionsData, setRegionsData] = useState([])
    // const [tariffsData, setTariffsData] = useState([])//для селекта тарифов
    // const [tariffsValues, setTariffsValues] = useState([])//для таблицы тарифов
    // const [selectedRegion, setSelectedRegion] = useState(null)
    // const [selectedTariff, setSelectedTariff] = useState(null)

// const downloadMessageAttach = async (e) => {
    //     const idMessage = e.target.id
    //     setLoading(true)
    //     const res = await getMessageAttach(idMessage)
    //     if (res.isOk) {
    //         const data = res.data['attachFiledata']
    //         const filename  = res.data['attachFilename']

    //         var fileDownload = require('js-file-download')
    //         fileDownload(Buffer.from(data), filename)
    //     } else notify(res.message, 'error',10000)
    //     setLoading(false)
    // }

    // const fileOptions = {
    //     selectButtonText: "Выберите файл(ы)", 
    //     labelText: "Или перетащите сюда",
    //     accept: "*/*",
    //     multiple: true,
    //     maxFileSize: 50000000,
    //     uploadMode: "useForm",
    //     invalidFileExtensionMessage: 'Тип файлов не разрешен!',
    //     invalidMaxFileSizeMessage: 'Файл слишком большой!',
    //     invalidMinFileSizeMessage: 'Файл слишком маленький'
    // }

//  <Popup 
//                 id="configurator-popup"
//                 visible={configuratorVisible}
//                 onHiding={hidingTariffPopup}
//                 onShowing={openConfigurator}
//                 //maxHeight="600px"
//                 maxWidth="800px"
//                 //maxHeight="450px"
//                 title="Конфигуратор тарифов"
//                 //onHidden={onHiddenPopup}
//                 showCloseButton={true}
//                 closeOnOutsideClick={false}
//                 maxHeight={'90%'}
//                 height={'auto'}
//                 titleRender={()=>PopupTitleWithIcon(faScrewdriverWrench, "Конфигуратор тарифов",hidingTariffPopup)}
//             >
//                 <LoadIndicator width={'24px'} height={'24px'} visible={loadingConfigurator}/>
//                 <ScrollView className="big-input" width='100%' height='100%'>
//                     <div className="row mb10">
//                         <div className="col-md-6">
//                             <SelectBox
//                                 labelMode='floating'
//                                 noDataText="Нет данных для отображения"
//                                 label="Выберите регион"
//                                 placeholder="Выберите регион.."
//                                 dataSource={regionsData}
//                                 displayExpr="region.nameRegion"
//                                 valueExpr="region.idRegion"
//                                 value={selectedRegion ? selectedRegion.idRegion : null}
//                                 defaultValue={selectedRegion > 0 ? selectedRegion.idRegion : null}
//                                 onValueChanged={onChangeRegion}

//                             ></SelectBox>
//                         </div>
//                         <div className="col-md-6">
//                             <SelectBox
//                                 labelMode='floating'
//                                 label="Выберите тариф"
//                                 noDataText="Нет данных для отображения"
//                                 placeholder="Выберите тариф.."
//                                 dataSource={tariffsData}
//                                 displayExpr="nameTariff"
//                                 valueExpr="idTariff"
//                                 value={selectedTariff ? selectedTariff.idTariff : null}
//                                 defaultValue={selectedTariff ? selectedTariff.idTariff : null}
//                                 onValueChanged={onChangeTariff}
//                             ></SelectBox>
//                         </div>
//                     </div>

//                     <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-xs-12">
//                             <DataGrid
//                                 className={'dx-card wide-card'}
//                                 dataSource={tariffsValues}
//                                 showBorders={true}
//                                 //focusedRowEnabled={true}
//                                 //defaultFocusedRowIndex={0}
//                                 columnAutoWidth={true}
//                                 columnHidingEnabled={true}
//                                 noDataText="Нет данных для отображения"
//                                 wordWrapEnabled={true}
//                                 width="100%"
//                             >
//                                 <Column dataField="level" caption="Уровень напряжения" dataType="string"  />
//                                 <Column dataField="avans" caption="Авансовые платежи" dataType="number"  />
//                                 <Column dataField="current" caption="Текушие платежи" dataType="number" />
//                                 <Column dataField="fact" caption="Оплата по факту" dataType="number"  />
//                             </DataGrid>
//                         </div>
//                     </div>
//                     </div>
//                 </ScrollView>
//             </Popup> 
