import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm, ConfirmAccountForm } from './components';

const UnauthenticatedContent = () => {

  document.title = 'ЛКК ЮЛ'

  return (
    <Switch>
      <Route exact path='/login' >
        <SingleCard title="Вход в личный кабинет">
          <LoginForm />
        </SingleCard>
      </Route>
      <Route exact path='/create-account' >
        <SingleCard title="Регистрация">
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path='/reset-password' >
        <SingleCard
          title="Восстановление пароля"
          description="Введите email, с которым Вы регистрировались, и мы отправим Вам ссылку для смены пароля"
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title="Смена пароля">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/confirm-account/:userId/:confirmCode' >
        <SingleCard title="Подтверждение аккаунта">
          <ConfirmAccountForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}

export default UnauthenticatedContent