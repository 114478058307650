import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "devextreme-react/button";



export const PopupTitleWithIcon = (icon,title,fun) => {
    return (
        <div className="mt10 popup-title-with-icon"><FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={icon} />
            <h3><strong>{title}</strong></h3>
            <div className="dx-toolbar-after">
                <div className="dx-item dx-toolbar-item dx-toolbar-button">
                    <div className="dx-item-content dx-toolbar-item-content">
                        <Button icon="close" className="dx-button-mode-text mr20"  onClick={fun}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

