import SelectBox from 'devextreme-react/select-box'
import { Button } from 'devextreme-react/button'
import { useAuth } from '../../contexts/auth'
import './select-dogovor.scss'
import StorageService from '../../services/storage.service'
import { useReqPopup } from '../../contexts/req-popup'
import { TextBox } from 'devextreme-react'
import { useEffect, useState } from 'react'
import { getDolgSum } from '../../api/dogovor'
import { verifyAccess } from '../../utils/admin.utils'

const SelectDogovor = (props) => {

    const { dogovors, selectedDog, setSelectedDog } = useAuth() //, updateCountNotReads, updateCountVistSf
    const { openReqPopup } = useReqPopup()
    const sd_visible = props.visible //? 'block' : 'none'
    
    const [sumDolg, setSumDolg] = useState(0)
    const [sumAvansDolg, setSumAvansDolg] = useState(0)
    const [visibleDebt, setVisibleDebt] = useState(true)

    const onSelectDogovor = (e) => {
        let selectedDog = dogovors.find(dogovor => dogovor.kodDog === e.value)
        if (selectedDog) {
            StorageService.setLocalDogovor(selectedDog)
            setSelectedDog(selectedDog)
            //updateCountNotReads(selectedDog)
            //updateCountVistSf(selectedDog)
        }
    }

    useEffect(() => {
      const debtRoles = [3,4]
      if (!verifyAccess(debtRoles, selectedDog)) {//(selectedDog ? selectedDog.roles : [])
          setVisibleDebt(false)
          return
      }
      (async function(){
        if (selectedDog) {
          setVisibleDebt(true)
          const res = await getDolgSum(selectedDog.kodDog)
          if (res.isOk) {
            //const sumResult =  res.data.real2 + res.data.real0
            setSumDolg(res.data.real2)
            setSumAvansDolg(res.data.real0)
          } else {
            setSumDolg('-')
            setSumAvansDolg('-')
          }
          //setSumDolg(selectedDog.dolg)
        }
      })()
    },[selectedDog])

    const btnAddClick = () => {
      openReqPopup(true,1)
    }

    const renderField = (data) => {
      return (
          <div>
              <TextBox
                defaultValue={data ? ('№'+(data.ndog ? data.ndog : '') + ': ' + (data.name ? data.name : '') + 
                ((visibleDebt && data.dolg && data.dolg !== 0) ? ' (' + data.dolg.toLocaleString('ru',{style:"currency", currency:"RUB"}) + ')' : '')) : ''}
              />
          </div>
      );
  }

  return (
    <>
      { sd_visible &&
        <div className={'select-dogovor content-block dx-card responsive-paddings big-input'}>
          <div className="bd-container-sidebar">
            <Button className={'float-right big-button'} icon="add" type={'success'} onClick={btnAddClick}/>
          </div>
          <div className="bd-container-body">
              <SelectBox  
                labelMode={'floating'} 
                label={'Договор'}
                dataSource={dogovors}
                displayExpr="name"//{(itemData, itemIndex, itemElement)=>{return '№'+itemData.ndog+': '+itemData.name}}//"name"
                valueExpr="kodDog"
                defaultValue={selectedDog ? selectedDog.kodDog : null}
                value={selectedDog ? selectedDog.kodDog : null}
                itemTemplate={(itemData, itemIndex, itemElement)=>{itemElement.append('№'+itemData.ndog +  ': ' + itemData.name + ((visibleDebt && itemData.dolg && itemData.dolg !== 0) ? ' (' + itemData.dolg.toLocaleString('ru',{style:"currency", currency:"RUB"}) + ')' : ''));}}
                placeholder="Выберите договор.."
                onValueChanged={onSelectDogovor}
                noDataText="У Вас нет подключенных договоров"
                fieldRender={renderField} 
                //dropDownOptions={{resizeEnabled: true, minWidth: '200px' }} с этим перестало работать переключение
                searchEnabled={true}
                searchExpr={['name','ndog']}
                searchMode={"contains"}
              />
          </div>
          {visibleDebt &&
            <div style={{marginTop: '10px'}}>
              <h5>Задолженность: <a href={"#/debt"}>{sumDolg.toLocaleString('ru',{style:"currency", currency:"RUB"})}</a></h5>
              <h5>Обязательства по авансовым платежам: <a href={"#/debt"}>{sumAvansDolg.toLocaleString('ru',{style:"currency", currency:"RUB"})}</a></h5>
            </div>
          }
        </div>

      }
    </>
  )
}

export default SelectDogovor
