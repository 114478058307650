export const themes1 = [
    "material.blue.light","material.blue.dark","material.iesgarant.light","material.purple.dark","material.purple.light","material.teal.dark","material.teal.light",
    "material.lime.dark","material.lime.light","material.orange.dark","material.orange.light","generic.light"
];

export const themes2 = [{
    'ID': 12,
    'NameTheme': 'generic.light',
    'NameRus': 'Синяя тема',
    'Img': 'generic.light'
},
//     {
//     'ID': 1,
//     'NameTheme': 'material.iesgarant.light',
//     'NameRus': 'ЕЭСГ',
//     'Img': 'material.iesgarant.light'
// },{
//     'ID': 2,
//     'NameTheme': 'material.blue.light',
//     'NameRus': 'Голубой',
//     'Img':'material.blue.light'
// },{
//     'ID': 3,
//     'NameTheme': 'material.blue.dark',
//     'NameRus': 'Голубой',
//     'Img': 'material.blue.dark'
// },{
//     'ID': 4,
//     'NameTheme': 'material.purple.light',
//     'NameRus': 'Пурпур',
//     'Img': 'material.purple.light'
// },{
//     'ID': 5,
//     'NameTheme': 'material.purple.dark',
//     'NameRus': 'Пурпур',
//     'Img': 'material.purple.dark'
// },{
//     'ID': 6,
//     'NameTheme': 'material.teal.light',
//     'NameRus': 'Бирюза',
//     'Img': 'material.teal.light'
// },{
//     'ID': 7,
//     'NameTheme': 'material.teal.dark',
//     'NameRus': 'Бирюза',
//     'Img': 'material.teal.dark'
// },{
//     'ID': 8,
//     'NameTheme': 'material.lime.light',
//     'NameRus': 'Лимон',
//     'Img': 'material.lime.light'
// },{
//     'ID': 9,
//     'NameTheme': 'material.lime.dark',
//     'NameRus': 'Лимон',
//     'Img': 'material.lime.dark'
// },{
//     'ID': 10,
//     'NameTheme': 'material.orange.light',
//     'NameRus': 'Оранж',
//     'Img': 'material.orange.light'
// },{
//     'ID': 11,
//     'NameTheme': 'material.orange.dark',
//     'NameRus': 'Оранж',
//     'Img': 'material.orange.dark'
// },{
//     'ID': 13,
//     'NameTheme': 'generic.light.compact',
//     'NameRus': 'ЕЭСГ compact',
//     'Img': 'generic.light.compact'
// },{
//     'ID': 13,
//     'NameTheme': 'material.blue.light.compact',
//     'NameRus': 'Голубой compact',
//     'Img': 'material.blue.light.compact'
// }
];
