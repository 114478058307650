import request from './api'

export async function getCompanyInfo() {
    try {
        const res = await request('/api/resource/company-info','POST',null)
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getResource(func) {
    try {
        const res = await request('/api/resource/resource','POST',{func: func})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}