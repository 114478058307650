import React from 'react'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import Button from 'devextreme-react/button'
import UserPanel from '../user-panel/user-panel'
import './header.scss'
import { Template } from 'devextreme-react/core/template'
import { ReactComponent as Logo } from '../../assets/iesgarantznak.svg'
//import { ReactComponent as Logo3 } from '../../assets/iesgarantznak-dx-light.svg'
// import { ReactComponent as Logo4 } from '../../assets/text.svg'
// import { ReactComponent as Logo2 } from '../../assets/iesgarantslov.svg'
import {Link} from "react-router-dom"
import ThemeSelect from "../theme-select/theme-select"
import {lettersWidget,paysWidget,readsWidget,agregatorWidget} from '../../data/homeWidgetsData'
import themes from "devextreme/ui/themes"
//import OnlySelectDogovor from "../only-select-dogovor/only-select-dogovor"
import { useAuth } from '../../contexts/auth'
import { useReqPopup } from '../../contexts/req-popup'
import LoadIndicator from "devextreme-react/load-indicator"

themes.initialized(() =>{
    if (window.localStorage.getItem("dx-theme")==null) {window.localStorage.setItem("dx-theme","generic.light")}
    themes.current(window.localStorage.getItem("dx-theme"))
}
);
const Header = ({ menuToggleEnabled, /*title,*/ toggleMenu }) => {

  const {lettersCount, countDogovors, dogovors, nextAgrEventsCount, countNotReads, countVistSf, loadSyncUser, selectedDog } = useAuth()
  const { openReqPopup } = useReqPopup()

  lettersWidget.count = lettersCount
  agregatorWidget.count = nextAgrEventsCount
  readsWidget.count = countNotReads
  paysWidget.count = countVistSf

  var widgets = [lettersWidget,paysWidget,readsWidget,agregatorWidget];

  if (countDogovors === 0 && dogovors.length === 0) {
    widgets = widgets.filter(item => item.all_users_visible === true)
  }

  const btnAddClick = () => {
    openReqPopup(true,1)
  }

  return (
  <>
    <header className={'header-component main-background-fon-second-color'}>
      {/*<div className={'row justify-content-end'}><div className="select-dog-in-header col-xs-12 col-sm-6 col-md-4 col-lg-3 right"><SelectDogovor visible={'block'} /></div></div>*/}
      {/*<hr className="mt0 mb0" />*/}
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'}>
        <Link className="navbar-brand gradientsvg" to="/">

          {window.localStorage.getItem("dx-theme") === "generic.light"
              ? <><img alt={'alt'} style={{ height: '4rem' }} src={require('./../../assets/logo.png').default}/>
                {/*<Logo3 style={{ height: '1.8rem', width: '2rem' }} />*/}
                  {/*<svg height="0" width="0">*/}
                  {/*  <defs>*/}
                  {/*    <linearGradient id="lgrad-p" gradientTransform="rotate(0)">*/}
                  {/*      <stop offset="45%" stopColor="#0064A7"/>*/}
                  {/*      <stop offset="99%" stopColor="#9ED7F7"/>*/}
                  {/*    </linearGradient>*/}
                  {/*  </defs>*/}
                  {/*</svg>*/}
              </>
              : <Logo style={{ height: '1.8rem', width: '2rem' }} />
          }

        </Link>
        </Item>
        {/*<Item location={'before'} cssClass={'header-title'} locateInMenu={'auto'}>*/}
        {/*  <Link className="navbar-brand homemain" to="/">*/}
        {/*    {window.localStorage.getItem("dx-theme") === "generic.light"*/}
        {/*        ? <Logo4 style={{ height: '2rem', width: '8rem' }} />*/}
        {/*        : <Logo2 style={{ height: '1rem' }} />*/}
        {/*    }*/}
        {/*  </Link>*/}
        {/*</Item>*/}

        {widgets.map((obj,i) => {
          return (<Item cssClass={'main-color-interact'} location={'before'} locateInMenu={'auto'} key={i.toString()}>
            <Link to={ (obj.url === "/docs" && obj.count > 0) ? '/debt#' : (countDogovors === 0 && dogovors.length === 0 && obj.url === "/agregator-events") ? '/agregator-reclame#' :obj.url+'#'}>
              <div className="informer">
                <h4 className="count">{obj.svg}{obj.count !== 0 ? <div><span className={obj.badgeClassName}>{obj.count}</span></div> : ''}</h4>
                <span className="name">{obj.title}</span>
              </div>
            </Link>
          </Item>)
        })}

        <Item  location={'before'} visible={countDogovors === 0 && dogovors.length === 0}>
          <Button className='ml-3' icon='plus' text='Добавить договор в ЛК' onClick={btnAddClick}></Button>
        </Item>

        {/*<Item*/}
        {/*    location={'after'}*/}
        {/*    cssClass={'header-title'}*/}
        {/*    text={title}*/}
        {/*    visible={!!title}*/}
        {/*/>*/}
        <Item
            cssClass=""
            location={'after'}
            locateInMenu="never"
        >
          {/* <OnlySelectDogovor visible={'block'}/> */}
        </Item>
        <Item
            cssClass={'theme-select'}
            location={'after'}
            locateInMenu="always"
        >
          <ThemeSelect/>
        </Item>
        <Item
          visible={dogovors.length > 0}
          location={'after'}
        >
          <div>
            <span><a href="#/dogovor" className='dog-href'>{selectedDog ? 'Договор №'+selectedDog.ndog : ''}</a></span>
          </div>
        </Item>
        <Item
          visible={loadSyncUser}
          location={'after'}
        >
          <div>
            <LoadIndicator width={'15px'} height={'15px'}/>
            <span> Обновление данных..</span>
          </div>
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            //width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  </>
  )
}

export default Header
