import React from 'react';
import {Link} from "react-router-dom";
import './home2.scss';
import {homeWidgetsData} from '../../data/homeWidgetsData'
import "../../assets/scss/mdb.scss_";

class Home2 extends React.Component {
  render() {
      //применяем тему
    const widgets = homeWidgetsData;
    return (
        <React.Fragment>
          <div className='mt-3 mb-5 homemain'>
            <div className="container">
              <div className="services-list row mainsvgcolor">
                {widgets.map((obj, i) => {
                  return (<div className="col col-lg-4 col-xl-3 col-md-6 col-12" key={"widget_"+i}>
                    <div className="d-none d-sm-block animated pulse" >
                      <Link to="/docs#">
                        <div className="card d-flex text-center" >
                          <div className="rounded-circle z-depth-1-half">
                            {obj.svg}
                          </div>
                          {obj.badge}
                          <div className="card-body" >
                            <h4 className="card-title">{obj.title}</h4>
                            <hr/>
                            <p className="card-text">
                              {obj.subtitle}
                            </p>
                          </div>
                          <div className='rounded-bottom main-background-color text-center pt-3'>
                            <ul className='list-unstyled list-inline font-small'>
                              <li className='list-inline-item pr-2 white-text'>{obj.footer}</li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="card d-block d-sm-none d-flex" >
                      <Link to="/docs#">
                        <div className="media align-items-center">
                          <div>
                            {obj.svg}
                          </div>
                          {obj.badge}
                          <div>
                            {obj.footerXs}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default Home2
