import React from 'react'
import Accordion from 'devextreme-react/accordion'
import { useEffect, useState } from 'react'
import { formatTimestamp } from '../../utils/string.utils'
import { getUserRequests, getAttachment, updateReaded } from '../../api/request'
import LoadPanel from 'devextreme-react/load-panel'
import notify from 'devextreme/ui/notify'
import './letters.scss'
import { useAuth } from '../../contexts/auth'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import {Button} from "devextreme-react/button"
import { useReqPopup } from '../../contexts/req-popup'

const Letters = (props) => {

    const [requests, setUserRequests] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingAttach, setLoadingAttach] = useState(false)
    const { lettersCount, setLettersCount, dogovors } = useAuth()
    const { openReqPopup, isOpen } = useReqPopup()

    //const auth = useAuth()
    //const requests = auth.userRequests

    useEffect(() => {
        (async function () {
            setLoading(true)

            //получаем пользовательские сообщения
            const res_requests = await getUserRequests()
            if (res_requests.isOk) {
              setUserRequests(res_requests.data)
            } else {
                console.log(res_requests.message)
              notify(res_requests.message, 'error', 5000)
            }
          
            setLoading(false)
        })()
    }, [])

    useEffect(() => {
        (async function () {
            if (!isOpen){
                setLoading(true)
                //получаем пользовательские сообщения
                const res_requests = await getUserRequests()
                if (res_requests.isOk) {
                setUserRequests(res_requests.data)
                } else {
                    console.log(res_requests.message)
                notify(res_requests.message, 'error', 5000)
                }      
                setLoading(false)
            }          
        })()
    }, [isOpen])

    const saveAttachment = async (e) => {
        const idAttach = e.target.id
        
        setLoadingAttach(true)

        const res = await getAttachment(idAttach)
        if (res.isOk) {
            const data = res.data.attachment.data
            const filename  = res.data.name

            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(data), filename)

        } else notify(res.message,'error',5000)

        setLoadingAttach(false)

    }

    const onOpenItem = async (e) => {
        const item = e.itemData
        //если не прочитано, отметим
        if (!item.isRead) {
             const data = await updateReaded(item.id,true)
             if (data.isOk) {
                item.isRead = true
                CustomTitle(item)//перерендер сообщения
                if (lettersCount > 0) setLettersCount(lettersCount - 1)//перерендер стикера
            }
            
        }
    }

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faComments} />
            <h3 className="mb20"><strong>Обращения</strong></h3>
            <LoadPanel visible={loading || loadingAttach} />
            <Button 
                icon='plus' 
                type='default' 
                text='Новое обращение' 
                onClick={() => {if (dogovors.length > 0) openReqPopup(true, 1,null,[],[],false); else openReqPopup(true,3);}}
                className={'mb-2 mr-2'}
            />
            {loading ?
                <p>Загрузка обращений..</p> :  
            requests && requests.length === 0 ? 
                <p>У Вас пока нет обращений.</p> : 
                <Accordion
                    dataSource={requests}
                    collapsible={true}
                    multiple={false}
                    animationDuration={500}
                    //selectedItems={state.selectedReq}
                    defaultSelectedItem={false}
                    //onSelectionChanged={selectionChanged}
                    itemTitleRender={CustomTitle}
                    //itemTitleTemplate={(itemData, itemIndex, itemElement)=>{itemElement.append(itemData.type +  ': ' + itemData.status);}}
                    //itemTitleComponent={CustomTitle}
                    itemRender={CustomItem}
                    onItemTitleClick={onOpenItem}
                    noDataText='У Вас пока нет обращений'
                /> 
            }
        </div>
    )

    function CustomTitle(data) {
        const type = data.type
        const dateCreate = new Date(data.createdAt)
        const date_str = formatTimestamp(dateCreate)
        const isRead = data.isRead
        const className = isRead ? '' : 'not-readed'

        return (
            <div className={className}>
                <small>{date_str}</small><br/>
                <span style={!isRead ? {fontWeight: 'bold'} : {}}>{!isRead ? '• ' : ''}{type}</span>
                {/*<Box*/}
                {/*    direction="row"*/}

                {/*>*/}
                {/*    <Item ratio={20}>{!isRead ? '• ' : ''}{type}</Item>/!*<Item ratio={20} onClick={}>{!isRead ? '• ' : ''}{type}</Item>*!/*/}
                {/*    <Item ratio={4}>{date_str}</Item>*/}
                {/*</Box>*/}
            </div>
        )
    }

    function CustomItem(data) {
        const message = data.message
        const result = data.result
        const status = data.status
        const typeId = data.typeId

        const attachmentsRequest = data.attachments.filter(attach => attach.direction === 0)
        const attachmentsResponse = data.attachments.filter(attach => attach.direction === 1)
        //console.log(attachmentsRequest)
        //console.log(attachmentsResponse)

        const attachmentsRequestList = createListAttach(attachmentsRequest)
        const attachmentsResponseList = createListAttach(attachmentsResponse)

        return (
            <>
            {typeId !== 8 ?
                <>
                    <b>Статус:</b> {status}<br/>
                    <b>Текст запроса:</b> {message}<br/>
                    <b>Вложения:</b> {attachmentsRequestList}<br/>
                    <b>Результат:</b> {result} {attachmentsResponseList}
                </>
            :
            <>
                {result}  {attachmentsResponseList}
            </>
            }
            </>
            // <Box
            //     direction="col"
            //     width="100%"
            //     height={200}
            // >
            //     <Item ratio={1}><b>Статус:</b> {status}</Item>
            //     <Item ratio={1}><b>Текст запроса:</b> {message}</Item>
            //     <Item ratio={2}><b>Вложения:</b> {attachmentsRequestList}</Item>
            //     <Item ratio={1}><b>Результат:</b> {result} {attachmentsResponseList}</Item>
            // </Box>
        );
    }

    function createListAttach(list) {
        return (
            <React.Fragment>
                <ul>
                    {
                        list.map((attach, i) => {
                            return (
                                <li key={'attach'+attach.idReq+i}><a href={'#/letters'} onClick={saveAttachment} id={attach.id}>{attach.name}</a></li>
                            )
                        })
                    }
                </ul>
            </React.Fragment>
        )
    }
}

export default Letters
