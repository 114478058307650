import request from './api'

export async function sendNewdogRequest(formData){
    try {
        const data = await request('/api/newdog/request', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getRequests(){
    try {
        const res = await request('/api/newdog/request', 'POST', null)
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getRequestAttach(idAttach){
    try {
        const res = await request('/api/newdog/request-attach', 'POST', {id: idAttach})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendMessage(formData){
    try {
        const res = await request('/api/newdog/message', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getMessages(idReq){
    try {
        const res = await request('/api/newdog/message', 'POST', {request: idReq})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

// export async function getMessageAttach(id){
//     try {
//         const res = await request('/api/newdog/message-attach', 'POST', {id: id})
//         return {
//             isOk: true,
//             data: res
//         }
//     } catch (e) {
//         return {
//             isOk: false,
//             message: e.message
//         }
//     }
// }

export async function getRegionsWithTariffs(){
    try {
        const res = await request('/api/newdog/region', 'POST', null)
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function checkINN(inn){
    try {
        const res = await request('/api/newdog/inn', 'POST', {inn: inn})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getFileTypes(){
    try {
        const res = await request('/api/newdog/file-types', 'POST', null)
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function addFilesToOldReq(formData){
    try {
        const res = await request('/api/newdog/add-to-old-req', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getFinalDogovor(idReq){
    try {
        const res = await request('/api/newdog/final-dogovor', 'POST', {id: idReq})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorToSigned(idAttach){
    try {
        const res = await request('/api/newdog/sign-dogovor', 'POST', {id: idAttach})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function putSignDogovor(formData){
    try {
        const res = await request('/api/newdog/sign-dogovor', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorZip(idFile){
    try {
        const res = await request('/api/newdog/dog-zip', 'POST', {id: idFile})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}