import request from './api'

export async function getTemplate(source) {
    try {
        const res = await request('/api/template/download','POST',{source: source})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getTemplateIntegral(period, points, dogovor) {
    try {
        const res = await request('/api/template/integral','POST',{
            period: period,
            points: points,
            dogovor: dogovor
        })
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getTemplateInterval(period, points, readsType, kodDog) {
    try {
        const res = await request('/api/template/interval','POST',{
            period: period,
            points: points,
            type: readsType,
            dogovor: kodDog
        })
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}