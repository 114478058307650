import {getFirstDayOfMounth} from "../../utils/date.utils"
import DateBox from "devextreme-react/date-box"
import DataGrid, {Column, Grouping, Paging, Selection} from 'devextreme-react/data-grid'
import Chart, {CommonSeriesSettings, Export, Legend, LoadingIndicator, Point, Series, Size, Title, Tooltip, ValueAxis} from 'devextreme-react/chart'
import {Button} from "devextreme-react/button"
import {Popup} from "devextreme-react"
import React, {useEffect, useState} from "react"
import {useAuth} from '../../contexts/auth'
import {getChartMonth, getNoremDay, getNoremMonth} from "../../api/agrdog"
import {useReqPopup} from "../../contexts/req-popup"
import LoadPanel from 'devextreme-react/load-panel'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faListCheck} from "@fortawesome/free-solid-svg-icons";
import notify from "devextreme/ui/notify"
import { verifyAccess } from "../../utils/admin.utils"
import AccessDenied from "../../components/access-denied/access-denied"
import DayEvents from "./day-events"
import DayChart from "./day-chart"
import ScrollView from "devextreme-react/scroll-view";
import {PopupTitleWithIcon} from "../../components/popup-title-with-icon/popup-title-with-icon";

const AgregatorEvents = () => {

    const [visiblePopup, setVisiblePopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState([])
    const defaultPeriod = getFirstDayOfMounth(new Date())
    const [period, setPeriod] = useState(defaultPeriod)

    const { agrDogovors, selectedAgrdog, selectedDog } = useAuth()
    const { openReqPopup } = useReqPopup()
    const [chartData, setChartData] = useState([])
    const [chartSource, setChartSource] = useState([])

    const [dayEvents, setDayEvents] = useState([])
    const [visibleDayChart, setVisibleDayChart] = useState(false)
    const [dayChart, setDayChart] = useState(new Date())

    const calendarOptions = {
        applyValueMode: "useButtons",
        //onValueChanged: onStartPickerChanged,
        showDropDownButton:true,
        maxZoomLevel: 'year', 
        minZoomLevel: 'century'
    }

    const onClickProfile = async () => {
        setVisiblePopup(true)
        setChartData([])
        setLoading(true)
        ///console.log('На клик профиля ',profileDay,profileType)
        await getProfile()
        setLoading(false)
    }

    async function getProfile() {
       //const res = await getEnergosferaCounter(date,type,countVals, selectedAgrdog.kodDog)
       const res = await getChartMonth(period, selectedAgrdog.kodDog, selectedAgrdog.objId)
        if (res.isOk) {
            setChartData(res.data)
            setChartSource(getSource(res.data))
        } else {
            notify(res.message,'error',10000)
        }
    }

    const onHiddenPopup = () => {
        setVisiblePopup(false)
        setChartData([])
        setChartSource([])
    }

    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [5]
        if (!verifyAccess(pageRoles, selectedDog) && !selectedAgrdog) {
            setVisiblePage(false)
            return
        }
        (async function () {
            if (selectedAgrdog){
                setVisiblePage(true)
                setLoading(true)
                const res = await getNoremMonth(selectedAgrdog.objId, period)
                if (res.isOk) {
                    setEvents(res.data)
                } else {
                    // notify(periodRes.message,'error',5000)
                }
                setLoading(false)
            }
            
         })()
    },[selectedAgrdog, period, selectedDog])

    const onChangePeiod = (e) => {
        setPeriod(getFirstDayOfMounth(e))
    }

    async function onSelectDay(e){
        const row = e.selectedRowsData[0]
        if (!row) return
        if (row.label === '-') {
            notify('По выбранному дню нет событий.', 'info', 2000)
            return
        }
        setLoading(true)
        const dayRes = await getNoremDay(selectedAgrdog.objId, row.date)
        if (dayRes.isOk) {
            setDayEvents(dayRes.data)
        }
        setLoading(false)
    }

    const onBack = () => {
        setDayEvents([])
    }

    function getSource(mas) {
        var res = []
        var item = {}
        for (let i=0; i<mas.length; i++) {
            item = {
                value: mas[i].name,
                name: mas[i].name
            }
            if (!res.find(el => el.value === mas[i].name)) res.push(item)
        }
        //console.log(res)
        return res
    }

    // const statusRender = (row) => {
    //     const data = row.data
    //     const colorMas = ['red','orange','green']
    //     let color = 'black'
    //     if (data.min !== null) color = colorMas[parseInt(data.min)]
    //     return (
    //         <span style={{color: color}}>{data.parsed_status}</span>
    //     )
    // }

    const statusRender = (e) => {
        const colorMas = ['#dd3444','orange','#5cb85b']
        if (e.rowType === 'data' && e.column.dataField === 'parsed_status'){
            if (e.data && e.data.min != null) {
                e.cellElement.style.backgroundColor = colorMas[parseInt(e.data.min)]
                e.cellElement.style.fontWeight = 'bold'
            } 
        }
    }

    // const rowRender = (row) => {
    //     const data = row.data
    //     const colorMas = ['#dd3444','orange','#5cb85b']
    //     //console.log(data)
    //     if (data && data.min != null) {
    //         console.log(data.min)
    //         row.rowElement.style.backgroundColor = colorMas[parseInt(data.min)]
    //     } 
    // }

    const onChartClick = (e) => {
        const intDay = e.target.argument
        //console.log(e.target.argument, e.target.initialValue)
        let dayChart = new Date(period)
        dayChart.setDate(intDay)
        setDayChart(dayChart)
        setVisibleDayChart(true)
    }

    const onBackPeriodChart = () => {
        setVisibleDayChart(false)
    }

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faListCheck} />
            <h3><strong>События по договору</strong></h3>
       {agrDogovors.length === 0 ?
        <>
            <h5>У Вас нет подключенных договоров управления спросом.</h5>
            <Button text="Подать заявку" type={'default'} icon="edit" onClick={function (){openReqPopup(true, 4)}} />
        </>
        :
        <>
            <LoadPanel visible={loading} />
            { 
                dayEvents.length === 0 ?
                <div>
                    <DateBox 
                        labelMode={'floating'} 
                        label={'Период:'}
                        displayFormat='monthAndYear'
                        //width="150px"
                        style={{maxWidth: '200px'}}
                        calendarOptions={calendarOptions}
                        value={period}
                        onValueChange={onChangePeiod}
                    ></DateBox><br></br>
                    <span>События по выбранному периоду:</span>
                    <DataGrid
                        id="month-table"
                        dataSource={events}
                        //keyExpr="setId"
                        columnHidingEnabled={true}
                        //allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        //onContentReady={this.onContentReady}
                        wordWrapEnabled={true}
                        noDataText="Не найдено событий в выбранном периоде!"
                        onSelectionChanged={onSelectDay}
                        //onRowPrepared={rowRender}
                        onCellPrepared={statusRender}
                    >
                        <Grouping autoExpandAll={false} />
                        <Selection mode="single" />

                        <Column dataField="date" caption="Дата" dataType="date"/>
                        <Column dataField="label" caption="Событие" dataType="string"/>
                        <Column dataField="hours" caption="Время" dataType="string"/>
                        <Column dataField="parsed_status" caption="Статус" dataType="string"/>

                        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
                        <Paging defaultPageSize={events.length} pageSize={events.length}/>
                    </DataGrid>
                    <div className="row mt20">
                        <div className={'col-sm-4'}>
                            <Button type={'default'} text="Текущий профиль потребления" icon="chart" onClick={onClickProfile}/>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <Button text="Назад" icon="back" type="default" onClick={onBack}></Button><br></br><br></br>
                    <DayEvents dayEvents={dayEvents} />
                </div>
            }
            <Popup 
                closeOnOutsideClick={true} 
                showCloseButton={true}
                visible={visiblePopup}
                height='auto'
                maxHeight={'90%'}
                titleRender={()=>PopupTitleWithIcon(faChartLine, "Суммарный профиль потребления по договору №" + selectedAgrdog.ndog,onHiddenPopup)}
            >
                <ScrollView className="big-input" width='100%' height='100%'>
                <Button icon="back" type="default" text="Назад" onClick={onBackPeriodChart} visible={visibleDayChart}/>
                    <div key='uniqueKey'>

                <DayChart dogovor={selectedAgrdog} visible={visibleDayChart} day={dayChart}/>                {!visibleDayChart &&
                <div id="chart-demo">
                    <div style={{textAlign: 'center'}}>
                        <span><b>{period ? (period.getMonth() + 1).toString().padStart(2,'0') + '.' + period.getFullYear() + ' (GMT+3)' : ''}</b></span><br></br>
                        <span><i>* Нажмите на значение графика, чтобы просмотреть день *</i></span>
                    </div>
                    <Chart
                        dataSource={chartData}
                        //customizePoint={customizePoint}
                        onPointClick={onChartClick}
                    >
                        <Size height={420} />
                        <CommonSeriesSettings
                                argumentField="format_time"
                                type="spline"
                                hoverMode="includePoints"
                            >
                            <Point visible={false} hoverMode="allArgumentPoints" />
                        </CommonSeriesSettings>
                        <ValueAxis position="left">
                            <Title text="КВт/ч" />
                        </ValueAxis>
                        {
                            chartSource.map((item) => {
                                return <Series
                                    key={item.value}
                                    valueField={item.value}
                                    name={item.name}
                                    type="stackedbar"
                                />
                            })
                        }
                        {/* <Series
                            argumentField="format_time"
                            valueField="value"
                            type="bar"
                        /> */}
                        <Export enabled={true} />
                        <LoadingIndicator enabled={true} />
                        <Tooltip
                            enabled={true}
                            shared={true}
                            customizeTooltip={customizeTooltip}
                            
                        />
                        <Legend verticalAlignment="bottom"
                                horizontalAlignment="center"
                                hoverMode="excludePoints"></Legend>
                    </Chart>
                </div>}
                    </div>
                </ScrollView>
            </Popup>
        </>
      }
      </div>
      : <AccessDenied/>}</>
    )

    function customizeTooltip(outInfo) {
        return {
          html: `
            <div style="z-index: 10000; position: relative">
                <div class="tooltip-body">
                    <div class="series-name">
                        <span class='top-series-name'>День: ${outInfo.argumentText}</span>
                    </div>
                    <div class="value-text">
                        <span class='top-series-value'>КВт/ч: ${outInfo.originalValue}</span>
                    </div>
                </div>
            </div>`,
        };
    }
}

export default AgregatorEvents
