export function getCountPoints(mas) {
    var countPoints = 0
        for (let i=0; i<mas.length; i++) {
            if (mas[i].kodPoint) countPoints++
        }
    return countPoints
}

export function getPoints(mas) {
    // var points = []
    //     for (let i=0; i<mas.length; i++) {
            
    //         if (mas[i].kodPoint) {
    //             //console.log(mas[i])
    //             points.push(mas[i].kodPoint)
    //         }
    //     }
    // return points
    var points = []
    for (let i=0; i<mas.length; i++) {
        if (!mas[i].isG) points.push(mas[i].id.split('_')[1])
    }
    return points
}

// export function getFullPoints(mas) {
//     var points = []
//         for (let i=0; i<mas.length; i++) {
            
//             if (mas[i].kodPoint) {
//                 points.push(mas[i])
//             }
//         }
//     return points
// }

