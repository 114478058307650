import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faListCheck} from "@fortawesome/free-solid-svg-icons"
import {Button} from "devextreme-react/button"
import {useReqPopup} from "../../contexts/req-popup"
import { useAuth } from "../../contexts/auth"
import { useEffect, useState } from "react"
import { verifyAccess } from "../../utils/admin.utils"

const AgregatorReclamePage = () => {

    const { openReqPopup } = useReqPopup()
    const { selectedDog } = useAuth()

    const [visibleButtons, setVisibleButtons] = useState(true)

    useEffect(() => {
        const pageRoles = [5]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisibleButtons(false)
            return
        }
    }, [selectedDog])

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faListCheck} />
            <h3><strong>Управление спросом</strong></h3>
            <div style={{textAlign: 'justify'}}>
                <p>С июня 2019 года на электроэнергетическом рынке РФ стартовал пилотный проект по созданию и развитию агрегаторов управления спросом (постановление Правительства РФ № 287 «О внесении изменений в некоторые акты Правительства Российской Федерации по вопросам функционирования агрегаторов управления спросом на электрическую энергию в Единой энергетической системе России, а также совершенствования механизма ценозависимого снижения потребления электрической энергии и оказания услуг по обеспечению системной надежности»).</p>
                <p>Агрегаторы управления спросом – это компании, объединяющие возможность группы розничных потребителей управлять своим электропотреблением, преобразующие ее в услугу по управлению спросом на электрическую энергию и выплачивающие потребителям вознаграждение за успешную реализацию этой услуги.</p>
                <p>Теперь потребители розничного рынка могут получать положительный экономический эффект за счет замещения неэффективной генерации ресурсами управления спросом и формирования более низких цен на электроэнергию.</p>
                <p>Основные возможности участия в управлении спросом для потребителей связаны с полным или частичным ограничением потребления электроэнергии и с использованием в такие интервалы собственных источников электроэнергии или накопителей электроэнергии.</p>
                <p>Стать участником пилотного проекта может любая компания - потребитель розничного рынка, имеющая почасовой учет электроэнергии и техническую возможность оперативно перераспределять потребление между часами суток. Объем и длительность разгрузки (от 1 до 5 раз в месяц длительностью 2 или 4 часа) определяются потребителем самостоятельно в зависимости от потенциала маневренности его электропотребления и производственного процесса, а вопрос о целесообразности исполнения команд на разгрузку всегда решается потребителем самостоятельно. Отборы исполнителей услуг проходят каждый квартал.</p>
                <p>В случае высокой готовности к снижению и надлежащего исполнения команд на разгрузку вознаграждение потребителя можно посчитать, используя {visibleButtons ? <a href="/#/agregator-calculator" className="href">Калькулятор дохода</a> : 'Калькулятор дохода'}.</p>
                <Button text="Подать заявку" type={'default'} icon="edit" visible={/*visibleButtons*/true} onClick={function (){openReqPopup(true, 4)}} />
            </div>
        </div>
    )
}

export default AgregatorReclamePage