import DateBox from 'devextreme-react/date-box'
import {usePeriod} from '../../contexts/period'
import {useAuth} from "../../contexts/auth";
import {getCurrentPeriod} from "../../api/dogovor";
import {getFirstDayOfMounth} from "../../utils/date.utils";
//import notify from "devextreme/ui/notify";
import {useEffect} from "react";



const SelectPeriod = (props) => {

    const { periodStart, setPeriodStart, periodEnd, setPeriodEnd } = usePeriod()

    const mode = props.mode

    const { selectedDog } = useAuth()
    useEffect(() => {
        (async function () {
            const periodRes = await getCurrentPeriod(selectedDog.kodDog)
            if (periodRes.isOk) {
                const startPeriod = getFirstDayOfMounth(new Date(periodRes.data.period))
                setPeriodStart(startPeriod)
            }
        })()
    }, [selectedDog,setPeriodStart])

    const onStartPickerChanged = (e) => {
        setPeriodStart(e)
    }

    const onEndPickerChanged = (e) => {
        setPeriodEnd(e)
    }

    const calendarOptions = {
        applyValueMode: "useButtons",
        showDropDownButton:true,
        maxZoomLevel: 'year', 
        minZoomLevel: 'century'
    }

    return (
        <>
            {
                mode === 'multiple' ?
                    <div className="row">
                        <div className={'col-sm-4 col-md-3 col-lg-2'} style={{minWidth: '200px'}}><DateBox labelMode={'floating'} label={'Период начала:'} calendarOptions={calendarOptions} value={periodStart} onValueChange={onStartPickerChanged} displayFormat='monthAndYear'/></div>
                        <div className={'col-sm-4 col-md-3 col-lg-2 mt20xs'} style={{minWidth: '200px'}}><DateBox labelMode={'floating'} label={'Период окончания:'} calendarOptions={calendarOptions} value={periodEnd} onValueChange={onEndPickerChanged} displayFormat='monthAndYear'/></div>
                    </div>
                :
                mode === 'single' ?
                    <div className="row">
                        <div className={'col-sm-4 col-md-3 col-lg-2'} style={{minWidth: '200px'}}><DateBox labelMode={'floating'} label={'Период:'} calendarOptions={calendarOptions} value={periodEnd} onValueChange={onEndPickerChanged} displayFormat='monthAndYear'/></div>
                    </div>
                    : <div className="row">
                        <div className={'col-sm-4 col-md-3 col-lg-2'}>Period mode not selected!</div>
                    </div>
            }
        </>
        
        
    )
}

export default SelectPeriod
