import notify from 'devextreme/ui/notify'
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react'
import { getCountNextEvents, getUserAgrDogovors } from '../api/agrdog'
import { getUser, signIn as sendSignInRequest, logout, generateFingerpint, syncUser } from '../api/auth'
import { getCountVistSf, getUserDogovors } from '../api/dogovor'
import { getCountNotReads } from '../api/point'
import { getCountNotReaded, getRequestTypes } from '../api/request'
import StorageService from './../services/storage.service'

function AuthProvider(props) {
  const [user, setUser] = useState()
  const [dogovors, setUserDogovors] = useState([])
  const [agrDogovors, setAgrDogovors] = useState([])
  const [selectedAgrdog, setSelectedAgrdog] = useState()
  const [reqTypes, setReqTypes] = useState([])
  const [selectedDog, setSelectedDog] = useState(StorageService.getLocalDogovor())
  const [auth, setAuth] = useState(StorageService.getLocalAuth())
  const [loading, setLoading] = useState(true)
  const [lettersCount, setLettersCount] = useState(0)
  const [nextAgrEventsCount, setNextAgrEvents] = useState(0)
  const [countDogovors, setCountDogovors] = useState(0)
  const [countNotReads, setCountNotReads] = useState(0)
  const [countVistSf, setCountVistSf] = useState(0)
  const [loadSyncUser, setLoadSyncUser] = useState(false)

  useEffect(() => {
    (async function () {
      if (auth){
        //получаем пользователя
        const res_user = await getUser()
        if (res_user.isOk) {
          setUser(res_user.data)
          const lastUser = StorageService.getLocalUser()
          var selectDogovor = null

          //получаем договора пользователя
          const res_dogovors = await getUserDogovors()
          if (res_dogovors.isOk) {
            const listUserDogs = res_dogovors.data
            var localDog = StorageService.getLocalDogovor()
            setUserDogovors(listUserDogs)
            const isLocalDogActual = (listUserDogs && localDog && listUserDogs.find( dog => dog.kodDog === localDog.kodDog))
            if (!isLocalDogActual) {
              StorageService.removeLocalDodovor()
              localDog = null
            }
            selectDogovor = 
            (localDog && (lastUser.id === res_user.data.id) && isLocalDogActual) ? //если есть последний договор и зашли под тем же пользователем И не удаляли договор из связок
            listUserDogs.find(dog => dog.kodDog === localDog.kodDog) ://localDog : //показываем последний выбранный //теперь заменяю договор на случай его изменения
            (listUserDogs && listUserDogs.length > 0) ? //если не выбирали раньше, но есть подвязанные договора
            listUserDogs[0] : //показываем первый из подключенных в списке
            null //если нет подключенных договоров
            setSelectedDog(selectDogovor)
            if (selectDogovor) StorageService.setLocalDogovor(selectDogovor)
            else StorageService.removeLocalDodovor()
          } else {
            notify(res_dogovors.message, 'error', 5000)
          }
          //получаем типы заявок
          const res_types = await getRequestTypes()
          if (res_types.isOk) {
            setReqTypes(res_types.data)
          } else {
            notify(res_types.message, 'error', 5000)
          }

          //запомним последнего пользователя
          StorageService.setLocalUser(res_user.data)

          //кол-во непрочитанных сообщений для стикера
          const countLettersData = await getCountNotReaded()
          if (countLettersData.isOk) {
            setLettersCount(countLettersData.data.letters)
          } else {
            notify(countLettersData.message,'error',5000)
          }

          //получим агрегированные договора пользователя
          const agrRes = await getUserAgrDogovors()
          if (agrRes.isOk) {
            const resDogovors = agrRes.data
            setAgrDogovors(resDogovors)
            const localAgrDog = StorageService.getLocalAgrDogovor()
            let selectedAgrDog = ((localAgrDog && (lastUser.id === res_user.data.id)) ? //если есть последний договор и зашли под тем же пользователем
              resDogovors.find(dog => dog.kodDog === localAgrDog.kodDog) ://localAgrDog : //показываем последний выбранный
              (resDogovors && resDogovors.length > 0) ? //если не выбирали раньше, но есть подвязанные договора
              resDogovors[0] : //показываем первый из подключенных в списке
              null //если нет подключенных договоров
            )
            setSelectedAgrdog(selectedAgrDog)
            if (selectedAgrDog) StorageService.setLocalAgrDogovor(selectedAgrDog)
            else StorageService.removeLocalAgrDodovor()
          } else {
            notify(agrRes.message, 'error',5000)
          }

          //посчитаем кол-во не наступивших событий по агрегатору
          const agrCountRes = await getCountNextEvents()
          if (agrCountRes.isOk) {
            setNextAgrEvents(agrCountRes.data)
          } else {
            notify(agrCountRes.message, 'error',5000)
          }

          //посчитаем кол-во точек без показаний в периоде, если срок передачи истекает
          const date = new Date()
          if ([1,2,3].includes(date.getDate()) && selectDogovor !== null) {
            //если сегодня с 1 по 3 число и выбран договор
            const countRes = await getCountNotReads(selectDogovor.kodDog)
            if (countRes.isOk) {
              setCountNotReads(countRes.data.count)
            } else {
              notify(countRes.message,'error',5000)
            }
          }

          //посчитаем кол-во фин. документов (авансов) с сегодняшним днем оплаты
          if (selectDogovor !== null) {
            const resData = await getCountVistSf(selectDogovor.kodDog)
            if (resData.isOk) {
              setCountVistSf(resData.data)
            } else {
              notify(resData.message,'error',5000)
            }
          }

        } else {
          StorageService.removeLocalAuth()
        }
      }
      setLoading(false)
    })()
  }, [auth])

  const signIn = useCallback(async (email, password) => {
    const fingerprint = await generateFingerpint()
    const result = await sendSignInRequest(email, password, fingerprint)
    if (result.isOk) {
      StorageService.setLocalAuth(result.data.access, result.data.refresh, fingerprint)
      setAuth(result.data)
      //console.log('in Context: ' + result.data.dogovors)
      setCountDogovors(result.data.dogovors)
    }

    return result
  }, [])

  const signOut = useCallback(async () => {
    await logout()
    setUser()
  }, [])

  const updateCountNotReads = useCallback(async (p_dogovor) => {
    const date = new Date()
    if ([1,2,3].includes(date.getDate()) && p_dogovor !== null) {
      //если сегодня с 1 по 3 число и выбран договор
      const countRes = await getCountNotReads(p_dogovor.kodDog)
      if (countRes.isOk) {
        setCountNotReads(countRes.data.count)
      } else {
        notify(countRes.message,'error',5000)
      }
    }
  }, [])

  const updateCountVistSf = useCallback(async (p_dogovor) => {
    if (p_dogovor !== null) {
      //посчитаем кол-во фин. документов (авансов) с сегодняшним днем оплаты
      if (p_dogovor !== null) {
        const resData = await getCountVistSf(p_dogovor.kodDog)
        if (resData.isOk) {
          setCountVistSf(resData.data)
        } else {
          notify(resData.message,'error',5000)
        }
      }
    }
  }, [])

  const syncUserData = useCallback(async () => {
    setLoadSyncUser(true)
    const res = await syncUser()
    if (res.isOk) {
      //notify(res.message, 'success',10000)
    } else {
      notify(res.message, 'error', 10000)
    }
    setLoadSyncUser(false)
    //пока просто перезагружаем страницу (меня заставили)
    window.location.reload();
  }, [])

  //отдельно запустим синхронизацию при авторизации
  useEffect(() => async function () {
    if (auth) syncUserData()
  },[auth, syncUserData])

  return (
    <AuthContext.Provider value={{ user, setUser, dogovors, countDogovors, selectedDog, reqTypes, setSelectedDog, signIn, signOut, loading, 
      lettersCount, setLettersCount, agrDogovors, selectedAgrdog, setSelectedAgrdog, nextAgrEventsCount, countNotReads, setCountNotReads,
      updateCountNotReads, countVistSf, updateCountVistSf, syncUserData, loadSyncUser }} {...props} />
  )
}

const AuthContext = createContext({})
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
