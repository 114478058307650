import StorageService from '../services/storage.service'
import { logout } from './auth'

const request = async (url, method = 'GET', body = null, headers = {}) => {

    const auth = StorageService.getLocalAuth()
    
    try {
        if (body) {
            
            if (headers !== 'multipart'){
                headers['Content-Type'] = 'application/json'
                body = JSON.stringify(body)
            }
            else {
                headers = []
            }
        
        }
        
        var response = await fetch(url, {method,body,headers})
                
        //если не авторизован, попробуем сделать refresh
        if (response.status === 401 && auth && auth.refresh) {
            console.log('update access key...')
            //console.log({ refresh: auth.refresh, fingerprint: auth.fingerprint })

            const body_auth = JSON.stringify({ refresh: auth.refresh, fingerprint: auth.fingerprint })
            const headers_auth = {}
            headers_auth['Content-Type'] = 'application/json'

            const res_auth = await fetch('/api/account/refresh',{method: 'POST', body: body_auth, headers: headers_auth})

            if (res_auth.status === 401) {
                await logout()//auth.logout()
                //window.location.reload()
            }

            const res_data = await res_auth.json()

            //обновляем ключи без перезагрузки страницы
            StorageService.setLocalAuth(res_data.access, res_data.refresh, auth.fingerprint)

            // еще раз выполнить запрос
            response = await fetch(url, {method,body,headers})
        }
        const data = await response.json()
        console.log('('+ url +') Server:',data)

        if (!response.ok) {
            if (data && data.status && data.status === 'reset') {
                //если пользователю сбросили пароль принудительно
            } else if (url.includes('login')) {
                return {isOk: false, message: data.message}
            }
            else throw new Error(data.message || 'Что-то пошло не так')
        }

        return data
    } catch (e) {
        if (url.includes('login')) return {isOk: false, message: 'Неизвестная ошибка! Возможно, сервер недоступен.'}
        throw e
    }
}

export default request

export const err500res = {
    isOk: false,
    message: 'Не удалось загрузить данные с сервера! Попробуйте перезагрузить страницу..'
}