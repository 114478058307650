import React, { useEffect, useRef, useState } from "react"
import DataGrid, { Column, Editing, Texts } from 'devextreme-react/data-grid'
import { useAuth } from "../../contexts/auth"
import { addToDog, checkEmail, deleteUserDog, getAuthorities, getUsersOfDogovorWithAuth, registry , editRoles as apiEditRoles} from "../../api/administration"
//import List from 'devextreme-react/list'
import LoadPanel from 'devextreme-react/load-panel'
import Button from 'devextreme-react/button'
import Form, { ButtonItem, ButtonOptions, EmailRule, Item, Label, RequiredRule } from 'devextreme-react/form'
import { Popup } from "devextreme-react"
import TagBox from 'devextreme-react/tag-box'
import notify from "devextreme/ui/notify"
import LoadIndicator from 'devextreme-react/load-indicator'
import { resetPassword } from "../../api/auth"
import AccessDenied from "../../components/access-denied/access-denied"
import {verifyAccess} from '../../utils/admin.utils.js'

const AdministrationPage = () => {

    const [visiblePage, setVisiblePage] = useState(true)

    const {selectedDog, user} = useAuth()
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [auths, setAuths] = useState([])
    const [popupVisible, setPopupVisible] = useState(false)
    const emailRef = useRef({})
    const fioRef = useRef({})
    const [checkEmailMessage, setCheckEmailMessage] = useState(null)
    const [loadingCheckEmail, setLoadingCheckEmail] = useState(false)
    const [visibleFio, setVisibleFio] = useState(false)
    const [visibleListAuths, setVisibleListAuths] = useState(false)
    const [visibleAddToDog, setVisibleAddToDog] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [popupRolesVisible, setPopupRolesVisible] = useState(false)
    const [editRoles, setEditRoles] = useState([])
    const [editUserDog, setEditUserDog] = useState(null)

    useEffect(() => {
        const pageRoles = [1]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            setLoading(true)
            const authsRes = await getAuthorities()
            if (authsRes.isOk) setAuths(authsRes.data)
            const resUsers = await getUsersOfDogovorWithAuth(selectedDog.kodDog)
            if (resUsers.isOk) {
                setUsers(resUsers.data)
            }
            setLoading(false)
        })()
    }, [selectedDog])

    const hiddingRolesPopup = () => {
        setPopupRolesVisible(false)
        setEditRoles([])
        setEditUserDog(null)
    }

    const onOpenRolesPopup = () => {
        //console.log(editRoles)
    }

    const onEditRoles = (e) => {
        //console.log(e.value)
        setEditRoles(e.value)
    }

    //отправляем новый спиоск ролей пользователя на договоре
    const onSaveEditRoles = async () => {
        if (editRoles.length === 0 || !editUserDog) return
        var roles = []
        for (let i=0; i<editRoles.length; i++) {roles.push(editRoles[i])}
        setLoading(true)
        const resRoles = await apiEditRoles(editUserDog, roles)
        if (resRoles.isOk) {
            notify(resRoles.message,'success',5000)
            setPopupRolesVisible(false)
            refreshGrid()
        }
        setLoading(false)
    }

    const roleCellRender = (e) => {
        const data = e.data
        return (
            <>
                {/*{data.roles && data.roles.length > 0 &&}*/}
                <ul>
                    {data.roles.map(role => <li key={role.id_authority}>{role.name_authority}</li>)}
                    <li style={{listStyleType: 'none'}}>
                        <a href="#/admin" 
                        onClick={() => {setEditUserDog(data.id_user_dog);setPopupRolesVisible(true);}}
                        >Редактировать</a>
                    </li>
                </ul>
            </>
        )
    }

    const hiddingPopup = () => {
        setPopupVisible(false)
        setVisibleFio(false)
    }

    const onAddBtnClick = () => {
        setPopupVisible(true)
    }

    const onCheckEmail = async (e) => {
        e.preventDefault()
        const {email} = emailRef.current
        console.log(email)
        clearForm()
        setLoadingCheckEmail(true)
        const resCheckEmail = await checkEmail(email, selectedDog.kodDog)
        if (resCheckEmail.isOk) {
            const data = resCheckEmail.data
            setCheckEmailMessage(data.message)
            switch (data.status) {
                case 0 : { //не зарегистрирован
                    setVisibleFio(true)
                    setVisibleListAuths(true)
                    setVisibleAddToDog(false)
                    setSelectedUser(null)
                    break
                }
                case 1: { //не в договоре
                    setVisibleFio(false)
                    setVisibleListAuths(true)
                    setVisibleAddToDog(true)
                    setSelectedUser(data.user)
                    break
                }
                case 2: { //уже в договоре
                    setVisibleFio(false)
                    setVisibleListAuths(false)
                    setVisibleAddToDog(false)
                    setSelectedUser(data.user)
                    break
                }
                default: break
            }
        } else {
            notify(resCheckEmail.message, 'error', 10000)
        }
        setLoadingCheckEmail(false)
    }

    const onSelectRoles = (e) => {
        setSelectedRoles(e.value)
    }

    //добавляем существующего пользователя к договору
    const onAddToDog = async () => {
        setLoading(true)

        console.log(selectedRoles)
        const resAddToDog = await addToDog(selectedUser.id,selectedDog.kodDog, selectedRoles)
        if (resAddToDog.isOk) {
            setPopupVisible(false)
            clearForm()
            notify(resAddToDog.message, 'success', 10000)
            await refreshGrid()
        } else {
            notify(resAddToDog.message, 'error',10000)
        }

        setLoading(false)
    }

    const refreshGrid = async () => {
        setLoading(true)
        const resUsers = await getUsersOfDogovorWithAuth(selectedDog.kodDog)
        if (resUsers.isOk) {
            setUsers(resUsers.data)
        }
        setLoading(false)
    }

    function clearForm() {
        setSelectedRoles([])
        setCheckEmailMessage(null)
        setVisibleAddToDog(false)
        setVisibleFio(false)
        setVisibleListAuths(false)
        setSelectedUser(null)
    }

    const onOpenPopup = async () => {
        // setLoading(true)
        // const authsRes = await getAuthorities()
        // if (authsRes.isOk) setAuths(authsRes.data)
        // setLoading(false)
    }

    const onRegistarationClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        const {firstName, lastName, middleName} = fioRef.current
        const {email} = emailRef.current
        if (selectedRoles.length === 0) {
            notify('Выберите роли пользователя!', 'error', 5000)
            return
        }
        const resReg = await registry(
            {
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                email: email
            },selectedDog.kodDog,selectedRoles
        )
        if (resReg.isOk) {
            notify(resReg.message,'success', 10000)
            clearForm()
            setPopupVisible(false)
            refreshGrid()
            const resEmail = await resetPassword(email,'"ЕЭС ГАРАНТ"')
            if (!resEmail.isOk) notify('Ошибка при отправке письма пользователю!', 'error', 10000)
        } else {
            notify(resReg.message,'error', 10000)
        }
        setLoading(false)
    }

    const onDelete = async (e) => {
        if (e.data.id === user.id) {
            notify('Нельзя открепить своего пользователя!', 'error', 5000)
            refreshGrid()
            return
        }
        const userDog = e.data.id_user_dog
        const resDelete = await deleteUserDog(userDog)
        if (resDelete.isOk) {
            notify(resDelete.message, 'success', 5000)
        } else notify(resDelete.message, 'error', 10000)
    }

    // function renderRoleEditor(e) {
    //     const userRoles = e.data.data.roles
    //     //setEditRoles(userRoles)
    //     return <TagBox 
    //         dataSource={auths}
    //         defaultValue={userRoles}
    //         //value={editRoles} 
    //         displayExpr="name_authority" 
    //         valueExpr="id_authority" 
    //         onValueChanged={e=>{console.log(e.value);}}
    //         placeholder="Выберите роли из списка.."
    //     />
    // }

    // const onEditRoles = (e) => {
    //     console.log(e.data)
    // }


    return (
        <>{visiblePage ?
        <div className={'content-block dx-card responsive-paddings'}>
            <i className="dx-icon dx-icon-preferences icon-header-h3 main-color main-color-light"/>
            <h3><strong>Управление доступом</strong></h3>
            <h5><strong>Подключенные пользователи:</strong></h5>
            <DataGrid
                style={{margin: '20px 0px 20px 0px'}}
                keyExpr="id"
                columnHidingEnabled={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                showBorders={true}
                noDataText="Нет подключенных пользователей"
                dataSource={users}
                onRowRemoved={onDelete}
                //selection={{mode: 'single'}}
                //onSelectionChanged={onSelectUser}
            >
                <Column dataField="last_name" caption="Фамилия" dataType="string" width={"150px"} allowEditing={false}/>
                <Column dataField="first_name" caption="Имя" dataType="string" width={"150px"} allowEditing={false}/>
                <Column dataField="middle_name" caption="Отчество" dataType="string" width={"200px"} allowEditing={false}/>
                <Column dataField="email" caption="email" dataType="string" width={"200px"} allowEditing={false}/>
                <Column dataField="roles" caption="Роли" cellRender={roleCellRender} width="200px"></Column>
                <Editing allowDeleting={true} allowAdding={false} allowUpdating={false} confirmDelete={true}>
                    <Texts 
                        deleteRow="Открепить" 
                        confirmDeleteMessage={"Вы точно хотите открепить пользователя от договора?"}
                        editRow="Редактировать"
                        cancelRowChanges="Отмена"
                        saveRowChanges="Сохранить"
                    />
                </Editing>
            </DataGrid>
            <LoadPanel visible={loading} />
            <Button text="Добавить пользователя" type="default" icon="plus" onClick={onAddBtnClick}></Button>
            <Popup
                visible={popupVisible}
                title="Добавить пользователя к договору"
                showCloseButton={true}
                closeOnOutsideClick={true}
                onHiding={hiddingPopup}
                maxWidth="500px"
                height={'auto'}
                maxHeight={'90%'}
                onShowing={onOpenPopup}
            >
                <form onSubmit={onCheckEmail}>
                    <Form
                        labelMode= "floating"
                        labelLocation="left"
                        formData={emailRef.current} 
                        onFieldDataChanged={clearForm}
                    >
                        <Item dataField={"email"}>
                            <Label text={"email"}/>
                            <RequiredRule message="Заполните email"/>
                            <EmailRule message="Email задан неверно" />
                        </Item>
                        <ButtonItem>
                            <ButtonOptions 
                                type={'default'}
                                useSubmitBehavior={true}
                                text="Проверить"
                                icon="check"
                            />
                        </ButtonItem>
                    </Form>
                </form>
                <LoadIndicator width={'24px'} height={'24px'} visible={loadingCheckEmail}/>
                {!loadingCheckEmail && <i>{checkEmailMessage}</i>}
                {visibleListAuths &&
                <div style={{margin: '20px 0px 10px 0px'}}>
                    <TagBox 
                        dataSource={auths}
                        value={selectedRoles} 
                        displayExpr="name_authority" 
                        valueExpr="id_authority" 
                        onValueChanged={onSelectRoles}
                        placeholder="Выберите роли из списка.."
                        label="Роли пользователя"
                        labelLocation="left"
                        labelMode="floating"
                    ></TagBox>
                </div>
                }
                <Button visible={visibleAddToDog} disabled={selectedRoles.length === 0} text="Добавить к договору" type="default" icon="plus" onClick={onAddToDog}></Button>
                {visibleFio &&
                <>
                    <form onSubmit={onRegistarationClick}>
                        <Form
                            labelMode="floating"
                            labelLocation="left"
                            formData={fioRef.current}
                            visible={visibleFio}
                        >
                            <Item dataField={"lastName"}>
                                <Label text="Фамилия" />
                                <RequiredRule message="Заполните фамилию" />
                            </Item>
                            <Item dataField={"firstName"}>
                                <Label text="Имя" />
                                <RequiredRule message="Заполните имя" />
                            </Item>
                            <Item dataField={"middleName"}>
                                <Label text="Отчество" />
                            </Item>
                            <ButtonItem>
                                <ButtonOptions 
                                    type={'default'}
                                    useSubmitBehavior={true}
                                    text="Зарегистрировать"
                                    icon="check"
                                />
                            </ButtonItem>
                        </Form>
                    </form>
                    <i>* На указанный email придет письмо со ссылкой для восстановления пароля!</i>
                </>
                }
            </Popup>
            <Popup
                visible={popupRolesVisible}
                title="Назначить роли пользователю:"
                showCloseButton={true}
                closeOnOutsideClick={true}
                onHiding={hiddingRolesPopup}
                maxWidth="500px"
                maxHeight={"90%"}
                height={'auto'}
                onShowing={onOpenRolesPopup}
            >
                <TagBox 
                    dataSource={auths}
                    //defaultValue={editRoles} 
                    //value={editRoles}
                    displayExpr="name_authority" 
                    valueExpr="id_authority" 
                    onValueChanged={onEditRoles}
                    placeholder="Выберите роли из списка.."
                    label="Роли пользователя"
                    labelLocation="left"
                    labelMode="floating"
                ></TagBox>
                <Button icon="check" className={'mt-3'} text="Сохранить" disabled={editRoles.length === 0} onClick={onSaveEditRoles} type="default"></Button>
            </Popup>
        </div>
        : <AccessDenied/>}</>
    )
}

export default AdministrationPage
