import React from 'react';

import TileView from 'devextreme-react/tile-view';

 const homes = [{
    ID: '1',
    ImageSrc: 'images/tile/1.jpg',
    heightRatio: 2,
     widthRatio: 2
}, {
     ID: '2',
     ImageSrc: 'images/tile/2.jpg',
     widthRatio: 2
 }, {
     ID: '3',
     ImageSrc: 'images/tile/3.jpg',
 }, {
     ID: '17',
     ImageSrc: 'images/tile/17.jpg',
 }, {
     ID: '4',
     ImageSrc: 'images/tile/4.jpg',
 }, {
     ID: '5',
     ImageSrc: 'images/tile/5.jpg',
     heightRatio: 2,
     widthRatio: 2
 }, {
     ID: '6',
     ImageSrc: 'images/tile/6.jpg',
 }, {
     ID: '7',
     ImageSrc: 'images/tile/7.jpg',
     widthRatio: 2
 }, {
     ID: '8',
     ImageSrc: 'images/tile/8.jpg',
     widthRatio: 2
 }]

class Tiles extends React.Component {
    render() {
        return (
            <TileView
                items={homes}
                itemRender={HomeImage}
                baseItemHeight={200}
                baseItemWidth={200}
                height={500}
            />
        );
    }
}

function HomeImage(data) {
    return <div className="dx-tile-image" style={{ backgroundImage: `url(${data.ImageSrc})` }}></div>;
}

export default Tiles;
