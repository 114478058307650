import Form, {Label, SimpleItem} from "devextreme-react/form"
import notify from "devextreme/ui/notify"
import React, {useEffect, useState} from "react"
import {getCompanyInfo} from "../../api/resource"
import {Button} from "devextreme-react/button"
import HelpPopup from "../../components/help-popup/help-popup"
import {useReqPopup} from "../../contexts/req-popup"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeadset} from "@fortawesome/free-solid-svg-icons";


const HelpPage = () => {

    const [company, setCompany] = useState({phone: 'Нет данных', adress: 'Нет данных'})
    const {setVisibleHelpPopup} = useReqPopup()

    useEffect(() => {
        (async function(){
            const companyData = await getCompanyInfo()
            if (companyData.isOk) {
                setCompany(companyData.data)
                
            } else {
                notify(companyData.message,'error',10000)
            }
        })()
    },[])

    const onHelpBtnClick = () => {
        setVisibleHelpPopup(true)
    }

    return (
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faHeadset} />
            <h3><strong>Техническая поддержка</strong></h3>
            <Form labelMode={'floating'} colCount={1} readOnly={true}>
                <SimpleItem dataField="adressField" editorOptions={{value: company.adress}}><Label text={"Адрес компании"}></Label></SimpleItem>
                <SimpleItem dataField="phoneField" editorOptions={{value: company.phone}}><Label text={"Телефон компании"}></Label></SimpleItem>
            </Form>
            <Button style={{marginTop: '20px'}} type={'default'} text="Написать в тех. поддержку" icon="edit" onClick={onHelpBtnClick}></Button>
            <HelpPopup />
        </div>
    )
}

export default HelpPage
