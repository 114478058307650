import React, { useState, createContext, useContext } from 'react'
import { getFirstDayOfMounth } from '../utils/date.utils'

function PeriodProvider(props) {

    const defaultPeriod = getFirstDayOfMounth(new Date())
    const [periodStart, setPeriodStart] = useState(defaultPeriod)
    const [periodEnd, setPeriodEnd] = useState(defaultPeriod)

  return (
    <PeriodContext.Provider value={{ periodStart, setPeriodStart, periodEnd, setPeriodEnd }} {...props} />
  )
}

const PeriodContext = createContext({})
const usePeriod = () => useContext(PeriodContext)

export { PeriodProvider, usePeriod }