export const navigation = [
  {
    text: 'Главная',
    path: '/home',
    icon: 'home',
    all_users_visible: true 
  },
  {
    text: 'Заключить договор',
    path: '/add-dogovor',
    icon: 'file',
    all_users_visible: true 
  },
  {
    text: 'Информация о договоре',
    icon: 'product',
    path: '/dogovor',
    all_users_visible: false 
  },
  {
    text: 'Объекты и ТУ',
    icon: 'mediumiconslayout',
    path: '/energoobjects',
    all_users_visible: false 
  },
  {
    text: 'Документы и оплаты',
    icon: 'folder',
    all_users_visible: false,
    items: [
      {
        text: 'Задолженность',
        path: '/debt'
      },
      {
        text: 'Финансовые документы',
        path: '/docs'
      },
      {
        text: 'Оплаты',
        path: '/pays'
      },
      {
        text: 'Лицевая карта',
        path: '/card'
      }
    ]
  },
  {
    text: 'Показания',
    icon: 'chart',
    all_users_visible: false,
    items: [
      {
        text: 'Подать показания',
        path: '/pokazania-new'
      },
      {
        text: 'История показаний',
        path: '/pokazania-history'
      }
    ]
  },
  {
    text: 'Агрегатор спроса',
    icon: 'money',
    all_users_visible: true,
    items: [
      {
        text: 'Управление спросом',
        path: '/agregator-reclame',
        all_users_visible: true
      },
      {
        text: 'События',
        path: '/agregator-events',
        all_users_visible: false
      },
      {
        text: 'Калькулятор эффективности',
        path: '/agregator-calculator',
        all_users_visible: true
      }
    ]
  },
  {
    text: 'Управление доступом',
    path: '/admin',
    icon: 'preferences'
  },
  {
    text: 'Техническая поддержка',
    path: '/help',
    icon: 'help'
  }
];
