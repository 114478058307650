import React, {useState} from 'react'
import {FileUploader} from "devextreme-react";
import Button from "devextreme-react/button";
import {getInfoFromSignedData} from "../../components/cryptopro/utils";
import {degrees, PDFDocument, rgb} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'

export const getPdfWithSignStamp= (signature64Array, BlobPDF, isDownloadPdf = false, fileNameSigPDF = 'test.sig.pdf', numLogo = 0)=>{
    const signs = [];
        return Promise.all(
            Array.from(signature64Array).map(item => {
                return getInfoFromSignedData(item).then((t) => {
                    signs.push(t);
                });
            })
        ).then(() => {
            return new Promise((resolve, reject) => {
                modifyPdf(signs, BlobPDF, numLogo).then(BlobPDF => {
                    if (isDownloadPdf) downloadAsFile(BlobPDF, fileNameSigPDF)
                    resolve(BlobPDF);
                })
            })
        }).catch(e => console.log(e.message));

}

// установка штампа
const modifyPdf = async (signs, BlobPDF, numLogo)=> {
    let pdfDoc
    try {
        pdfDoc = await PDFDocument.load(BlobPDF)
    } catch (e) {
        console.log(e.message, 'error', 2500);
        return;
    }
    const url2 = './fonts/arialbd.ttf'
    const fontBytes = await fetch(url2).then(res => res.arrayBuffer())
    // Устанавливаем шрифт
    let customFont;
    if (fontBytes) {
        pdfDoc.registerFontkit(fontkit);
        await pdfDoc.embedFont(fontBytes);
        customFont = await pdfDoc.embedFont(fontBytes);
    }
    //вместо встроенного / не работает с русским
    //const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const lastPage = pages[pages.length-1]
    //const { width, height } = lastPage.getSize()
    // x:0 у:0 - в PDF левый нижний угол
    const x = 20
    const rotate = 0
    const fontsize = 10
    const color = rgb(0, 0.57, 0.84)
    for (let i=0; i<signs.length; i++){
        // Можно добавить абзацы. Высота и положение штампа динамически изменится.
        const SN = (signs[i].SubjectName.includes("SN=")) ? '\nВладелец: ' + signs[i].SubjectName.replaceAll(/.*SN=([^,]+).*/gi,'$1'):null
        const G = (signs[i].SubjectName.includes("G=")) ? (SN?'':'\n') + signs[i].SubjectName.replaceAll(/.*G=([^,]+).*/gi,'$1'):null
        const fio = `${SN?SN:""} ${G?G:""}`
        let m = new Date();
        let dateString =
            m.getFullYear() + "." +
            ("0" + (m.getMonth()+1)).slice(-2) + "." +
            ("0" + m.getDate()).slice(-2) + " " +
            ("0" + m.getHours()).slice(-2) + ":" +
            ("0" + m.getMinutes()).slice(-2) + ":" +
            ("0" + m.getSeconds()).slice(-2);
        const date = (i===0) ? `\nДата подписания: ${dateString}`:``
        const drawText = `Подписант: ${signs[i].SubjectName.replaceAll(/.*CN=([^,]+).*/gi,'$1').replaceAll(/"/gi,"")}${fio}\nСертификат: ${signs[i].IssuerName.replaceAll(/CN=([^,]+).*/gi,'$1').replaceAll(/"/gi,"")}\nСерийный номер: ${signs[i].SerialNumber}\nДействителен с: ${signs[i].ValidFromDate.toLocaleDateString()} по: ${signs[i].ValidToDate.toLocaleDateString()}${date}`//
        const abzac = drawText.split('\n').length+1
        const y = (abzac*fontsize*1.3+10)*(i+1)-20
        // текст штампа
        lastPage.drawText(drawText, {
            x: numLogo===i+1 ? x+50: x,
            y: y,
            size: fontsize,
            font: customFont,
            lineHeight: fontsize*1.1,
            color: color,
            rotate: degrees(rotate),
        })
        // рамка
        lastPage.drawRectangle({
            x: x-(rotate?20:10),
            y: y+fontsize*2,
            width:400,
            height:-abzac*fontsize*1.3,
            borderColor: color,
            rotate: degrees(rotate),
            borderWidth:2
        })
        if (i===numLogo-1){
            const logo = './iesgarantznak.png'
            const pngImageBytes = await fetch(logo).then((res) => res.arrayBuffer())
            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.5)
            lastPage.drawImage(pngImage, {
                x: x,
                y: y-30,
                width: pngDims.width,
                height: pngDims.height,
                rotate:degrees(rotate)
            })
        }
    }
    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([new Uint8Array(pdfBytes)], {type: "application/pdf"});
    return blob;
}

// загрузка фала в браузере
const downloadAsFile = (data,fileNameSigPDF)=>{
    const a = document.createElement("a");
    a.href = URL.createObjectURL(data);
    a.download = fileNameSigPDF;
    a.click();
};

export default function StampSignPdf(props){
    const [filePdf, setFilePdf] = useState([])
    const [filesSig, setFilesSig] = useState([])

    const stamp = () => {
        if (filesSig.length > 0) {
            readFileAsDataUrl(filePdf[0]).then(BlobPDF => {
                let fileNameSigPDF = filePdf[0].name.replaceAll(/\.pdf/gi,'.sig.pdf');
                readFileAsArrayBuffer(filesSig[0]).then(data => {
                    let sign64 = _arrayBufferToBase64(data)
                    getPdfWithSignStamp([sign64,sign64],BlobPDF, true, fileNameSigPDF,1).then(e=>{
                        return e;
                    })
                }, error => {
                    console.log(error.message);
                });
            });
        }
      }

    const _arrayBufferToBase64=(buffer)=> {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    // для тестовой формы
    const readFileAsArrayBuffer =file =>
        new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () =>{
                resolve(fr.result);
            };
            fr.readAsArrayBuffer(file);
        });

    // для тестовой формы
    const readFileAsDataUrl=file =>
        new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () =>{
                resolve(fr.result);
            };
            fr.readAsDataURL(file);
        });

    return (
           <div>
                <h3>Тест штампа в пдф</h3>
                <div className="fileuploader-container">
                    <FileUploader labelText='Или перетащите сюда' accept="application/pdf" selectButtonText='Выберите PDF' uploadMode="useForm" value={filePdf} onValueChanged={(e) => {setFilePdf(e.value)}}/>
                </div>
                <div className="fileuploader-container">
                    <FileUploader labelText='Или перетащите сюда' accept=".sig" selectButtonText='Выберите SIG' uploadMode="useForm" value={filesSig} onValueChanged={(e) => {setFilesSig(e.value)}}/>
                </div>
               <br/>
                <Button
                    type="default"
                    icon="check"
                    text="Тест 1"
                    disabled={filePdf.length === 0 || filesSig.length === 0}
                    onClick={stamp}
                >
                </Button> Файлы грузяться вручную.
            </div>
    )
}

