import SelectPeriod from "../../components/select-period/select-period"
import DataGrid, {Column, Format, Grouping, Pager, Paging} from 'devextreme-react/data-grid'
import {getDogovorSf} from "../../api/dogovor"
import {useAuth} from '../../contexts/auth'
import notify from "devextreme/ui/notify"
import React, {useEffect, useState} from "react"
import LoadPanel from 'devextreme-react/load-panel'
import {usePeriod} from "../../contexts/period"
//import { getYm } from "../../utils/date.utils"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import AccessDenied from "../../components/access-denied/access-denied"
import {verifyAccess} from "../../utils/admin.utils"

const FinDocs = () => {
    const {selectedDog} = useAuth()
    const [finDocs, setFinDocs] = useState([])
    const [loading, setLoading] = useState(false)

    const pageSizes = [10, 25, 50, 100]

    const { periodStart, periodEnd } = usePeriod()

    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [3]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            setFinDocs([])
            const kodDog = selectedDog.kodDog
            setLoading(true)
            const res = await getDogovorSf(kodDog,periodStart,periodEnd)
            if (res.isOk) {
                setFinDocs(res.data)
            } else {
                notify(res.message, 'error', 5000)
            }
            setLoading(false)
        })()
        
        
    },[selectedDog,periodStart,periodEnd])

    // const sfRender = (data) => {
    //     const rym = data.data.rym
    //     const vistDay = data.data.vistDay
    //     const sumDolg = data.data.sumDolg
    //     const vidReal = data.data.vidReal
    //     var fontWeight = 'normal'
    //     var fontColor = 'black'
    //     const now = new Date()
    //     const isDolg = (vidReal === 2 && sumDolg > 0)//rym.toString() === getYm(now) && vistDay <= now.getDate() && (sumDolg && sumDolg > 0)
    //     if (isDolg) {
    //         fontWeight = 'bold'
    //         fontColor = 'red'
    //     }
    //     return <div style={{ fontWeight: fontWeight, color: fontColor}}>{data.data.sumNach}</div>;
    // }

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faFileInvoice} />
            <h3><strong>Финансовые документы</strong></h3>
            <div className="mt20 mb20">
                <SelectPeriod mode={'multiple'}/>
            </div>
            {!loading ? 
                <DataGrid
                    dataSource={finDocs}
                    noDataText="Нет документов за указанный период"
                    keyExpr="kodSf"
                    columnHidingEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    //onContentReady={this.onContentReady}
                    wordWrapEnabled={true} width={"100%"}
                >
                    {/* <GroupPanel visible={true} /> */}
                    {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
                    <Grouping autoExpandAll={false} />

                    <Column dataField="ym" caption="Период выставления" dataType="string" width="120px" 
                        cellRender={e => {
                            return e.value.toString().endsWith('.1') ? e.value.toString() + '0' : e.value.toString()
                        }}
                    />
                    <Column dataField="nameDoc" caption="Тип документа" dataType="string" width="200px"/>
                    {/* <Column dataField="nameKodDoc" caption="Тип документа" dataType="string" width="120px"/>
                    <Column dataField="vidrealcode" caption="Вид реализации" dataType="string" width="120px"/> */}
{/*                    <Column dataField="numPl" caption="№ документа на оплату" dataType="string" width="120px"/>
                    <Column dataField="datDeb" caption="Дата документа на оплату" dataType="date" width="120px"/>
            */}                               
                    <Column dataField="nomSf" caption="Номер документа начисления" dataType="string" width="150px"/>
                    <Column dataField="datSf" caption="Дата документа начисления" dataType="date" width="120px"/>           
                   {/* <Column dataField="nomSf" caption="Номер для печати" dataType="string" width="150px"/>*/}
                   {/* <Column dataField="sumPrint" caption="Сумма на печать" dataType="numeric" width="120px"/>*/}
                    <Column dataField="sumNach" caption="Начислено с налогом" dataType="numeric" width="120px">
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>
                    <Column dataField="sumOpl" caption="Сумма оплаты" dataType="numeric" width="150px">
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>
                    <Column dataField="sumDolg" caption="Задолженность" dataType="numeric" width="100px" >{/*cellRender={sfRender}*/}
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>

                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                    <Paging defaultPageSize={10} />
                </DataGrid>
                :
                <LoadPanel visible={loading} />
            }
        </div>
        : <AccessDenied/>}</>
    )
}

export default FinDocs
