import React from 'react';

export default function Item(data) {

        return (
            <div className='custom-item'>
                <img src={`/themes-img/${data.Img}.svg`} alt="theme-color"/>
                <div className='product-name'>
                    {data.NameRus}
                </div>
            </div>
        );
}
