import React from 'react';

import TileView from 'devextreme-react/tile-view';

     const homes2=[{
     ID: '10',
     ImageSrc: 'images/tile/10.jpg',
         widthRatio: 2
 }, {
     ID: '11',
     ImageSrc: 'images/tile/11.jpg'
 }, {
     ID: '12',
     ImageSrc: 'images/tile/12.jpg'
 }, {
     ID: '13',
     ImageSrc: 'images/tile/13.jpg'
 }, {
     ID: '14',
     ImageSrc: 'images/tile/14.jpg',
         heightRatio: 2,
 }, {
     ID: '15',
     ImageSrc: 'images/tile/15.jpg'
 }, {
     ID: '16',
     ImageSrc: 'images/tile/16.jpg',
 }, {
     ID: '18',
     ImageSrc: 'images/tile/18.jpg',
 }];
class Tiles2 extends React.Component {
    render() {
        return (
                <TileView
                    items={homes2}
                    itemRender={HomeImage}
                    baseItemHeight={200}
                    baseItemWidth={200}
                    height={500}
                />
        );
    }
}

function HomeImage(data) {
    return <div className="dx-tile-image" style={{ backgroundImage: `url(${data.ImageSrc})` }}></div>;
}

export default Tiles2;
