import React, { useEffect, useState } from "react";
//import { action } from "@storybook/addon-actions";
import FileSignature from "./";
//import {FileUploader} from "devextreme-react";
//import notify from "devextreme/ui/notify";
import { putSignDogovor } from "../../api/newdog";
import {getPdfWithSignStamp} from "../../pages/ecp/stamp-sign-pdf"
export default FileSignature
// {
//   title: "Подпись файла",
//   component: FileSignature
// };

export const FileSignatureCriptoPro = (props) => {
  const [filesForSignature, setFilesForSignature] = useState(null);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (filesForSignature && filesForSignature !== props.files) {
      setClear(true);
    }
    setFilesForSignature(props.files)
  },[props.files, filesForSignature])
  // const fileInputHandler2 = async (e) => {
  //   if (filesForSignature && filesForSignature !== e.value[0]) {
  //     setClear(true);
  //   }
  //   setFilesForSignature(e.value);
  // };

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  const onChange = async e => {
    //action("callback подписи")(e);
    if (Array.isArray(e)) {
      //e.forEach(item => downloadAsFile(item.sign, item.fileNameSign));
      const fd = new FormData()
      fd.append('id',props.idFile)
      fd.append('filename', e[0].fileNameSign)
      fd.append('filedata', e[0].sign)
      if (props.filePdfName){
        let signs = []
        let t = await toBase64(e[0].sign)
        signs.push(t.replaceAll(/(.*?;base64,)/gi,''))
        if (props.companyKey) signs.push(props.companyKey)
        let blob = await toBase64(props.files[0])
        getPdfWithSignStamp(signs,blob,false,props.filePdfName,0).then(e=>{
          fd.append('signed_file', e)
          fd.append('signed_filename', props.filePdfName)
          putSignDogovor(fd).then(()=>props.onSuccessSign())
        })
      } else{
        await putSignDogovor(fd)
        props.onSuccessSign()
      }
    } else {
      downloadAsFile(e.sign, e.fileNameSign);
    }
  };

  const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });


  const downloadAsFile = (data, name = "example.txt") => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(data);
    a.download = name;
    a.click();
  };

  const msgError = e => {
    console.log(e)
   //notify(e, 'error', 5000) gjrf
  };

  return (
    <div className={'big-input'}>

      {/* <FileUploader
          labelText={'Или перетащите сюда'}
          cssClass={'container-with-border'}
          selectButtonText={'Выберите файл (ы)'}
          multiple={true}
          accept={'*'}
          uploadMode={'useForm'}
          onValueChanged={fileInputHandler2} /> */}



      {/*<Button type="default" onClick={() => setClear(true)}>*/}
      {/*  Удалить подпись*/}
      {/*</Button>*/}
      <FileSignature
          {...{
            onChange,
            //onSelect:action("callback выбора подписи"),
            files: filesForSignature,
            clear,
            callbackError:msgError
          }}
      />
    </div>
  );
};


