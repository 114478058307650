import { Button } from "devextreme-react"
import { useState } from "react"
import { FileSignatureCriptoPro } from "./FileSignatureCriptoPro.stories"



const PopMainCert = (props) => {

    const [visible, setVisible] = useState(false)

    return (
        <div>
            {!visible &&
                <div>
                    <p>
                        Для подписания договора Вам необходимо иметь сертификат, установленный в браузере <a href="https://www.cryptopro.ru/products/cades/plugin" target="blank">плагин</a>, а также установленный на компьютере Cryptopro CSP.
                    </p>
                    <Button text="Продолжить" type="default" onClick={()=>setVisible(true)}/>
                </div>
            }
            {visible && <FileSignatureCriptoPro files={props.files} companyKey={props.companyKey}  filePdfName={props.filePdfName} idFile={props.idFile} onSuccessSign={()=>{setVisible(false); props.onSuccessSign()}}/>}
        </div>
    )
}

export default PopMainCert
