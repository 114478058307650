import request from './api'

export async function getHolidaysPeriodInfo(period) {
    try {
        const res = await request('/api/holidays/period-info','POST',{period: period})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}