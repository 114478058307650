import React from 'react';
export default function ConditionalIcon({ value }) {

    if(value) {
        return (
            <img
                src={`/themes-img/${value}.svg`}
                className='custom-icon'
                alt="theme-color"
            />
        );//// src={`https://js.devexpress.com/Demos/WidgetsGallery/Content/Images/Themes/${value}.svg`}
    } else {
        return (
            <div className='dx-dropdowneditor-icon'></div>
        );
    }
}
