import FileUploader from 'devextreme-react/file-uploader'
import {SelectBox} from "devextreme-react/select-box"
import Popup from "devextreme-react/popup"
import {Button} from "devextreme-react/button"
import {useState} from 'react'
import ScrollView from "devextreme-react/scroll-view";
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";

const NewdogFiles = (props) => {

    //const mode = props.mode
    const types = props.types
    const visible = props.visible

   // const [visiblePopup, setVisiblePopup] = useState(false)
    
    const [files, setFiles] = useState([])
    const [type,setType] = useState(types.length > 0 ? types[0] : null)


    return (
        <div>
        <Popup
            closeOnOutsideClick={false}
            visible={visible}
            onHiding={()=>{props.onClose(); setFiles([]); setType(types[0])}}
            maxWidth='500px'
            maxHeight={'90%'}
            height={'auto'}
            title="Добавить документ"
            titleRender={()=>PopupTitleWithIcon(faFileLines, "Добавить документ",()=>{props.onClose(); setFiles([]); setType(types[0])})}
        >
            <ScrollView className="big-input" width='100%' height='100%'>
                <div className="px-3">
            <SelectBox dataSource={types} key="id" displayExpr="nameType" value={type} onValueChanged={(e) => setType(e.value)}/>
            <FileUploader labelText='Или перетащите сюда' selectButtonText='Выберите файл(ы)' multiple={true} uploadMode="useForm" value={files} onValueChanged={(e) => {setFiles(e.value)}}/>
            <Button 
                type="default" 
                icon="check" 
                text="Добавить к заявке" 
                disabled={files.length === 0 || !type} 
                onClick={() => {props.onAddFiles(type,files); props.onClose();}}
            >
            </Button>
                </div>
            </ScrollView>
        </Popup>
        </div>
    )
}

export default NewdogFiles
