import {useMemo, useState} from "react";
import ccpa from "crypto-pro-cadesplugin";
//import {extract} from "../../utils";

export const useGetCertificate = thumbprint =>{
  const [certificate, setCertificate] = useState()
    useMemo(async () => {
        let certsList;
        const certsApi = await ccpa();
        try {
            certsList = await certsApi.getCertsList();
        } catch (e) {
            console.log(e.message);
            return null;
        }
        const findCert =  certsList.find(item=>item.thumbprint === thumbprint)
        setCertificate(findCert)
    }, [thumbprint]);
  return certificate
}
