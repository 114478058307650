import Form, { Item, ButtonItem, ButtonOptions, Label, CustomRule, RangeRule } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { getHolidaysPeriodInfo } from '../../api/holidays'
import { getFirstDayOfMounth, getLastDayOfMounth } from '../../utils/date.utils'
import LoadPanel from 'devextreme-react/load-panel'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from '../../contexts/auth'
//import { verifyAccess } from '../../utils/admin.utils'
import AccessDenied from '../../components/access-denied/access-denied'
import notify from 'devextreme/ui/notify'
import DateBox from 'devextreme-react/date-box'
import { getCurrentPeriod } from '../../api/dogovor'
//import { verifyAccess } from '../../utils/admin.utils'
const moment = require('moment')
require('moment-weekday-calc')


const AgregatorCalculator = () => {

    const [price, setPrice] = useState(0)
    const [loading, setLoading] = useState(0)
    const [visiblePage, setVisiblePage] = useState(true)
    const { selectedDog, selectedAgrdog } = useAuth()
    const [month, setMonth] = useState(getFirstDayOfMounth(new Date()))

    var formData = useRef({
        daysInMounth: 22,
        daysWithStatusSuccess: 22,
        countEvents: 5,
        countSuccessEvents: 5,
        redactionVolume: 0,
        countLoadHours: 4,
        priceSelectionResult: 0,
        koeff: 0,
        koeffReady: 1,
        koeffSuccess: 1,
        priceServices: 0,
        priceFine: 0
    })

    const [daysInMonth, setDaysInMonth] = useState(formData.current['daysInMounth'])
    const [sourceCountSuccessEvents, setSourceCountSuccessEvents] = useState(generateDays(formData.current['countEvents']))
    const [maxDaysWithStatusSuccess, setMaxCountDaysWithStatusSuccess] = useState(formData.current['daysInMounth'])
    const [daysWithStatusSuccess, setDaysWithStatusSuccess] = useState(formData.current['daysInMounth'])
    const [koeffReady, setKoeffReady] = useState(formData.current['koeffReady'])
    const [koeffSuccess, setKoeffSuccess] = useState(formData.current['koeffSuccess'])
    const [priceServices, setPriceServices] = useState(calculatePriceServices())
    const [priceFine, setPriceFine] = useState(calculatePriceFine())


    useEffect(() => {
        if (selectedAgrdog){
            (async function(){
                setLoading(1)
                const periodRes = await getCurrentPeriod(selectedDog.kodDog)
                if (periodRes.isOk) {
                setMonth(periodRes.data.period)
                setLoading(2)
            }
            })()
        }
    },[selectedAgrdog, selectedDog])

    useEffect(() => {
        // const pageRoles = [5]
        // if (!verifyAccess(pageRoles, selectedDog)) {
        //     setVisiblePage(false)
        //     return
        // }

        //если нет договора управления спросом
        if (!selectedAgrdog) {
            //formData.current['daysWithStatusSuccess'] = 22
            //formData.current['countEvents'] = 5
            formData.current['priceSelectionResult'] = 300000
            setLoading(2)
            return
        }
        (async function () {
            setVisiblePage(true)
            setLoading(1)
            let p_daysInMounth = formData.current['daysInMounth']
            //const periodRes = await getCurrentPeriod(selectedDog.kodDog)
            //if (periodRes.isOk) {
                //let actualPeriod = periodRes.data.period
                //setMonth(actualPeriod)
                const holidaysRes = await getHolidaysPeriodInfo(getFirstDayOfMounth(new Date(month)))
                if (holidaysRes.isOk && holidaysRes.data) { //если достали из календаря асусэ
                    p_daysInMounth = parseFloat(holidaysRes.data[0].work_days)
                } else { //если не получилось, считаем по обычному календарю
                    //notify(periodRes.message,'error',5000)
                    p_daysInMounth = moment().isoWeekdayCalc(getFirstDayOfMounth(month),getLastDayOfMounth(month, false),[1,2,3,4,5])
                }
            //}
            //console.log(daysInMonth)
            formData.current['daysInMounth'] = p_daysInMounth
            setDaysInMonth(p_daysInMounth)
            setMaxCountDaysWithStatusSuccess(p_daysInMounth)
            setDaysWithStatusSuccess(p_daysInMounth)
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
            setPrice(0)
            setLoading(2)
         })()
    },[selectedDog,selectedAgrdog, month])

    const onCalculate = (e) => {
        e.preventDefault()
        setPrice(calculate())
    }

    function calculate() {
        const d = formData.current

        var result = 0
        if (!selectedAgrdog) { //для новых пользователей агрегатора
            if (d['koeff'] < 0.2) {
                notify('Заявленный объем снижения должен быть >= 0.2 МВт', 'error', 3000)
                return 0
            }
            result = d['redactionVolume'] * d['priceSelectionResult'] * (d['koeff']/100) * d['countLoadHours'] / 4
            return result
        }
        result = (daysWithStatusSuccess * d['countSuccessEvents'] *
        d['redactionVolume'] * d['countLoadHours'] * d['priceSelectionResult'] * 
        (d['koeff'])/100) / (4 * d['daysInMounth'] * d['countEvents'])
        if (Number.isNaN(result)) result = 0
        //console.log(result.toFixed(2), result.toLocaleString('ru',{style:"currency", currency:"RUB"}))
        return result
    }

    const koeffOptions = {
        //"#0%",
        defaultValue: 0.00,
        step: 0.01,
        min: 0,
        max: 100,
        onValueChanged: function(){
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }
    const countEventEditor = {
        dataSource: [3,4,5],
        onValueChanged: function(){
            setSourceCountSuccessEvents(generateDays(formData.current['countEvents']))
            setKoeffSuccess(parseFloat((formData.current['countSuccessEvents'] / formData.current['countEvents']).toFixed(2)))
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    const countSuccessEventsEditor = {
        dataSource: sourceCountSuccessEvents,
        onValueChanged: function(){
            setKoeffSuccess(parseFloat((formData.current['countSuccessEvents'] / formData.current['countEvents']).toFixed(2)))
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    const daysInMonthOptions = {
        value: daysInMonth,
        onValueChanged: function(){
            setMaxCountDaysWithStatusSuccess(formData.current['daysInMounth'])
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    function generateDays(countDays) {
        return Array.from(Array(countDays + 1), (_, index) => index)//+ 1
    }

    const countLoadHoursEditor = {
        dataSource: [2,4],
        onValueChanged: function(){
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    const validateCountSucEvents = (e) => {
        const sucEvents = e.value
        const events = formData.current['countEvents']
        //console.log(sucEvents,events)
        return sucEvents <= events
    }

    // const validateCountEvents = (e) => {
    //     const sucEvents = formData.current['countSuccessEvents']
    //     const events = e.value
    //     //console.log(sucEvents,events)
    //     return sucEvents <= events
    // }

    const koeffEffectValidate = (e) => {
        const value = e.value
        let koeff = 0
        if (value >= 0.2 && value < 0.5) koeff = 75
        else if (value >= 0.5 && value < 1) koeff = 80
        else if (value >= 1) koeff = 90
        //console.log(koeff)
        setLoading(0)
        formData.current['koeff'] = parseInt(koeff)
        setLoading(2)
        if (koeff < 0.2) notify('Слишком малая величина разгрузки!','error',5000)
    }

    const daysWithStatusSuccessEditor = {
        min: 0,
        max: maxDaysWithStatusSuccess,
        value: daysWithStatusSuccess,
        onValueChanged: function(e){
            setDaysWithStatusSuccess(e.value)
            setKoeffReady(e.value < 7 ? 0 : parseFloat((formData.current['daysWithStatusSuccess']/formData.current['daysInMounth']).toFixed(2))) 
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    const priceSelectionResultOptions = {
        min: 0,
        format: "#,##0.00 ",
        onValueChanged: function(){
            setPriceServices(calculatePriceServices())
            setPriceFine(calculatePriceFine())
        }
    }

    const koeffReadyOptions = {
        value: koeffReady
    }

    const koeffSuccessOptions = {
        value: koeffSuccess
    }

    const priceServicesOptions = {
        value: priceServices,
        format: "#,##0.00 "
    }

    const priceFineOptions = {
        value: priceFine,
        format: "#,##0.00 "
    }

    function calculatePriceServices() {
        let a = (formData.current['countLoadHours']/4) * formData.current['redactionVolume'] * (1.25 * formData.current['koeffReady'] * formData.current['koeffSuccess'] - 
            0.175 * formData.current['koeffReady'] - 0.075) > 0
        return a ? parseFloat(((formData.current['countLoadHours']/4) * formData.current['redactionVolume'] * (1.25 * formData.current['koeffReady'] * formData.current['koeffSuccess'] - 
            0.175 * formData.current['koeffReady'] - 0.075) * formData.current['priceSelectionResult'] * formData.current['koeff']/100).toFixed(2)) : 0
    }

    function calculatePriceFine() {
        let a = (formData.current['countLoadHours']/4) * formData.current['redactionVolume'] * (1.25 * formData.current['koeffReady'] * formData.current['koeffSuccess'] - 
            0.175 * formData.current['koeffReady'] - 0.075) < 0 
            ? 
            ((formData.current['countLoadHours']/4) * formData.current['redactionVolume'] * (1.25 * formData.current['koeffReady'] * formData.current['koeffSuccess'] - 
            0.175 * formData.current['koeffReady'] - 0.075) * formData.current['redactionVolume'] - 1) 
            : 0
        let b = (0.01 * (formData.current['countLoadHours']/4) * formData.current['redactionVolume'] * formData.current['priceSelectionResult'])
        return parseFloat(Math.min(a,b).toFixed(2))
    }

    const calendarOptions = {
        applyValueMode: "useButtons",
        showDropDownButton:true,
        maxZoomLevel: 'year', 
        minZoomLevel: 'century'
    }

    const onMonthChanged = (e) => {
        //console.log(e)
        setMonth(e)
    }

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faCalculator} />
            <h3><strong>Калькулятор эффективности</strong></h3>
            <form onSubmit={onCalculate}>
                {loading === 2 ? 
                <div>
                    {selectedAgrdog && 
                        <DateBox 
                            labelMode={'floating'} 
                            label={'Период:'} 
                            calendarOptions={calendarOptions} 
                            value={month} 
                            onValueChange={onMonthChanged} 
                            displayFormat='monthAndYear'
                        />
                    }
                    <Form
                        labelMode={'floating'}
                        formData={formData.current}
                        //onFieldDataChanged={onCalculate}
                        colCountByScreen={colCountByScreen}
                    >
                        <Item editorType="dxNumberBox" dataField="daysInMounth" disabled={true} editorOptions={daysInMonthOptions}><Label text="Кол-во рабочих дней в месяце"/></Item>
                        <Item editorType="dxNumberBox" dataField="daysWithStatusSuccess" disabled={!selectedAgrdog} editorOptions={daysWithStatusSuccessEditor}>
                            <Label text="Кол-во рабочих дней в месяце со статусом готовности к снижению нагрузки"/>
                        </Item>
                        <Item editorType="dxSelectBox" dataField="countEvents" disabled={!selectedAgrdog} editorOptions={countEventEditor}>
                            <Label text="Количество событий"/>
                            {/* <CustomRule message="Не менее количества успешных событий!"  validationCallback={validateCountEvents} /> */}
                        </Item>
                        {selectedAgrdog && 
                            <Item editorType="dxSelectBox" dataField="countSuccessEvents" editorOptions={countSuccessEventsEditor}>
                                <Label text="Количество выполненных событий"/>
                                <CustomRule message="Не более количества событий!"  validationCallback={validateCountSucEvents} />
                            </Item>
                        }
                        <Item editorType="dxNumberBox" dataField="redactionVolume" editorOptions={{onValueChanged: koeffEffectValidate}}>
                            <Label text="Заявленный объем снижения, МВт"/>
                            <RangeRule min={0.2} message="Слишком малая величина разгрузки!" />
                            {/* <CustomRule validationCallback={koeffEffectValidate}/> */}
                        </Item>
                        <Item editorType="dxSelectBox" dataField="countLoadHours" editorOptions={countLoadHoursEditor}><Label text="Кол-во часов снижения нагрузки"/></Item>
                        <Item editorType="dxNumberBox" dataField="priceSelectionResult" editorOptions={priceSelectionResultOptions}><Label text="Цена по результатам отбора, руб/МВт"/></Item>
                        <Item editorType="dxNumberBox" dataField="koeff" editorOptions={koeffOptions} disabled={!selectedAgrdog}><Label text="Коэффициент распределения эффекта, %"/></Item>
                        {selectedAgrdog && <Item editorType="dxNumberBox" dataField="koeffReady" disabled={true} editorOptions={koeffReadyOptions}><Label text="Коэффициент готовности"/></Item>}
                        {selectedAgrdog && <Item editorType="dxNumberBox" dataField="koeffSuccess" disabled={true} editorOptions={koeffSuccessOptions}><Label text="Коэффициент выполнения"/></Item>}
                        {selectedAgrdog && <Item editorType="dxNumberBox" dataField="priceServices" disabled={true} editorOptions={priceServicesOptions}><Label text="Стоимость оказанных услуг, руб."/></Item>}
                        {selectedAgrdog && <Item editorType="dxNumberBox" dataField="priceFine" disabled={true} editorOptions={priceFineOptions}><Label text="Стоимость штрафов, руб."/></Item>}
                        <ButtonItem horizontalAlignment="left">
                                <ButtonOptions
                                    type={'default'}
                                    useSubmitBehavior={true}
                                    text="Расчитать"
                                    icon="check"
                                >
                                </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </div>
                : 
                <LoadPanel visible={loading === 1}/>}
            </form>
            <hr />
            {/* {todo поменять под devexpress} */}
            <h5>{'Предполагаемый доход: ' + price.toLocaleString('ru',{style:"currency", currency:"RUB"})}</h5>
        </div>
        : <AccessDenied/>}</>
    )
}

const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2
  };

export default AgregatorCalculator
