import React, { useState, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule, CompareRule, PatternRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';
import './create-account-form.scss';
import { passwordRulesLabel } from '../label-data';
import Validator from 'devextreme/ui/validator';
//import { getRegions } from '../../api/outer';
let formInstance
const CreateAccountForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  //const [regions, setRegions] = useState([])

  // useEffect(() => {
  //   (async function(){
  //     setLoading(true)
  //     const res = await getRegions()
  //     if (res.isOk) setRegions(res.data)
  //     setLoading(false)
  //   })()
  // }, [])

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { /*region,*/ email, password, phone, last_name, first_name, middle_name, confirmed_password } = formData.current;
    setLoading(true);

    const result = await createAccount(/*region*/null, email, password, '+7'+phone, last_name, first_name, middle_name, confirmed_password);
    setLoading(false);

    if (result.isOk) {
      history.push('/login');
      notify(result.message, 'success', 5000);
    } else {
      notify(result.message, 'error', 5000);
    }
  }, [history]);

  // const confirmPassword = useCallback(
  //   ({ value }) => value === formData.current.password,
  //   []
  // );
  const confirmPassword = () =>{
    return formData.current.password
  };

  // const checkPassword = useCallback(
  //   ({ value }) => (formData.current.confirmed_password ? value === formData.current.confirmed_password : true),
  //   []
  // );

  const [passwordMode, setPasswordMode] = useState('password')
  const passwordEditorOptions = { 
    placeholder: 'Пароль', 
    mode: passwordMode,
    onValueChanged: () => {
      const editor = formInstance && formInstance.getEditor('ConfirmPassword');
      if (editor && editor.option('value')) {
        const instance = Validator.getInstance(editor.element());
        instance.validate();
      }
    },
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  };

  const phoneEditorOptions = {
    mask: '+7 (YX0) 000-0000',
    maskRules: { X: /[01-9]/, Y: /[9]/ },
    maskInvalidMessage: 'Некорректный номер телефона',
  };
  const phonecheck = () =>{
    const editor = formInstance && formInstance.getEditor('phone');
    if (editor) {
      editor.instance().focus()
      editor.instance().blur()
      //setTimeout(() => editor.instance().blur(), 10);
      const instance = Validator.getInstance(editor.element());
      instance.validate();
  }}
  const [passwordConfirmMode, setPasswordConfirmMode] = useState('password')
  const confirmedPasswordEditorOptions = { 
    placeholder: 'Подтверждение пароля', 
    mode: passwordConfirmMode,
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordConfirmMode(passwordConfirmMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  };

  // const regionOptions = {
  //   dataSource: regions,
  //   valueExpr: 'idRegion',
  //   displayExpr: 'nameRegion',
  //   placeholder: 'Регион',
  //   stylingMode: 'filled',
  //   searchEnabled: true
  // }

  const onInitialized = (e) => {
    formInstance = e.component;
  }
  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} onInitialized={onInitialized} disabled={loading} labelMode="floating" labelLocation="left">
        {/* <Item
          dataField={"region"}
          editorType={"dxSelectBox"}
          editorOptions={regionOptions}
        > 
          <Label text="Регион" />
          <RequiredRule message="Выберите регион" />
        </Item>*/}
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email обязателен" />
          <EmailRule message="Email задан неверно" />
          <Label text="Email"/>
        </Item>
        <Item
          dataField={'phone'}
          editorType={'dxTextBox'}
          editorOptions={phoneEditorOptions}
          name="phone"
        >

          <PatternRule
              message="Некорректный формат"
              pattern={/[9]\d{9}$/}
          />
          <Label text="Телефон"/>
          <RequiredRule message="Телефон обязателен" />
        </Item>
        <Item
          dataField={'last_name'}
          editorType={'dxTextBox'}
          editorOptions={lastNameEditorOptions}
        >
          <RequiredRule message="Фамилия обязательна" />
          <Label text="Фамилия" />
        </Item>
        <Item
          dataField={'first_name'}
          editorType={'dxTextBox'}
          editorOptions={firstNameEditorOptions}
        >
          <RequiredRule message="Имя обязательно" />
          <Label text="Имя" />
        </Item>
        <Item
          dataField={'middle_name'}
          editorType={'dxTextBox'}
          editorOptions={middleNameEditorOptions}
        >
          <Label text="Отчество" />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}

        >
          <RequiredRule message="Пароль обязателен" />
          <Label text="Пароль"/>
          {/*<CustomRule*/}
          {/*  message={'Пароли не совпадают'}*/}
          {/*  validationCallback={checkPassword}*/}
          {/*/>*/}
        </Item>
        <Item
            name="ConfirmPassword"
          dataField={'confirmed_password'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Подверждение пароля обязательно" />
          {/*<CustomRule*/}
          {/*  message={'Пароли не совпадают'}*/}
          {/*  validationCallback={confirmPassword}*/}
          {/*/>*/}
          <CompareRule
              message="Пароль и подтверждение пароля не совпадают"
              comparisonTarget={confirmPassword}
          />
          <Label text="Подтверждение пароля" />
        </Item>
        <Item>
          <div className="text">{passwordRulesLabel}</div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true} onClick={phonecheck}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Зарегистрироваться'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Уже есть аккаунт? <Link to={'/login'}>Войти</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { mode: 'email' };
const lastNameEditorOptions = {placeholder: 'Фамилия' };
const firstNameEditorOptions = {placeholder: 'Имя' };
const middleNameEditorOptions = { placeholder: 'Отчество' };


export default CreateAccountForm
