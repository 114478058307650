import React from 'react';
//import {Link} from "react-router-dom"; , {useEffect, useRef}
import './home3.scss';
//import homeWidgetsData from '../../data/homeWidgetsData'
import "../../assets/scss/mdb.scss_";
import {/*Button,*/ Gallery} from "devextreme-react";
import Tiles from "./tile";
import Tiles2 from "./tile2";
//import Home2 from "../home2/home2";
const gallery = [
    // {
    //     path:'images/02_min.jpg',
    //     name:'100700',
    //     h1:'ЭС-Гарант энергосервисная компания №1*',
    //     subh1:'Комплексные решения в сфере энергоснабжения ' +
    //         'и энергосбережения для вашего бизнеса'
    // },
    {
        path:'images/06_min.jpg',
        name:'100500',
        h1:'Энергоснабжение для бизнеса на розничном рынке',
        subh1:'Минимальные сроки заключения договора'
    }
];


// function SvgBig() {
//     const blocksvg = useRef(null);
//     const textMaskLine = useRef(null);
//     const textFirstLineLight = useRef(null);
//     const textFirstLineDark = useRef(null);
//     const textSecondLineLight = useRef(null);
//     const textSecondLineDark = useRef(null);
//     //const svg = document.querySelector('.svg');
//     if (!blocksvg.current) {
//         //return null;
//     }
//     const svgRect = { x: 0, y: 0, w: 0, h: 0 };
//
//     // function updateSvgRect() {
//     //     const rect = blocksvg.current.getBoundingClientRect();
//     //     svgRect.x = rect.x + window.scrollX;
//     //     svgRect.y = rect.y + window.scrollY;
//     //     svgRect.w = rect.width;
//     //     svgRect.h = rect.height;
//     // }
//     const updateSvgRect = _ => {
//         const rect = blocksvg.current.getBoundingClientRect();
//         svgRect.x = rect.x + window.scrollX;
//         svgRect.y = rect.y + window.scrollY;
//         svgRect.w = rect.width;
//         svgRect.h = rect.height;
//     };
//
//     //updateSvgRect();
//     useEffect(() => {
//         //window.addEventListener('resize', updateSvgRect, true);
//         return () => {
//             window.addEventListener('resize', updateSvgRect, true);
//         };
//     }, []);
//     //window.addEventListener('resize', updateSvgRect);
//     let scrollProgressPrev = clamp((svgRect.y - window.scrollY) / (window.innerHeight - svgRect.h), 0, 1);
//     const Row = () => <text className="svg__mask-line" style={'transform':`translate(0 ${lerp(0, -200, t)})`} ref={textMaskLine} x="50%" y="96%" textAnchor="middle" fill="#fff">150</text>;
//     const setAttr = (t) => {
//         textMaskLine.current.setAttribute('transform', `translate(0 ${lerp(0, -200, t)})`);
//         textFirstLineDark.current.setAttribute('transform', `translate(0 ${lerp(0, -400, t)})`);
//         textFirstLineLight.current.setAttribute('transform', `translate(0 ${lerp(0, -400, t)})`);
//         textSecondLineDark.current.setAttribute('transform', `translate(0 ${lerp(0, -300, t)})`);
//         textSecondLineLight.current.setAttribute('transform', `translate(0 ${lerp(0, -300, t)})`);
//     }
//     function loop(now) {
//         const y = svgRect.y - window.scrollY;
//         const scrollProgress = clamp(y / (window.innerHeight - svgRect.h), 0, 1);
//         scrollProgressPrev = lerp(scrollProgressPrev, scrollProgress, 0.05);
//         const t = 1 - scrollProgressPrev;
//
//         setAttr(t);
//         requestAnimationFrame(loop);
//     }
//     requestAnimationFrame(loop);
//     function clamp(val, min, max) {
//         return Math.min(Math.max(val, min), max);
//     }
//     function lerp(v0, v1, t) {
//         return v0 * (1 - t) + v1 * t;
//     }
//     return (
//             <div className="container-fluid  pt60 pb60 dark-background">
//                 <div className="block">
//                     <svg className="block__svg svg" ref={blocksvg} viewBox="0 0 1900 650" width="100%" height="100%">
//                         <defs>
//                             <mask id="text-mask" x="0" y="0" width="100%" height="100%">
//                                 <text className="svg__mask-line" ref={textMaskLine} x="50%" y="96%" textAnchor="middle" fill="#fff">150</text>
//                             </mask>
//                         </defs>
//
//                         <image
//                             href="https://www.660citynews.com/wp-content/blogs.dir/sites/8/2021/08/iStock-1161679866.jpg"
//                             preserveAspectRatio="xMidYMid slice" width="100%" height="100%" mask="url(#text-mask)"/>
//
//                         <g className="svg__first-line">
//                             <text className="svg__line-dark" ref={textFirstLineDark} x="1%" y="65%" textAnchor="start" fill="#000">lorem</text>
//                             <g mask="url(#text-mask)">
//                                 <text className="svg__line-light" ref={textFirstLineLight} x="1%" y="65%" textAnchor="start" fill="#fff">lorem</text>
//                             </g>
//                         </g>
//
//                         <g className="svg__second-line" stroke="#fff">
//                             <text className="svg__line-dark" ref={textSecondLineDark} x="50%" y="100%" textAnchor="middle"
//                                   fill="#000">ipsumm
//                             </text>
//                             <g mask="url(#text-mask)">
//                                 <text className="svg__line-light" ref={textSecondLineLight} x="50%" y="100%" textAnchor="middle"
//                                       fill="#fff">ipsumm
//                                 </text>
//                             </g>
//                         </g>
//                     </svg>
//                 </div>
//             </div>
//     );
// }

const renderGalleryItem = (itemData) => {
    return (
        <div>
    <div className="index-business--content">
        <div className="dx-card wide-card">
            <div className="row">
                <div className="col-lg-3 col-sm-1"></div>
                <div className="col-lg-6 col-sm-10 p20 center-content">
                    <div className="section-title animated fadeInLeft text-responsive">{itemData.h1}</div>
                    <div className="index-business--text animated delay-1s fadeInLeft">{itemData.subh1}</div>
                    {/* <Button
                        text="Перейти в кабинет"
                        type="default"
                        stylingMode="contained"
                    /> */}
                </div>
                <div className="col-lg-3 col-sm-1"></div>
            </div>
        </div>
    </div>
            <div className="index-business--img" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + itemData.path})`,
                width:'100%'
            }}/>
        </div>
    );
}
class Home3 extends React.Component {
  render() {
    return (
        <React.Fragment>
            <div className="homePage3 mb100">
                <div className="widget-container">
                    <Gallery
                        id="gallery"
                        dataSource={gallery}
                        height={500}
                        animationEnabled={true}

                        slideshowDelay={6000}
                        loop={true}
                        width={'100%'}
                        showNavButtons={true}
                        showIndicator={true}
                        itemRender={renderGalleryItem}
                    />
                </div>
                {/*<SvgBig/>*/}
                <div className="container-fluid pt60 pb60">
                    <div className="container text-center">
                        {/*<h2 className='main-color'><strong>ЕЭС-Гарант энергосервисная компания №1*</strong></h2>*/}
                        {/*<p>Комплексные решения в сфере энергоснабжения*/}
                        {/*    и энергосбережения для вашего бизнеса</p>*/}
                        <Tiles/>
                    </div>
                </div>
                <div className="container-fluid  pt60 pb60 dark-background">
                    <div className="container text-center">
                        <h2 className='main-color'><strong>Энергоснабжение для бизнеса на розничном рынке</strong></h2>
                        <p>Минимальные сроки заключения договора</p>
                        <Tiles2/>
                    </div>
                </div>
                {/* <div className="container-fluid pt60">
                    <h2 className='main-color text-center'><strong>Личный кабинет</strong></h2>
                    <Home2/>
                </div> */}
            </div>
        </React.Fragment>
    );
  }
}

export default Home3
