import React, {useCallback, useRef, useState} from 'react'
import {Popup} from 'devextreme-react/popup'
import Form, {
    ButtonItem,
    ButtonOptions,
    GroupItem,
    Item,
    Label,
    RangeRule,
    RequiredRule,
    StringLengthRule,
} from 'devextreme-react/form'
import 'devextreme-react/text-area'
import 'devextreme-react/file-uploader'
import {useReqPopup} from '../../contexts/req-popup'
import {useAuth} from '../../contexts/auth'
import './request-popup.scss'
import {sendUserRequest} from '../../api/request'
import notify from 'devextreme/ui/notify'
import LoadIndicator from 'devextreme-react/load-indicator'
import ScrollView from 'devextreme-react/scroll-view'
import {getPointsList} from '../../api/dogovor'
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faPenClip} from "@fortawesome/free-solid-svg-icons";
import {getPointPus} from '../../api/point'

const RequestPopup = (props) => {

    const { isOpen, defaultType, setDefaultType, defaultMessage, setDefaultMessage, defaultFiles, openReqPopup, 
        dogVisibleTypes, pointVisibleTypes, tuList, setTuList, selectedTu, setSelectedTu, disabledTypes, pu, setPu } = useReqPopup()
    const { reqTypes, lettersCount, setLettersCount, selectedDog } = useAuth()
    const formData = useRef({})
    const [loading, setLoading] = useState(false)
    const pointDisabled = false

    function hidingPopup() {
        openReqPopup(false, null)
    }
    
    const onSendRequest = useCallback(async (e) => {
        e.preventDefault();
        const { point, message, attachments, 
            nom_pu, name_typepu, dat_s, razr1, razr2, god_vip, kvartal_pp,
            god_pp, balance_name, expl_name, dat_start_w, dat_end_w, mpi } = formData.current;

        const fd = new FormData()
        fd.append('message',message)
        fd.append('type',defaultType)//type_request
        //если в заявке обязательные документы
        if ([1,5,7].includes(defaultType) && attachments.length === 0) {
            notify('Прикрепите соотвествующий(е) документ(ы)!','error', 5000)
            return
        }
        //если в заявке участвует договор
        if (selectedDog && dogVisibleTypes.includes(defaultType)) fd.append('dogovor',selectedDog.kodDog)
        //если в заявке участвует точка учета
        if (selectedDog && pointVisibleTypes.includes(defaultType)) fd.append('point',point)
        //если подаем заявку на замену ПУ
        if (selectedDog && defaultType === 5) {
            if (god_vip > god_pp) {
                notify('Год выпуска не может быть больше года ПП!', 'error', 5000)
                return
            }
            fd.append('nom_pu', nom_pu)
            fd.append('name_typepu', name_typepu)
            fd.append('dat_s', dat_s)
            fd.append('razr1', razr1)
            fd.append('razr2', razr2)
            fd.append('god_vip', god_vip)
            fd.append('kvartal_pp', kvartal_pp)
            fd.append('god_pp', god_pp)
            fd.append('balance_name', balance_name)
            fd.append('expl_name', expl_name)
            fd.append('dat_start_w', dat_start_w)
            fd.append('dat_end_w', dat_end_w)
            fd.append('mpi', mpi)
        }
        //прикрепляем к заявке вложения
        for (let i=0; i<attachments.length; i++) {
            fd.append('file',attachments[i])
        }
        setLoading(true);
        const result = await sendUserRequest(fd)
        if (result.isOk) { //если успешно создали заявку
            openReqPopup(false)
            notify(result.message, 'success', 5000)
            setLettersCount(lettersCount + 1)
            //setDefaultPu(null)
        } else {
            notify(result.message, 'error', 5000);
        }
    
        setLoading(false)
      }, [openReqPopup, lettersCount, setLettersCount, selectedDog, dogVisibleTypes, pointVisibleTypes,defaultType]);

    const typeOptions = {
        dataSource: reqTypes,
        displayExpr: "name",
        valueExpr: "id",
        value: defaultType,
        placeholder: "Выберите тип запроса на обслуживание..",
        noDataText: "Не удалось получить данные с сервера",
        disabled: disabledTypes,
        onValueChanged: async function(e) {
            setDefaultType(e.value)
            const newType = reqTypes.find(type=> type.id === e.value)
            setDefaultMessage(newType ? newType.defaultText : null)
            if (selectedDog && pointVisibleTypes.includes(e.value) && tuList.length !== 1) {
                const res = await getPointsList(selectedDog.kodDog)
                if (res.isOk) {
                    setTuList(res.data)
                    if (res.data.length > 0) setSelectedTu(res.data[0].kodPoint)
                }
                else notify(res.message, 'error',10000)
            }
        }
    }

    const messageOptions = {
        height: 90,
        placeholder: 'Введите текст запроса..',
        showClearButton: true,
        value: parseMessage(defaultMessage)
    }

    //заменим дефолтный текст реквизитами
    function parseMessage(message) {
        if (!message) return null
        let res = message
        if (res.includes('<ИНН>') && selectedDog) {
            res = res.replace('<ИНН>', 'ИНН '+selectedDog.inn)
        }
        if (res.includes('<номер договора>') && selectedDog) {
            res = res.replace('<номер договора>', selectedDog.ndog)
        }
        if (res.includes('<название организации>') && selectedDog) {
            res = res.replace('<название организации>', selectedDog.name)
        }
        return res
    }

    const fileOptions = {
        selectButtonText: "Выберите файлы", 
        labelText: "Или перетащите файлы сюда",
        accept: "*/*",
        multiple: true,
        maxFileSize: 50000000,
        uploadMode: "useForm",
        value: defaultFiles ? defaultFiles : []
    }

    const dogovorOptions = {
        value: selectedDog ? ("№"+selectedDog.ndog+": "+selectedDog.name) : ''
    }

    const pointOptions = {
        defaultValue: selectedTu,//tuList.length === 1 ? tuList[0].kodPoint : null,
        value: selectedTu,
        dataSource: tuList,
        valueExpr: 'kodPoint',
        displayExpr: 'text',
        noDataText: 'Нет точек для отображения',
        placeholder: 'Выберите точку учета..',
        onValueChanged: async function(e) {
            setSelectedTu(e.value)
            //достанем ПУ
            const res = await getPointPus(e.value)
            if (res.isOk) {
                setPu(res.data && res.data.kodPointPu ? res.data : null)
            } 
        }
    }
    
    //const [defaultPu, setDefaultPu] = useState(null)
    const puOptions = {
        //value: defaultPu
    }

    return (
        <Popup 
            id="req-popup"
            visible={isOpen}
            onHiding={hidingPopup}
            closeOnOutsideClick={false}
            height='auto'
            maxHeight={'90%'} //форма с прибором не входит никак иначе
            maxWidth="800px"
            title="Запрос на обслуживание"
            showCloseButton={true}
            titleRender={()=>PopupTitleWithIcon(faPenClip, "Запрос на обслуживание",hidingPopup)}
        >
            <ScrollView className="big-input" width='100%' height='100%'>
                <form className="px-3" onSubmit={onSendRequest}>
                    <Form labelMode={'floating'}
                          labelLocation={'top'}
                        formData={formData.current}
                        id="request-form"
                        colCount={1}
                    >
                        <Item dataField="type_request" editorType="dxSelectBox" editorOptions={typeOptions}>
                            <Label text="Тип запроса"/>
                            <StringLengthRule max={2} message="Выберите тип запроса!" />
                        </Item>
                        {selectedDog && dogVisibleTypes.includes(defaultType) &&
                        <Item 
                            dataField="dogovor" 
                            editorType="dxTextBox" 
                            disabled={true} 
                            editorOptions={dogovorOptions}
                        >
                            <Label text="Выбранный договор"/>
                        </Item>
                        }
                        {selectedDog && pointVisibleTypes.includes(defaultType) && 
                        <Item 
                            dataField="point" 
                            editorType="dxSelectBox" 
                            disabled={pointDisabled} 
                            editorOptions={pointOptions}
                        >
                            <Label text="Точка учета"/>
                        </Item>
                        }
                        {selectedDog && defaultType === 5 &&
                        <GroupItem caption={pu ? 'Данные нового ПУ (взамен ПУ №'+pu.numPu+')' : 'Данные нового ПУ'}>
                            <Item dataField="nom_pu" editorType="dxTextBox" editorOptions={puOptions}>
                                <Label text={"Номер ПУ"}/>
                                <RequiredRule message="Введите номер ПУ!" />
                            </Item>
                            <GroupItem colCount={2}>
                                <Item dataField="name_typepu" editorType="dxTextBox" editorOptions={puOptions}>
                                    <Label text={"Тип счетчика"}/>
                                    <RequiredRule message="Введите тип счетчика!" />
                                </Item>
                                <Item dataField="dat_s" editorType="dxDateBox" editorOptions={{type: 'datetime', useMaskBehavior: true}}>
                                    <Label text={"Дата установки"}/>
                                    <RequiredRule message="Введите дату установки!" />
                                </Item>
                            </GroupItem>
                            <GroupItem colCount={2}>
                                <Item dataField="razr1" editorType="dxNumberBox" editorOptions={puOptions}>
                                    <Label text={"Разрядность целой части"}/>
                                    <RequiredRule message="Введите разрядность целой части!" />
                                </Item>
                                <Item dataField="razr2" editorType="dxNumberBox" editorOptions={puOptions}>
                                    <Label text={"Разрядность дробной части"}/>
                                    <RequiredRule message="Введите разрядность дробной части!" />
                                </Item>
                            </GroupItem>
                            <GroupItem colCount={2}>
                                <Item dataField="god_vip" editorType="dxNumberBox" editorOptions={{value: null}}>
                                    <Label text={"Год выпуска"}/>
                                    <RequiredRule message="Введите год выпуска!"/>
                                    <StringLengthRule min={4} max={4} message="Допустимо 4 символа!"/>
                                    <RangeRule min={1980} max={2099} message="Некорректно заполнен год выпуска!"/>
                                </Item>
                                <Item dataField="kvartal_pp" editorType="dxSelectBox" editorOptions={{dataSource: [1,2,3,4]}}>
                                    <Label text={"Квартал последней поверки"}/>
                                    <RequiredRule message="Введите квартал последней поверки!" />
                                    {/* <RangeRule min={1} max={4} message="Введите цифру от 1 до 4"/> */}
                                    {/* <StringLengthRule min={1} max={1} message="Допустим 1 символ!"/> */}
                                </Item>
                                <Item dataField="god_pp" editorType="dxNumberBox" editorOptions={{value: null}}>
                                    <Label text={"Год последней поверки"}/>
                                    <RequiredRule message="Введите год последней поверки!" />
                                    <StringLengthRule min={4} max={4} message="Допустимо 4 символа!"/>
                                    <RangeRule min={1980} max={2099} message="Некорректно заполнен год ПП!"/>
                                </Item>
                                <Item dataField="mpi" editorType="dxNumberBox" editorOptions={puOptions}>
                                    <Label text={"Межповерочный интервал"}/>
                                    <RequiredRule message="Введите межповерочный интервал!" />
                                    <RangeRule min={1} max={100} message="Некорректное значение МПИ!"/>
                                </Item>
                            </GroupItem>
                            <Item dataField="balance_name" editorType="dxTextBox" editorOptions={puOptions}>
                                <Label text={"Балансовая принадлежность"}/>
                                <RequiredRule message="Введите балансовую принадлежность!" />
                            </Item>
                            <Item dataField="expl_name" editorType="dxTextBox" editorOptions={puOptions}>
                                <Label text={"Эксплуатационная ответственность"}/>
                                <RequiredRule message="Введите эксплуатационную ответственность!" />
                            </Item>
                            <GroupItem colCount={2}>
                                <Item dataField="dat_start_w" editorType="dxDateBox" editorOptions={{type: 'datetime', useMaskBehavior: true}}>
                                    <Label text={"Дата начала работ"}/>
                                    <RequiredRule message="Введите дату начала работ!" />
                                </Item>
                                <Item dataField="dat_end_w" editorType="dxDateBox" editorOptions={{type: 'datetime', useMaskBehavior: true}}>
                                    <Label text={"Дата окончания работ"}/>
                                    <RequiredRule message="Введите дату окончания работ!" />
                                </Item>
                            </GroupItem>
                        </GroupItem>
                        }
                        <Item dataField="message" editorType="dxTextArea" editorOptions={messageOptions}>
                            <Label text="Текст запроса"/>
                            <RequiredRule message="Введите текст сообщения!" />
                        </Item>
                        <GroupItem caption={selectedDog && defaultType === 5 ? "Приложите акт замены ПУ и прочие документы" : ""}>
                            <Item dataField="attachments" editorType="dxFileUploader" editorOptions={fileOptions}>
                                <Label text="Вложения"/>
                            </Item>
                        </GroupItem>
                        <ButtonItem horizontalAlignment="left">
                            <ButtonOptions
                                type={'default'}
                                useSubmitBehavior={true}
                                text="Отправить запрос"
                                icon="email"
                                disabled={loading}
                            >
                            </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </form> 
                <LoadIndicator width={'24px'} height={'24px'} visible={loading}/>
            </ScrollView>
        </Popup>
    )
}

export default RequestPopup
