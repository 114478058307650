import React, { useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import {substringUserName as substr} from './../../utils/string.utils'
import { useReqPopup } from '../../contexts/req-popup'
//import notify from 'devextreme/ui/notify';

const UserPanel = ({ menuMode }) => {
  const { user, signOut, syncUserData, loadSyncUser, dogovors } = useAuth()
  const { openReqPopup } = useReqPopup()
  const history = useHistory()


  const navigateToProfile = useCallback(() => {
    history.push("/profile")
  },[history])

  const navigateToLetters = useCallback(() => {
    history.push("/letters")
  },[history])

  const openRequestPopup = useCallback(() => {
    if (dogovors.length > 0) openReqPopup(true, 1,null,[],[],false)
    else openReqPopup(true, 3)
  },[openReqPopup,dogovors])

  const refreshData = useCallback(async () => {
    await syncUserData()
  },[syncUserData])

  const menuItems = useMemo(() => ([
    {
      text: 'Профиль',
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: 'Новая заявка',
      icon: 'edit',
      onClick: openRequestPopup,
      visible: true//dogovors.length > 0
    },
    {
      text: 'Обращения',
      icon: 'email',
      onClick: navigateToLetters
    },
    {
      text: 'Обновить данные в ЛКК',
      icon: 'refresh',
      onClick: refreshData,
      disabled: loadSyncUser
    },
    {
      text: 'Выйти',
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut,navigateToProfile,navigateToLetters,openRequestPopup,refreshData,loadSyncUser]);

  return (
    <div>
      <div className={'user-panel'}>
        <div className={'user-info'}>
          <div className={'image-container'}>
            <div
              style={{
                //background: `url(${user.avatarUrl}) no-repeat #fff`,
                backgroundSize: 'cover'
              }}
              className={'user-image'}>
                {substr(user)}
              </div>
              
          </div>
          <div className={'user-name'}>{user.firstName + ' '+ user.lastName}</div>
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target={'.user-button'}
            showEvent={'dxclick'}
            //width={210}
            cssClass={'user-menu'}
          >
            <Position my={'top center'} at={'bottom center'} />
          </ContextMenu>
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menuItems} />
        )}
      </div>
    </div>
  );
}

export default UserPanel
