export function substringUserName(user) {
    if (user && user.lastName && user.firstName)
        return user.firstName.substring(0,1) + user.lastName.substring(0,1)
    else
        return ''
}

export function formatTimestamp(d) {
    return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
}