import request from './api'

export async function getReads(pointsMas, periodStart, periodEnd, typeReads) {
    try {
        const data = await request('/api/point/reads','POST',{
            points: pointsMas,
            start: periodStart,
            end: periodEnd,
            type: typeReads
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getPoint(kodPoint) {
    try {
        const data = await request('/api/point/point','POST',{
            point: kodPoint
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getPointPus(kodPoint) {
    try {
        const data = await request('/api/point/pus','POST',{
            point: kodPoint
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getRashod(pointsMas, periodStart, periodEnd) {
    try {
        const data = await request('/api/point/rashod','POST',{
            points: pointsMas,
            start: periodStart,
            end: periodEnd
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getOutVals(pointMas, period1, period2, type, dogovor) {
    try {
        const data = await request('/api/point/outvals','POST',{
            points: pointMas,
            date_start: period1,
            date_end: period2,
            type: type,
            dogovor: dogovor
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendIntegralAkt(formData){
    try {
        const data = await request('/api/point/integral-akt', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendIntegralReads(period, reads){
    try {
        const data = await request('/api/point/reads', 'PUT', {period: period, reads: reads}
        )
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendIntervalAkt(formData){
    try {
        const data = await request('/api/point/interval-akt', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getCountNotReads(dogovor){
    try {
        const data = await request('/api/point/not-reads', 'POST', {
            dogovor: dogovor
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getEnergosferaCounter(kodPoint, period1,period2) {
    try {
      const res = await request('/api/point/energo-counter','POST', {point: kodPoint, period1: period1, period2: period2})
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getFileEnergosferaCounter(data, point) {
    try {
      const res = await request('/api/point/energo-counter-file','POST', {point: point, data: data})
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}