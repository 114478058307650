import request from './api'
import StorageService from '../services/storage.service'
const FingerprintJS = require('@fingerprintjs/fingerprintjs')

export async function generateFingerpint() {
  const fpPromise = FingerprintJS.load()
  const fp = await fpPromise
  const result = await fp.get()
  return result.visitorId
}

export async function signIn(email, password, fingerprint) {
  try {
    const res = await request('/api/account/login', 'POST', {
      login: email,
      password: password,
      fingerprint: fingerprint
    })

    if (res && res.status) {
      return {
        isOk: false,
        message: res.message,
        status: res.status
      }
    }
    
    if (res && res.access) {
      return {
        isOk: true,
        data: res
      }
    } 
    else return {
      isOk: false,
      message: res.message
    }
  }
  catch(e) {
    return {
      isOk: false,
      message: e.message
    }
  }
}

export async function logout() {
  try {
    const localAuth = StorageService.getLocalAuth()
    if (localAuth) {
      const refresh = localAuth.refresh
      await request('/api/account/logout','POST',{
        refresh: refresh,
        fingerprint: localAuth.fingerprint
      })
    }
  } catch (e) {

  }
  StorageService.removeLocalAuth()
}

export async function getUser() {
  try {
    // Send request
    const res = await request('/api/account/user','GET',null)

    if (res && res.id) {
        return {
        isOk: true,
        data: res
      };
    } else return {
      isOk: false
    }
    
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(region, email, password, phone, last_name, first_name, middle_name, confirmed_password) {
  try {
    // Send request
    const data = await request('/signup', 'POST', 
      {
        region: region,
        email: email,
        phone: phone,
        fio_f: last_name,
        fio_i: first_name,
        fio_o: middle_name,
        pass: password,
        pass_confirm: confirmed_password
      }
    )

    if (data) {
      return {
        isOk: true,
        message: data.message
      };
    }
  }
  catch (e) {
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function confirmAccount(userId, confirmCode) {
  try {
    const result = await request('/signup/check_token', 'POST', 
        {
          user: userId,
          token: confirmCode
        }
    )
    if (result) {
      return {
        isOk: true,
        message: result.message
      }
    } else {
      return {
        isOk: false,
        message: result.message
      }
    }
  } catch (e) {
    return {
      isOk: false,
      message: e.message
    }
  }
}

export async function changePassword(password, recoveryCode) {
 
  //todo если делать мгновенную авторизацию, то продумать подключение отпечатка к общей схеме
  const fingerprint = await generateFingerpint()

  try {
    // Send request
    const result = await request('/api/account/password/restore', 'POST', 
      {
        token: recoveryCode,
        password: password,
        fingerprint: fingerprint
      }
    )

    return {
      isOk: true,
      message: result.message
    };
  }
  catch (e) {
    return {
      isOk: false,
      message: e.message
    }
  }
}

export async function resetPassword(email, company) {
  try {
    var params = '?email=' + email
    if (company) params = params + '&company=' + company
    const result = await request('/api/account/password/restore' + params, 'GET', null)

    if (result)
      return {
        isOk: true,
        message: result.message
      };
  }
  catch (e) {
    console.error(e)
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function checkRecoveryCode(recoveryCode) {
  try {
    const result = await request('/api/account/password/check-recovery', 'POST', {recovery: recoveryCode})
    return {
      isOk: true,
      data: result
    };
  }
  catch (e) {
    console.error(e)
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function updateUser(form) {
  const lastName = form.Фамилия
  const firsName = form.Имя
  const middleName = form.Отчество
  const mobilePhone = form.phone
  const region = form.region

  try {
    const data = await request('/api/account/user','PUT', {
      firstName: firsName,
      lastName: lastName,
      middleName: middleName,
      mobilePhone: mobilePhone,
      region: region
    })
    return {
      isOk: true,
      data: data
    }
  } catch (e) {
    return {
      isOk: false,
      message: e.message
    }
  }
}

export async function updatePassword(currPass, newPass) {
  if (!currPass || !newPass) 
    return {
      isOk: false,
      message: 'Заполните все поля!'
  }
  try {
    const data = await request('/api/account/password','PATCH', {
        currentPassword: currPass,
        newPassword: newPass
    })
    return {
      isOk: true,
      message: data.message
    }
  } catch (e) {
      return {
        isOk: false,
        message: e.message
      }
  }
}

export async function syncUser() {

  try {
    const data = await request('/api/account/sync-user','POST', null)
    return {
      isOk: true,
      message: data.message
    }
  } catch (e) {
    return {
      isOk: false,
      message: e.message
    }
  }
}