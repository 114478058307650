import NewdogFiles from "../../components/newdog-files/newdog-files"
import { Button } from "devextreme-react/button"
import { useState } from "react"
import { useReqPopup } from "../../contexts/req-popup"

const NewdogFilesList = (props) => {
    const [visibleAddFile, setVisibleAddFile] = useState(false)
    //const [fileTypes, setFileTypes] =useState([])
    //const [filesSource, setFilesSource] = useState([])
    const {newdogFiles, setNewdogFiles} = useReqPopup()

    const addFileToNewreq = (type, files) => {
        //console.log(files)
        let source = newdogFiles
        for (let i=0; i<files.length; i++){
            source.push({
                id: source.length + 1000000,
                type_id: type.id,
                type_name: type.nameType,
                file_name: files[i].name,
                file: files[i]
            })
        }
        setNewdogFiles(source)
        //props.setFiles(source)
    }

    const removeFile = (idFile) => {
        setNewdogFiles((source) => source.filter(item => item.id !== idFile))
    }

    return (
        <div>
            <div>
                <span><b>Документы:</b></span>
                <ul style={newdogFiles.length > 0 ? {border: '1px solid lightgray'} : {}}>
                    {
                        newdogFiles.map((item) => {
                            return <li key={item.id}>
                                {item.type_name}: <u>{item.file_name}</u> (<a href="#/add-dogovor" onClick={() => {removeFile(item.id)}}>Удалить</a>)
                            </li>}
                        )
                    }
                </ul>
            </div>
            <Button text="Добавить документы" icon="add" disabled={props.checkedInn} onClick={()=>{setVisibleAddFile(true)}}></Button>
            <NewdogFiles mode="newreq" visible={visibleAddFile} types={props.types} onClose={()=>{setVisibleAddFile(false)}} onAddFiles={addFileToNewreq} />
        </div>
    )
}

export default NewdogFilesList