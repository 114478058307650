import { withNavigationWatcher } from './contexts/navigation';

import { 
  HomePage3, ProfilePage, CardPage, DogovorPage, /*DotsPage,*/ EnergoobjectsPage, 
  FinDocsPage, PaysPage, PokazaniaEditPage, PokazaniaHistoryPage, LettersPage, AgregatorCalculator, 
  AgregatorEvents, AddDogovorPage, HelpPage, AdministrationPage, DebtPage, AgregatorReclamePage
} from './pages';
import EcpPageTest from "./pages/ecp/ecp-page-test";

const routes = [
  {
    path: '/card',
    component: CardPage,
    title: 'Лицевая карта',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/dogovor',
    component: DogovorPage,
    title: 'Информация о договоре',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/add-dogovor',
    component: AddDogovorPage,
    title: 'Заключить договор',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  {
    path: '/energoobjects',
    component: EnergoobjectsPage,
    title: 'Объекты и ТУ',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/docs',
    component: FinDocsPage,
    title: 'Финансовые документы',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/pays',
    component: PaysPage,
    title: 'Оплаты',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/pokazania-new',
    component: PokazaniaEditPage,
    title: 'Подать показания',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/pokazania-history',
    component: PokazaniaHistoryPage,
    title: 'История показаний',
    sd_visible: true,
    all_users_visible: false,
    sd_agr_visible: false
  },
  {
    path: '/profile',
    component: ProfilePage,
    title: 'Профиль',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  // {
  //   path: '/home2',
  //   component: HomePage2,
  //   title: 'Главная',
  //   sd_visible: false,
  //   all_users_visible: true,
  //   sd_agr_visible: false
  // },
  {
    path: '/home',
    component: HomePage3,
    title: 'Главная',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  {
    path: '/letters',
    component: LettersPage,
    title: 'Сообщения',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  {
    path: '/ecp-page-test',
    component: EcpPageTest,
    title: 'Сообщения',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  {
    path: '/agregator-reclame',
    component: AgregatorReclamePage,
    title: 'Управление спросом',
    sd_visible: false,
    all_users_visible: true,
    sd_agr_visible: false
  },
  {
    path: '/agregator-events',
    component: AgregatorEvents,
    title: 'События',
    sd_visible: false,
    all_users_visible: false,
    sd_agr_visible: true
  },
  {
    path: '/agregator-calculator',
    component: AgregatorCalculator,
    title: 'Калькулятор эффективности',
    sd_visible: false,
    all_users_visible: true
  },
  {
    path: '/help',
    component: HelpPage,
    title: 'Техническая поддержка',
    sd_visible: false,
    all_users_visible: true
  },
  {
    path: '/admin',
    component: AdministrationPage,
    title: 'Управление доступом',
    sd_visible: true,
    all_users_visible: false
  },
  {
    path: '/debt',
    component: DebtPage,
    title: 'Задолженность',
    sd_visible: true,
    all_users_visible: false
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
