import React, {useEffect, useState} from 'react'
import Tabs from 'devextreme-react/tabs'
import {tabs} from './data'
import './pokazania-history.scss'
import ReadsGrid from '../../components/reads-grid/reads-grid.js'
import ReadsProfile from '../../components/reads-profile/reads-profile.js'
import AccessDenied from '../../components/access-denied/access-denied'
import {useAuth} from '../../contexts/auth'
import {verifyAccess} from '../../utils/admin.utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";

const PokazaniaHistory = () => {

    const [selectedTab, setSelectedTab] = useState(0)
    const { selectedDog } = useAuth()
    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [2]
        setVisiblePage(verifyAccess(pageRoles, selectedDog))
    }, [selectedDog])

    useEffect(() => {
        const tab = window.location.toString().split('?tab=')[1]
        if (tab && parseInt(tab) && (parseInt(tab) >=0 && parseInt(tab) <=3)) setSelectedTab(parseInt(tab))
    }, [])

    const onTabsSelectionChanged = (args) => {
        if(args.name === 'selectedIndex') {
            setSelectedTab(args.value)
        }
    }

    //вытаскиваем расход
    // const getRashodInfo = async (mas) => {
    //     //setLoading(true)
    //     const points = getPoints(mas)
    //     if (points.length > 0) {
    //         const rashod = await getRashod(points,periodStart,periodEnd)
    //         if (rashod.isOk) setDataRashod(rashod.data)
    //         else notify(rashod.message, 'error', 5000)
    //     } else setDataReads([])

    //     //setLoading(false)
    // }
    
    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faClockRotateLeft} />
            <h3><strong>История показаний</strong></h3>
            <Tabs className="mt-3" dataSource={tabs} defaultSelectedIndex={selectedTab}  selectedIndex={selectedTab} onOptionChanged={onTabsSelectionChanged} />
            { 
                tabs[selectedTab].content === 'RegReads' ? <ReadsGrid type="PRIEM_HISTORY" /> : 
                tabs[selectedTab].content === 'RegReadsLkk' ? <ReadsGrid type="LKK" /> : 
                tabs[selectedTab].content === 'OutVals' ? <ReadsProfile type='PRIEM' /> :
                //tabs[selectedTab].content === 'Potreb' ? <ReadsPotrebGrid dataSource={dataRashod}/> :
                tabs[selectedTab].content === 'OutValsLkk' ? <ReadsProfile type='LKK' /> :
                'Не выбран таб'
            } 
        </div>
        : <AccessDenied/>}</>
    )
    
}

export default PokazaniaHistory
