import { getYm } from '../utils/date.utils'
import request from './api'

export async function getUserDogovors() {
    try {
      const res = await request('/api/dogovor/user-dogovors','POST', null)
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorPoints(kodDog, typePoints) {
    try {
        const res = await request('/api/dogovor/dogovor-points','POST',{kod_dog: kodDog, type: typePoints})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getPointsTree(kodDog, typePoints) {
    try {
        const res = await request('/api/dogovor/points-tree','POST',{kod_dog: kodDog, type: typePoints})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}
export async function getPointsTreePlain(kodDog, typePoints) {
    try {
        const res = await request('/api/dogovor/points-tree-plain','POST',{kod_dog: kodDog, type: typePoints})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getPointsList(kodDog) {
    try {
        const res = await request('/api/dogovor/points-list','POST',{dogovor: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorSf(kodDog, periodStart, periodEnd) {
    try {
        const ymStart = getYm(periodStart)
        const ymEnd = getYm(periodEnd)
        const res = await request('/api/dogovor/dogovor-sf','POST',{kod_dog: kodDog, period_start: ymStart, period_end: ymEnd})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorOpls(kodDog, dateStart, dateEnd) {
    try {
        const res = await request('/api/dogovor/dogovor-pays','POST',{kod_dog: kodDog, date_start: dateStart, date_end: dateEnd})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDogovorCard(kodDog, start, end) {
    try {
        const res = await request('/api/dogovor/dogovor-card','POST',{kod_dog: kodDog, start: start, end: end})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getCurrentPeriod(kodDog) {
    try {
        const res = await request('/api/dogovor/current-period','POST',{kod_dog: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getCountVistSf(kodDog) {
    try {
        const res = await request('/api/dogovor/count-vist-sf','POST',{kod_dog: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDolgSf(kodDog) {
    try {
        const res = await request('/api/dogovor/dolg-sf','POST',{dogovor: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDolgSum(kodDog) {
    try {
        const res = await request('/api/dogovor/dolg-sum','POST',{dogovor: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getManager(kodDog) {
    try {
        const res = await request('/api/dogovor/manager','POST',{dogovor: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDocs(kodDog) {
    try {
        const res = await request('/api/dogovor/docs','POST',{dogovor: kodDog})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getDoc(doc) {
    try {
        const res = await request('/api/dogovor/load-doc','POST',{doc: doc})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message ? e.message : 'Ошибка при загрузке документов!!!'
        }
    }
}

export async function getEnergoobject(kodObj) {
    try {
        const data = await request('/api/dogovor/energoobject','POST',{
            id: kodObj
        })
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}