import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';
import { useAuth } from '../../contexts/auth';

const SideNavigationMenu = (props) => {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { isLarge } = useScreenSize();
  const { countDogovors, dogovors} = useAuth()

  function normalizePath () {
    //отфильтруем по правам
    var filterNavigation = navigation
    if (countDogovors === 0 && dogovors.length === 0) {
      //фильтруем по 1 уровню меню
      filterNavigation = filterNavigation.filter(item => item.all_users_visible === true)
      //фильтруем по 2 уровню меню
      for (let i=0; i<filterNavigation.length; i++){
        if (filterNavigation[i].items) {
          const visbleItems = filterNavigation[i].items.filter(page => page.all_users_visible === true)
          filterNavigation[i].items = visbleItems
        }
      }
    }

    return filterNavigation.map((item) => {
      if(item.path && !(/^\//.test(item.path))){ 
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLarge}; 
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }
    // если страница отсутствует в меню (app-navigation) переход из тулбара или прямая ссылка
    // очищаем селект в меню.
    let nav = navigation;
    //if (['/letters','/profile'].includes(currentPath)) treeView.unselectAll();
    let unselect = true;
    function unselectAll(items) {
      for (let subitems of items) {
        if (subitems.items) unselectAll(subitems.items) // рекурсивно вызывается для subitems
        if (subitems.path===currentPath) {unselect = false; return;}
      }
    }
    unselectAll(nav)
    unselect && treeView.unselectAll()

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}

export default SideNavigationMenu
