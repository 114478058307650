import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/auth'
import {substringUserName as substr} from './../../utils/string.utils'
import './profile.scss';
import Form, { ButtonItem, ButtonOptions, CustomRule, Item, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form'
import { updateUser, updatePassword } from '../../api/auth'
import notify from 'devextreme/ui/notify'
import { passwordRulesLabel } from '../../components/label-data'
import { getRegions } from '../../api/outer';

const Profile = () => {
  const { user, setUser } = useAuth();
  // const [notes, setNotes] = useState(
  //   'Здесь нужно решить, оставляем этот блок или нет.\r\n\r\n В теории какая-то информация пользователя или просто крупным шрифтом вывести ФИО.'
  // )

  const strMinRuleMessage = 'Недопустимое кол-во символов!'

  const formDataFIO = useRef({
    Фамилия: user.lastName,
    Имя: user.firstName,
    Отчество: user.middleName,
    phone: user.mobilePhone
  })

  const formDataPass = useRef({
    Текущий_пароль: null,
    Новый_пароль: null,
    Подтверждение_пароля: null
  })

  const [disabledFIO, setDisabledFIO] = useState(true)
  const [disabledPass, setDisabledPass] = useState(true)
  const [regions, setRegions] = useState([])
  const [userRegion, setUserRegion] = useState(user.region)

  useEffect(() => {
    (async function(){
      const resRegions = await getRegions()
      if (resRegions.isOk) {
        setRegions(resRegions.data)
      }
    })()
  },[user.region])

  const editFio = async (e) => {
    e.preventDefault()
    //console.log(disabledFIO)
    setDisabledFIO(!disabledFIO)
    //console.log(disabledFIO)
    if (!disabledFIO) {
      const res = await updateUser(formDataFIO.current)

      if (res.isOk) {
        setUser(res.data)
        notify('Профиль успешно изменен!', 'success', 5000)
      } else notify(res.message, 'error', 5000)
    }
  }

  const editPassword = async (e) => {
    e.preventDefault()
    setDisabledPass(!disabledPass)
    if (!disabledPass) {

      const res = await updatePassword(formDataPass.current.Текущий_пароль, formDataPass.current.Новый_пароль)
      if (res.isOk) {
        notify(res.message, 'success', 5000)
        formDataPass.current = {
          Текущий_пароль: null,
          Новый_пароль: null,
          Подтверждение_пароля: null
        }
        e.target.reset()
      } else {
        setDisabledPass(false)
        notify(res.message, 'error', 5000)
        
      }
    }
  }

  const confirmPassword = useCallback(
    ({ value }) => {
      const newPass = formDataPass.current.Новый_пароль
      if (newPass === null) return true
      return value === newPass
    },
    []
  );

  const checkPassword = useCallback(
    ({ value }) => (formDataPass.current.Подтверждение_пароля ? value === formDataPass.current.Подтверждение_пароля : true),
    []
  );

  const fioEditor = {
    disabled: disabledFIO
  }

  const [passwordMode, setPasswordMode] = useState('password')
  const passwordEditor = {
    disabled: disabledPass,
    mode: passwordMode,
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  }
  const [passwordConfirmMode, setPasswordConfirmMode] = useState('password')
  const passwordConfirmEditor = {
    disabled: disabledPass,
    mode: passwordConfirmMode,
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordConfirmMode(passwordConfirmMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  }
  const [passwordCurrentMode, setPasswordCurrentMode] = useState('password')
  const passwordCurrentEditor = {
    disabled: disabledPass,
    mode: passwordCurrentMode,
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordCurrentMode(passwordCurrentMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  }

  const regionOptions = {
    disabled: disabledFIO,
    dataSource: regions,
    valueExpr: "idRegion",
    displayExpr: "nameRegion",
    value: userRegion,
    onValueChanged: function(e){
      setUserRegion(e.value)
    },
    searchEnabled: true
  }

  const phoneOptions = {
    disabled: disabledFIO,
    mask: '+7 (YX0) 000-0000', 
    maskRules: { X: /[01-9]/, Y: /[9]/ }
  }

  return (
    <React.Fragment>
      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar main-background-color'}>
          {/* <img
            alt={''}
            src={`https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${
              employee.Picture
            }`}
          /> */}
          {substr(user)}
        </div>
        <h5>{user.lastName + ' ' + user.firstName + ' ' + (user.middleName ? user.middleName : '')}</h5>
        {user.email && <h6>{user.email}</h6>}
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        Редактировать персональные данные:
        <form onSubmit={editFio}>
          <Form
            id={'form-fio'}
            formData={formDataFIO.current}
            onFieldDataChanged={e => true}
            labelLocation={'top'} labelMode={'floating'}
            colCountByScreen={colCountByScreen}
            //disabled={disabledFIO}
          >
            <Item dataField="Фамилия" editorType={'dxTextBox'} editorOptions={fioEditor}>
              <RequiredRule message="Фамилия задана неверно" />
              <StringLengthRule min={2} message={strMinRuleMessage}/>
            </Item>
            <Item dataField="Имя" editorType={'dxTextBox'} editorOptions={fioEditor}>
              <RequiredRule message="Имя задано неверно" />
              <StringLengthRule min={2} message={strMinRuleMessage}/>
            </Item>
            <Item dataField="Отчество" editorType={'dxTextBox'} editorOptions={fioEditor}></Item>
            <Item dataField="phone" editorType="dxTextBox" editorOptions={phoneOptions}>
              <Label text="Телефон" />
              <RequiredRule message="Введите номер телефона!" />
            </Item>
            <Item dataField="region" editorType="dxSelectBox" editorOptions={regionOptions}>
              <Label text="Регион"/>
              <RequiredRule message="Выберите регион!" />
            </Item>
            <ButtonItem>
              <ButtonOptions 
                icon={disabledFIO ? "edit" : "save"} 
                text={disabledFIO ? "Редактировать" : "Сохранить"}
                useSubmitBehavior={true} 
              ></ButtonOptions>
            </ButtonItem>
          </Form>
        </form>
      </div>
      <div className={'content-block dx-card responsive-paddings'}>
        Редактировать пароль:
        <form onSubmit={editPassword}>
          <Form
            id={'form-pass'}
            formData={formDataPass.current}
            onFieldDataChanged={e => true}//e.dataField === 'Notes' && setNotes(e.value)
            labelLocation={'top'}  labelMode={'floating'}
            colCountByScreen={colCountByScreen}
          >
            <Item dataField="Текущий_пароль" editorType={'dxTextBox'} editorOptions={passwordCurrentEditor} />
            <Item dataField="Новый_пароль" editorType={'dxTextBox'} editorOptions={passwordEditor}>
              <CustomRule
                message={'Пароли не совпадают'}
                validationCallback={checkPassword}
              />
            </Item>
            <Item dataField="Подтверждение_пароля" editorType={'dxTextBox'} editorOptions={passwordConfirmEditor} >
              <CustomRule
                message={'Пароли не совпадают'}
                validationCallback={confirmPassword}
              />
            </Item>
            <ButtonItem>
              <ButtonOptions 
                icon={disabledPass ? "edit" : "save"} 
                text={disabledPass ? "Редактировать" : "Сохранить"} 
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <Item>
              <div className="text">{passwordRulesLabel}</div>
            </Item>
          </Form>
        </form>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};

export default Profile
