import Chart, {CommonSeriesSettings, Export, Legend, LoadingIndicator, Point, Series, Size, Title, Tooltip, ValueAxis} from 'devextreme-react/chart'
import notify from 'devextreme/ui/notify'
import { useEffect, useState } from 'react'
import { getChartDay } from '../../api/agrdog'
import LoadPanel from 'devextreme-react/load-panel'

const DayChart = (props) => {

    const day = props.day
    const selectedAgrdog = props.dogovor
    const visible = props.visible

    const [chartData, setChartData] = useState([])
    const [chartSource, setChartSource] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!visible) {
            setChartData([])
            setChartSource([])
            return
        }
        (async function(){
            setLoading(true)
            const res = await getChartDay(day, selectedAgrdog.kodDog, selectedAgrdog.objId)
            if (res.isOk) {
                setChartData(res.data)
                setChartSource(getSource(res.data))
            } else {
                notify(res.message,'error',10000)
            }
            setLoading(false)
        })()
    }, [selectedAgrdog, visible, day])
 
    function getSource(mas) {
        var res = []
        var item = {}
        for (let i=0; i<mas.length; i++) {
            item = {
                value: mas[i].name,
                name: mas[i].name
            }
            if (!res.find(el => el.value === mas[i].name)) res.push(item)
        }
        return res.reverse()
    }

    function setSeriesColor(name) {
        switch (name) {
            case 'Объем снижения': return '#96c95b'
            case 'Профиль снижения': return '#f5564a'
            case 'Профиль потребления': return '#1cb1f5'
            default: return 'gray'
        }
    }

    return (
        <>
            {visible && 
            <div id="chart-demo">
                <LoadPanel visible={loading} />
                <div style={{textAlign: 'center'}}>
                    <span><b>{(new Date(day)).toLocaleDateString('ru')  + ' (GMT+3)'}</b></span>
                </div>
                <Chart
                    dataSource={chartData}
                    //customizePoint={customizePoint}
                >
                    <Size height={420} />
                    <CommonSeriesSettings
                            argumentField="format_time"
                            //type="spline"
                            hoverMode="includePoints"
                        >
                        <Point visible={false} hoverMode="allArgumentPoints" />
                    </CommonSeriesSettings>
                    <ValueAxis position="left">
                        <Title text="КВт/ч" />
                    </ValueAxis>
                    {
                        chartSource.map((item) => {
                            return <Series
                                color={setSeriesColor(item.name)}
                                key={item.value}
                                valueField={item.value}
                                name={item.name}
                                type={item.name === 'Объем снижения' ? 'spline' : 'stackedbar'}
                            />
                        })
                    }
                    <Export enabled={true} />
                    <LoadingIndicator enabled={true} />
                    <Tooltip
                        enabled={true}
                        shared={true}
                        customizeTooltip={customizeTooltip}     
                    />
                    <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        hoverMode="excludePoints" 
                    />
                </Chart>
            </div>
            }
        </>
    )

    function customizeTooltip(outInfo) {
        return {
          html: `
            <div style="z-index: 10000; position: relative">
                <div class="tooltip-body">
                    <div class="series-name">
                        <span class='top-series-name'>Час: ${outInfo.argumentText}</span>
                    </div>
                    <div class="value-text">
                        <span class='top-series-value'>КВт/ч: ${outInfo.originalValue}</span>
                    </div>
                </div>
            </div>`,
        };
    }
}

export default DayChart
