import Button from 'devextreme-react/button'
import DataGrid, {Column, Export, Grouping, Pager, Paging} from 'devextreme-react/data-grid'
import LoadPanel from 'devextreme-react/load-panel'
import notify from 'devextreme/ui/notify'
import {useEffect, useState} from 'react'
import {getPointsTreePlain} from '../../api/dogovor'
import {getReads} from '../../api/point'
import {useAuth} from '../../contexts/auth'
import {usePeriod} from '../../contexts/period'
import {getPoints} from '../../services/point.service'
import PointsTreeHook from '../points-tree/point-tree-hook'
//import {PointsTree} from '../points-tree/points-tree'
import SelectPeriod from '../select-period/select-period'

const ReadsGrid = (props) => {

    const pageSizes = [10, 25, 50, 100]
    var filename = 'История показаний'
    const [loading, setLoading] = useState(false)
    const [dataReads, setDataReads] = useState([])
    const [isPointsSelected, setIsPointsSelected] = useState(false)
    const [selectedObjects, setSelectedObjects] = useState([])
    const [btnClicked, setBtnClicked] = useState(false)
    const { periodStart, periodEnd } = usePeriod()
    const [points, setPoints] = useState(null)
    const { selectedDog } = useAuth()
    
    const typeReads = props.type

    const pointsType = 'all'//'integral'

    useEffect(() => {
        (async function () {
            setPoints(null)
            //получаем точки по договору
            const res = await getPointsTreePlain(selectedDog.kodDog, pointsType)
            if (res.isOk) {
                setPoints(res.data)
            } else {
                notify(res.message, 'error', 5000)
            }
         })()
    }, [selectedDog])

    useEffect(() => {
        setDataReads([])
        //setIsPointsSelected(false)
    }, [typeReads, selectedDog])

    const onTreeSelect = async (mas) => {
        const points = getPoints(mas)
        setIsPointsSelected(points.length > 0)
        setSelectedObjects(mas)
        if (btnClicked) setBtnClicked(false)
    }

    const getReadsInfo = async () => {
        setBtnClicked(true)
        setLoading(true)
        
        const points = getPoints(selectedObjects)
        if (points.length > 0) {
            const reads = await getReads(points,periodStart,periodEnd,typeReads)
            if (reads.isOk) setDataReads(reads.data)
            else notify(reads.message, 'error', 5000)
        } else setDataReads([])

        setLoading(false)
    }

    return (
        <div className=''>
            <div className="row mt-3">
                <div className="col-md-12">
                    {/* <PointsTree selectionMode="multiple" onSelect={onTreeSelect} typePoints={pointsType} points={points}/> */}
                    <PointsTreeHook selectionMode="multiple" onSelect={onTreeSelect} typePoints={pointsType} points={points}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-12">
                    <SelectPeriod mode={'multiple'}/>
                    <Button className="mt-3" disabled={!isPointsSelected} text="Показать" icon="refresh" onClick={getReadsInfo} />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-12">

                {
                    isPointsSelected && btnClicked ? 
                    <div>
                        <DataGrid
                            dataSource={dataReads}
                            keyExpr="id_row"
                            columnHidingEnabled={true}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            noDataText="Для выбранных параметров нет данных"
                            wordWrapEnabled={true}
                            //onContentReady={this.onContentReady}
                            allowColumnResizing={true}
                        >
                        {/* <GroupPanel visible={true} /> */}
                        {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
                            <Grouping autoExpandAll={false} />
                            <Column dataField="period" caption="Период" format="yyyy.MM" dataType="date" width={120}/>
                            <Column dataField="dat_priem" caption="День показаний" dataType="date" width={120}/>
                            <Column dataField="num_pu" caption="№ прибора" dataType="string" width={120} />
                            <Column dataField="name" caption="Название ТУ" dataType="string" width={150} />
                            <Column dataField="nomer" caption="№ ТУ" dataType="string" width={100} />
                            <Column dataField="readlast" caption="Показание" dataType="string" width={120} />
                            {typeReads === 'PRIEM_HISTORY' && <Column dataField="out" caption="Расход" dataType="string" width={100}/>}
                            <Column dataField="outadd" caption="Доп. расход" dataType="string"/>
                            {typeReads === 'PRIEM_HISTORY' && <Column dataField="pot_fix" caption="Потери (КВт)" dataType="number" />}
                            {typeReads === 'PRIEM_HISTORY' && <Column dataField="pot_prc" caption="Потери (%)" dataType="number" />}

                            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                            <Paging defaultPageSize={10} />
                            <Export fileName={filename} enabled={true}  />
                        </DataGrid>
                        <LoadPanel visible={loading} /> 
                    </div>
                : 'Выберите точки учета и нажмите <Показать>..'}
                </div>
            </div>

        </div>
    )
}

export default ReadsGrid
