import Popup from "devextreme-react/popup"
import Form, {
    Item,
    ButtonItem,
    ButtonOptions
  } from 'devextreme-react/form'
import { useRef, useState } from "react"
import notify from "devextreme/ui/notify"
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ScrollView from "devextreme-react/scroll-view";

const PopupIntegral = (props) => {

    const title = props.title
    const visible = props.visible
    const multiple = props.multiple

    const formData = useRef({})
    const [value, setValue] = useState([])

    const onHiding = () => {
        setValue([])
        props.onHiding()
    }

    const sendFile = (e) => {
        e.preventDefault()
        const { Файл } = formData.current;
        if (Файл.length === 0) notify('Файл не выбран!','error',5000)
        else props.onSendFile(Файл)

    }

    const fileOptions = {
        selectButtonText: "Выберите файл", 
        labelText: "Или перетащите файл сюда",
        accept: ".xlsx",
        multiple: multiple,
        maxFileSize: 10000000,
        uploadMode: "useForm",
        value: value,
        onValueChanged: function(e){
            setValue(e.value)
        }
    }

    return (
        <Popup
            id="reads-popup"
            title={title}
            showCloseButton={true}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={true}
            height='auto'
            maxHeight={'90%'} //форма с прибором не входит никак иначе
            maxWidth="600px"
            titleRender={()=>PopupTitleWithIcon(faFileExcel, title,onHiding)}
        >
            <ScrollView className="big-input" width='100%' height='100%'>
            <form onSubmit={sendFile}>
                    <Form
                        formData={formData.current}
                        id="request-form"
                        colCount={1}
                    >
                        <Item dataField="Файл" label={{visible:false}} editorType="dxFileUploader" editorOptions={fileOptions}></Item>
                        <ButtonItem horizontalAlignment="left">
                            <ButtonOptions
                                type={'default'}
                                useSubmitBehavior={true}
                                text="Загрузить акт"
                                icon="email"
                                disabled={value.length === 0}
                            >
                            </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </form>
            </ScrollView>
        </Popup>
    )
}

export default PopupIntegral
