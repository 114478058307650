import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CompareRule,
} from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import { changePassword, checkRecoveryCode } from '../../api/auth'
import './change-password-form.scss'
import { passwordRulesLabel } from '../label-data'
import Validator from "devextreme/ui/validator";
let formInstance
const ChangePasswordForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const { recoveryCode } = useParams()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current
    setLoading(true)

    const result = await changePassword(password, recoveryCode)
    setLoading(false)

    if (result.isOk) {
      history.push('/login');
      notify(result.message, 'success',5000)
    } else {
      notify(result.message, 'error', 5000)
    }
  }, [history, recoveryCode])

  useEffect(()=> {
    (async function(){
      const res = await checkRecoveryCode(recoveryCode)
      if (res.isOk && !res.data.success) {
        notify(res.data.message, 'error', 5000)
        history.push('/login')
      }
    })()
  },[history,recoveryCode])

  // const confirmPassword = useCallback(
  //   ({ value }) => value === formData.current.password,
  //   []
  // );

  const confirmPassword = () =>{
    return formData.current.password
  };

  const [passwordMode, setPasswordMode] = useState('password')
  const passwordEditorOptions = {
    placeholder: 'Пароль', 
    mode: passwordMode,
    onValueChanged: () => {
      const editor = formInstance && formInstance.getEditor('ConfirmPassword');
      if (editor && editor.option('value')) {
        const instance = Validator.getInstance(editor.element());
        instance.validate();
      }
    },
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  };
  const [passwordConfirmMode, setPasswordConfirmMode] = useState('password')
  const confirmedPasswordEditorOptions = {
    placeholder: 'Подтверждение пароля', 
    mode: passwordConfirmMode,
    buttons: [{
      name: 'password',
      location: 'after',
      options: {
        icon: 'key',
        type: 'default',
        onClick() {
          setPasswordConfirmMode(passwordConfirmMode === 'text' ? 'password' : 'text');
        },
      },
    }]
  };

  // const checkPassword = useCallback(
  //   ({ value }) => (formData.current.confirmedPassword ? value === formData.current.confirmedPassword : true),
  //   []
  // );
  const onInitialized = (e) => {
    formInstance = e.component;
  }
  return (
    <form className="change-password-form" onSubmit={onSubmit}>
      <Form formData={formData.current} onInitialized={onInitialized} disabled={loading} labelMode="floating" labelLocation="left">
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Пароль задан неверно" />
          <Label text="Пароль" />
          {/*<CustomRule*/}
          {/*  message={'Пароли не совпадают'}*/}
          {/*  validationCallback={checkPassword}*/}
          {/*/>*/}
        </Item>
        <Item
            name="ConfirmPassword"
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Пароль задан неверно" />
          {/*<CustomRule*/}
          {/*  message={'Пароли не совпадают'}*/}
          {/*  validationCallback={confirmPassword}*/}
          {/*/>*/}
          <CompareRule
              message="Пароль и подтверждение пароля не совпадают"
              comparisonTarget={confirmPassword}
          />
          <Label text="Подтверждение пароля" />
        </Item>
        <Item>
          <div className="text">{passwordRulesLabel}</div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Сменить пароль'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

export default ChangePasswordForm
