import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { confirmAccount } from '../../api/auth'
import Form, { Item, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import './confirm-account-form.scss'

const ConfirmAccountForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null)
  const { userId, confirmCode } = useParams();

  useEffect(() => {
    (async function () {
      setLoading(true)

      const result = await confirmAccount(userId, confirmCode)

      if (result.isOk) {
        setConfirm(true)
        notify(result.message, 'success', 5000);
      } else {
        setConfirm(false)
        notify(result.message, 'error', 5000);
      }

      setLoading(false)
    })()
  },[userId, confirmCode])

  const onBtnClick = () => { history.push('/login') }

  return (
    <form className="confirm-account-form">
      <Form>
        <Item>
          <div className='policy-info'>
          {
            loading
              ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
              : confirm === true ? 'Аккаунт успешно подтвержден!' : 'Ссылка не действительна!'
          }
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            onClick={onBtnClick}
            text="Войти" 
            disabled={loading}
          >
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

export default ConfirmAccountForm