export const tabs = [
  {
    text: 'Показания (расчитанные)',
    content: 'RegReads'
  },
  {
    text: 'Показания (переданные)',
    content: 'RegReadsLkk'
  },
  {
    text: 'Профиль (расчитанный)',
    content: 'OutVals'
  },
  {
    text: 'Профиль (переданный)',
    content: 'OutValsLkk'
  }
]
  
  