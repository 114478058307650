import React, {useState} from "react";
import {Popup} from "devextreme-react/popup";
//import {Button} from "devextreme-react/button";
//import {FileSignatureCriptoPro} from "./FileSignatureCriptoPro.stories";
import ScrollView from "devextreme-react/scroll-view";
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import { getDogovorToSigned } from "../../api/newdog";
import PopMainCert from "./PopMainCert";

const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

const PopSelectCert = (props) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [filesForSignature, setFilesForSignature] = useState(null)
    const [companyKey, setCompanyKey] = useState(null)
    const [filePdfName, setFilePdfName] = useState(null)
    const showPop = async e => {
        setPopupVisible(true)
        const fileToSigned = await getDogovorToSigned(props.idFile)
        if (fileToSigned.isOk){
            let blob = new Blob([new Uint8Array(fileToSigned.data.dogovor.attachment.data)], {type: "application/pdf"});
            let file = new File([blob], fileToSigned.data.dogovor.filename, {type: "application/pdf"});
            if (fileToSigned.data.dogovor.filename.includes('.pdf')){
                setFilePdfName(fileToSigned.data.dogovor.filename.replaceAll(/\.pdf/gi,'_signed.pdf'))
            }
            if (props.companyKeyName){
                let t = new Blob([new Uint8Array(fileToSigned.data.dogovor.company_key.data)]);
                let companySig = await toBase64(t)
                setCompanyKey(companySig.replaceAll(/(.*?;base64,)/gi,''))
            }
            setFilesForSignature([file]);
        }

    }

    const hidePop = e => {
        setPopupVisible(false)
    }
    return (
        <>
            <span onClick={showPop}>(Подписать)</span>
            <Popup
                visible={popupVisible}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Подпись файла(-ов)"
                maxWidth={600}
                maxHeight={'90%'}
                height={'auto'}
                titleRender={()=>PopupTitleWithIcon(faKey, "Подпись файла договора",hidePop)}
            >
                <ScrollView width='100%' height='100%'>
                {/* <FileSignatureCriptoPro files={filesForSignature} idFile={props.idFile} onSuccessSign={props.onSuccessSign}/> */}
                <PopMainCert files={filesForSignature} companyKey={companyKey} filePdfName={filePdfName} idFile={props.idFile} onSuccessSign={props.onSuccessSign}/>
                </ScrollView>
            </Popup>
        </>)

};

export default PopSelectCert;
