// import Chart, {
//     Legend,
//     Series,
//     Size,
//     Export,
//     LoadingIndicator,
//     Tooltip
//   } from 'devextreme-react/chart'
import Button from 'devextreme-react/button';
import {Chart, CommonSeriesSettings, Export, Legend, Point, Series, Tooltip,} from 'devextreme-react/chart'
import LoadPanel from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import {useEffect, useState} from 'react';
import {getCurrentPeriod, getPointsTreePlain} from '../../api/dogovor';
import {getOutVals} from '../../api/point';
import {useAuth} from '../../contexts/auth';
//import {usePeriod} from '../../contexts/period';
import {getPoints} from '../../services/point.service';
import PointsTreeHook from '../points-tree/point-tree-hook';
//import {PointsTree} from '../points-tree/points-tree';
//import SelectPeriod from '../select-period/select-period';
import DateBox from "devextreme-react/date-box"
import { getFirstDayOfMounth, getLastDayOfMounth } from '../../utils/date.utils';

const ReadsProfile = (props) => {

    const typeReads = props.type
    const pointsType = 'all'//'interval'

    const [points, setPoints] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isPointsSelected, setIsPointsSelected] = useState(false)
    const [selectedObjects, setSelectedObjects] = useState([])
    const [btnClicked, setBtnClicked] = useState(false)
    const [pointsSource, setPointsSource] = useState([])
    const [pointsData, setPointsData] = useState([])
    const [period1, setPeriod1] = useState(new Date())
    const [period2, setPeriod2] = useState(new Date())
    const [loadingPeriod, setLoadingPeriod] = useState(false)

    const FACT_CHART_NAME = 'Максимальные часы фактической пиковой нагрузки'
    const PLAN_CHART_NAME = 'Плановые часы пиковой нагрузки'

    //const { periodEnd } = usePeriod()
    const { selectedDog } = useAuth()

    useEffect(() => {
        (async function () {
            setBtnClicked(false)
            setPointsData([])
            setPointsSource([])
            setPoints(null)
            setIsPointsSelected(false)
            //получаем точки по договору
            const res = await getPointsTreePlain(selectedDog.kodDog, pointsType)
            if (res.isOk) {
                setPoints(res.data)
            } else {
                notify(res.message, 'error', 5000)
            }
            setLoadingPeriod(true)
            const periodRes = await getCurrentPeriod(selectedDog.kodDog)
            if (periodRes.isOk) {
                setPeriod1(getFirstDayOfMounth(new Date(periodRes.data.period)))
                setPeriod2(getLastDayOfMounth(new Date(periodRes.data.period),false))
            }
            setLoadingPeriod(false)
         })()
    }, [selectedDog])

    useEffect(() => {
        setPointsData([])
    },[props.type])

    const onTreeSelect = async (mas) => {
        const points = getPoints(mas)
        setIsPointsSelected(points.length > 0)
        setSelectedObjects(mas)
        if (btnClicked) setBtnClicked(false)
        //заполним данные для графика
    }

    //вытаскиваем профиль
    const getOutValsInfo = async () => {
        setLoading(true)
        setBtnClicked(true)
        setPointsData([])
        setPointsSource([])

        const points = getPoints(selectedObjects)
        //console.log(periodEnd)
        const vals = await getOutVals(points,period1,period2,typeReads, selectedDog.kodDog)
        if (vals.isOk) {
            let source = getPointsSource(vals.data)
            let data = vals.data
            let powerMas
            if (source.length > 0) {
                powerMas = generatePowerMas2(data, source[0])
                if (powerMas.length > 0) {
                    source.unshift({
                        value: FACT_CHART_NAME,
                        name: FACT_CHART_NAME
                    })
                    source.unshift({
                        value: PLAN_CHART_NAME,
                        name: PLAN_CHART_NAME
                    })
                }
                //console.log('source',source)
                //console.log('powerMas',powerMas)
    
                //for (let i=0; i<powerMas.length; i++) data.push(powerMas[i])
            }

            setPointsSource(source)
            setPointsData(powerMas)
            //console.log(data)
        } else {
            notify(vals.message,'error',5000)
        }

        setLoading(false)
    }

    function getPointsSource(mas) {
        let res = []
        let item = {}
        for (let i=0; i<mas.length; i++) {
            item = {
                value: mas[i].kod_point,
                name: 'ТУ №'+mas[i].nomer+': '+mas[i].name
            }
            if (!res.find(el => el.value === mas[i].kod_point)) res.push(item)
        }

        return res
    }

    //берем все данные и первую точку в списке
    function generatePowerMas2(values, point) {
        let mas = []
        let maxOut = getMaxOut(values)
        if (!point || values.length === 0) return mas
        for (let val of values){
            if ((val.old_power === 4 || [3,4].includes(val.new_power)) && point.value===val.kod_point){
                if (val.old_power === 4){
                    val.value1 = FACT_CHART_NAME
                    val[FACT_CHART_NAME] = maxOut
                } else if ([3,4].includes(val.new_power)) {
                    val.value2 = PLAN_CHART_NAME
                    val[PLAN_CHART_NAME] = maxOut
                }
            }
        }
        return values
    }
    //берем все данные и первую точку в списке
    // function generatePowerMas(values, point) {
    //     let mas = []
    //     let maxOut = getMaxOut(values)
    //     if (!point || values.length === 0) return mas
    //     for (let i=0; i<values.length; i++) {
    //         let val = values[i]
    //         if (val.old_power === 4 || [3,4].includes(val.new_power)){
    //             let type = val.power_color = val.old_power === 4 ? FACT_CHART_NAME : [3,4].includes(val.new_power) ? PLAN_CHART_NAME : 'none'
    //             let item = {}
    //             item.value = type
    //             item.dat_out = val.dat_out
    //             item[type] = maxOut//val[val.kod_point]
    //             mas.push(item)
    //         }
    //     }
    //     return mas
    // }

    //определим максимаьное показание в во всем графике
    function getMaxOut(values) {
        let maxOut = null
        if (values.length === 0) return maxOut
        maxOut = values.reduce((acc, curr) => acc.out > curr.out ? acc : curr).out
        return maxOut
    }

    const calendarOptions = {
        applyValueMode: "useButtons",
        showDropDownButton: true,
        maxZoomLevel: 'year',
        minZoomLevel: 'month',
    }

    return (
        <div>
            <div className="row mt-3 mb-3">
                <div className="col-md-12">
                {/* <PointsTree selectionMode="multiple" onSelect={onTreeSelect} typePoints={pointsType} points={points} /> */}
                <PointsTreeHook selectionMode="multiple" onSelect={onTreeSelect} typePoints={pointsType} points={points} />
                </div>
            </div>

            {/* <div className="row mt-3">
                <div className="col-md-12">
                    <SelectPeriod mode={'single'}/>
                    <Button className="mt-3" disabled={!isPointsSelected} text="Показать" icon="refresh" onClick={getOutValsInfo} />
                </div>
            </div> */}
            <div className="row mb-4" style={{alignItems: 'baseline'}}>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12">
                    <DateBox
                        calendarOptions={calendarOptions}
                        defaultValue={period1}
                        value={period1}
                        max={period2}
                        displayFormat='dd MMMM yyyy г.'
                        onValueChanged={(e) => setPeriod1(e.value)}
                        disabled={loadingPeriod}
                    />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 mt20xs">
                    <DateBox
                        calendarOptions={calendarOptions}
                        defaultValue={period2}
                        value={period2}
                        min={period1}
                        displayFormat='dd MMMM yyyy г.'
                        onValueChanged={(e) => setPeriod2(e.value)}
                        disabled={loadingPeriod}
                    />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-xs-12 mt20xs">
                    <Button disabled={!isPointsSelected} text="Показать" icon="refresh" onClick={getOutValsInfo} />
                </div>
            </div>


            <div className="row mt-3">
                <div className="col-md-12">
                {
                    isPointsSelected && btnClicked ? 
                    <div>  
                        <Chart
                            id="chart"
                            dataSource={pointsData} barGroupPadding={0}
                            // stickyHovering={false}
                        >
                            <CommonSeriesSettings
                                argumentField="dat_out"
                                type="spline"
                                ignoreEmptyPoints={true}
                                // hoverMode="includePoints"
                                //point={{color: item.power_color, visible: item.power_color !== 'none'}} 
                            >
                            <Point visible={false} hoverMode="allArgumentPoints" />
                            </CommonSeriesSettings>
                            {
                            pointsSource.map((item) => <Series barPadding={0}
                                key={item.value}
                                valueField={item.value}
                                name={item.name}
                                color={(item.name === FACT_CHART_NAME && 'orange') || (item.name === PLAN_CHART_NAME && 'green')}
                                type={[FACT_CHART_NAME, PLAN_CHART_NAME].includes(item.name) && 'bar'}
                                />)
                            }
                            <Export enabled={true} />
                            <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            hoverMode="excludePoints"
                            />
                            <Tooltip

                                enabled={true}
                                shared={true}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                        <LoadPanel visible={loading} /> 
                    </div>
                    : 'Выберите точки учета и нажмите <Показать>..'
                }
                </div>
            </div>
        </div>
    )
}
function customizeTooltip(pointInfo) {
    const items = pointInfo.valueText.split('\n');
    const color = pointInfo.point.getColor();
    items.forEach((item, index) => {
        if (item.indexOf(pointInfo.seriesName) === 0) {
            const element = document.createElement('span');
            element.textContent = item;
            element.style.color = color;
            element.className = 'active';
            items[index] = element.outerHTML;
        }
    });

    return { text: items.join('\n') };
}
export default ReadsProfile
