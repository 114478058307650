//import 'devextreme/dist/css/dx.common.css';
//import './themes/generated/theme.base.css';

// import './dx-styles.scss';
//import themes from "devextreme/ui/themes";
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { ReqPopupProvider } from './contexts/req-popup'
import { loadMessages, locale } from 'devextreme/localization'
import { PeriodProvider } from './contexts/period'
import ruMessages from 'devextreme/localization/messages/ru.json'

loadMessages(ruMessages)
locale(navigator.language)

function App() {
  const { user, loading } = useAuth()

  if (loading) {
    return <LoadPanel visible={true} />
  }

  if (user) {
    return <Content />
  }

  return <UnauthenticatedContent />
}

const Main = () => {
  const screenSizeClass = useScreenSizeClass()
  // console.log('localization = '+navigator.language)
  //locale(navigator.language)

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <ReqPopupProvider>
            <PeriodProvider>
              <div className={`app ${screenSizeClass}`}>
                <App />
              </div>
            </PeriodProvider>
          </ReqPopupProvider>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}

export default Main
