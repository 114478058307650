import React, {useEffect, useState} from "react";
import {useDoCertsList} from "./utils/hooks";
import SelectBox from "devextreme-react/select-box";


const SelectCert = ({
                      setThumbprint = _ => _,
                      //Component = Select,
                      callbackError,
                      value }) => {
  const [listCert, setListCert] = useState([{ value: "подпись", label: "Сертификаты отсутствуют" }]);

  const [selectItem, setSelectItem] = useState(null);

  useDoCertsList(callbackError)
    .then(setListCert)
    .catch(e => callbackError(String(e)));

  useEffect(() => {
    if (selectItem) {
      setThumbprint(selectItem.value);
    } else {
      setThumbprint(listCert[0].value);
      setSelectItem(listCert[0].value);
    }
  }, [selectItem, listCert, setThumbprint, setSelectItem]);

  const onChange = value => setSelectItem(value);

  return (
      <>
        <SelectBox name="thumbprint"
                   dataSource={listCert}
                   label="Выберите сертификат"
                   labelMode='floating'
                   displayExpr="label"
                   valueExpr="value"
                   defaultValue={listCert[0].value}
                   onValueChanged={onChange} />
      </>
  );
};

export default SelectCert;
