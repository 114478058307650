import React, { useState, useEffect, createContext, useContext, useCallback } from 'react'
import { useAuth } from './auth'

function ReqPopupProvider(props) {

  const [isOpen, setIsOpen] = useState(false) 
  const [defaultType, setDefaultType] = useState()
  const [defaultMessage, setDefaultMessage] = useState()
  const [defaultFiles, setDefaultFiles] = useState([])
  const dogVisibleTypes = [2,5,6,7] //типы заявок с видимостью селекта договоров
  const pointVisibleTypes = [5,6] //типы заявок с видимостью селекта точек
  const [tuList, setTuList] = useState([])
  const [selectedTu, setSelectedTu] = useState(null)
  const [disabledTypes, setDisabledTypes] = useState(false)
  const [pu, setPu] = useState(null)
  //пока выношу сюда переменную для диалога подачи заявки в тех. поддержку, дабы не городить контекст
  const [visibleHelpPopup, setVisibleHelpPopup] = useState(false)
  //
  const [newdogFiles, setNewdogFiles] = useState([])

  const { reqTypes } = useAuth()

  useEffect(() => {
    (async function () {
      
    })()
  }, [])

  const openReqPopup = useCallback((visible, type = null, message= null, files = [], tu = [], disableTypes = true, pu = null) => {
    setDefaultType(type)
    setIsOpen(visible)
    setDisabledTypes(disableTypes)
    if (type !== null) {
      const newType = reqTypes.find(item => item.id === type)
      setDefaultMessage(newType ? newType.defaultText : null)
    } else {
      setDefaultMessage(message)
    }
    setDefaultFiles(files)
    setTuList(tu)
    setPu(pu)
  }, [reqTypes])

  return (
    <ReqPopupContext.Provider value={{ isOpen, openReqPopup, defaultType, defaultMessage, setDefaultMessage, defaultFiles, setDefaultType, 
      dogVisibleTypes, pointVisibleTypes, tuList, setTuList, selectedTu, setSelectedTu, disabledTypes, setDisabledTypes,
      visibleHelpPopup, setVisibleHelpPopup, newdogFiles, setNewdogFiles, pu, setPu }} {...props} />
  )
}

const ReqPopupContext = createContext({})
const useReqPopup = () => useContext(ReqPopupContext)

export { ReqPopupProvider, useReqPopup }
