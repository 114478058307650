import Form, { SimpleItem, GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useAuth } from '../../contexts/auth'
import { useReqPopup } from '../../contexts/req-popup'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser, faFileLines, faCircleInfo, faFolder} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { verifyAccess } from '../../utils/admin.utils';
import AccessDenied from '../../components/access-denied/access-denied';
import { getDoc, getDocs, getManager } from '../../api/dogovor';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid'
import notify from 'devextreme/ui/notify';
import LoadPanel from 'devextreme-react/load-panel';

const Dogovor = (props) => {

    const auth = useAuth()
    const dogovor = auth.selectedDog
    const { openReqPopup } = useReqPopup()

    //инфо о договоре
    const ndogEditorOptions = {value: dogovor.ndog}
    const datNumdogEditorOptions = {value: dogovor.datNumdog}
    const datDogEditorOptions = {value: dogovor.datDog}
    const bpolNameEditorOptions = {value: dogovor.bpolName}
    const bpolRsEditorOptions = {value: dogovor.bpolRs}
    const bpolBicEditorOptions = {value: dogovor.bpolBic}
    const bpolKsEditorOptions = {value: dogovor.bpolKs}
    //инфо о контрагенте
    const nameEditorOptions = {value: dogovor.name}
    const innEditorOptions = {value: dogovor.inn}
    const ogrnEditorOptions = {value: dogovor.ogrn}
    const adrUrEditorOptions = {value: dogovor.adrUr}
    const adrDEditorOptions = {value: dogovor.adrD}
    const adrDPEditorOptions = {value: dogovor.adrDP}

    const [visiblePage, setVisiblePage] = useState(true)
    const [manager, setManager] = useState(null)
    const [loadingManager, setLoadingManager] = useState(false)
    const [docs, setDocs] = useState([])
    const [loadingDocs, setLoadingDocs] = useState(false)
    const [downloadingDoc, setDownloadingDoc] = useState(false)
    const pageSizes = [10, 25, 50]

    useEffect(() => {
        const pageRoles = [2,3]
        if (!verifyAccess(pageRoles, dogovor)){
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            //достаем менеджера
            setLoadingManager(true)
            const res = await getManager(dogovor.kodDog)
            if (res.isOk) {
                if (res.data.manager) setManager(res.data.manager)
                else setManager(null)
            }
            setLoadingManager(false)
            //достаем документы
            setLoadingDocs(true)
            const resDocs = await getDocs(dogovor.kodDog)
            if (resDocs.isOk) setDocs(resDocs.data)
            else notify(resDocs.message, 'error', 5000)
            setLoadingDocs(false)
        })()
    }, [dogovor])

    const onClick = () => {
        openReqPopup(true, 2)
    }

    function loadDocRender(e) {
        const id = e.data.kod_dopdoc
        return <a href='#/dogovor' onClick={()=>{downloadDoc(id)}}>{e.value}</a>
    }

    async function downloadDoc(kodDoc) {
        setDownloadingDoc(true)

        const res = await getDoc(kodDoc)
        if (res.isOk) {
            console.log(res.data)
            const data = res.data.file
            const filename  = res.data.nameFile

            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(data), filename)

        } else notify(res.message,'error',5000)

        setDownloadingDoc(false)
    }

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <div className="row mt20">
                <div className="col-md-6">
                    <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faFileLines} />
                    <h3><strong>Информация о договоре</strong></h3>
                    <Form labelMode={'floating'}
                          labelLocation={'top'}
                          id="form-dogovor" readOnly={true}
                    >
                        <h3>Информация о договоре</h3>

                        <GroupItem caption="">
                            <SimpleItem dataField="Номер_договора" editorOptions={ndogEditorOptions} />
                            <GroupItem colCount={2}>
                                <SimpleItem dataField="Начало_действия" editorOptions={datDogEditorOptions} />
                                <SimpleItem dataField="Дата_заключения" editorOptions={datNumdogEditorOptions} />
                            </GroupItem>
                            <SimpleItem dataField="Реквизиты для оплаты" editorOptions={bpolNameEditorOptions} />
                            <SimpleItem dataField="Расчетный_счет" editorOptions={bpolRsEditorOptions} />
                            <GroupItem colCount={2}>
                                <SimpleItem dataField="БИК" editorOptions={bpolBicEditorOptions} />
                                <SimpleItem dataField="Кор._счет" editorOptions={bpolKsEditorOptions}/>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
                <div className="col-md-6 mt20md">
                    <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faCircleInfo} />
                    <h3><strong>Информация о контрагенте</strong></h3>
                    <Form labelMode={'floating'} labelLocation={'top'} id="form-dogovor2" readOnly={true}>
                        <GroupItem caption="">
                            <SimpleItem dataField="Наименование_контрагента" editorOptions={nameEditorOptions} />
                            <GroupItem colCount={2}>
                                <SimpleItem dataField='ИНН' editorOptions={innEditorOptions} />
                                <SimpleItem dataField="ОГРН" editorOptions={ogrnEditorOptions} />
                            </GroupItem>
                            <SimpleItem dataField="Адрес_юридический" editorOptions={adrUrEditorOptions} />
                            <SimpleItem dataField="Адрес_фактический" editorOptions={adrDEditorOptions} />
                            <SimpleItem dataField="Адрес_для_переписки" editorOptions={adrDPEditorOptions} />
                            <ButtonItem>
                                <ButtonOptions useSubmitBehavior={false} type="default" icon="edit" text="Подать заявку на изменение адреса для переписки" onClick={onClick} />
                            </ButtonItem>
                        </GroupItem>
                    </Form>
                </div>
            </div>
            <div className="mt20">
                <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faFolder} />
                <h3><strong>Документы по договору</strong></h3>
                <LoadPanel visible={downloadingDoc} />
                {
                    !loadingDocs && docs.length === 0 ?
                    'Нет документов по договору.' :
                    loadingDocs ? 
                    'Загрузка документов по догвору..' :
                    <DataGrid
                        id='dogovor-docs'
                        dataSource={docs}
                        showBorders={true}
                        allowColumnReordering={true}      
                    >
                        <Column dataField="name_type" dataType="string" caption="Тип документа"></Column>
                        <Column dataField="num_doc" dataType="string" caption="Номер документа" width="200px"></Column>
                        <Column dataField="dat_doc" dataType="date" caption="Дата документа" width="150px"></Column>
                        <Column dataField="name_file" dataType="string" caption="Наименование файла" cellRender={loadDocRender}></Column>
                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                        <Paging defaultPageSize={10} />
                    </DataGrid> 
                }
            </div>
            <div className="mt20">
                <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faCircleUser} />
                <h3><strong>Ответственный менеджер по договору</strong></h3>
                <Form labelMode={'floating'} labelLocation={'top'} id="form-dogovor3" readOnly={true} visible={!loadingManager}>
                    <GroupItem caption="" colCount={3}>
                        <SimpleItem dataField="ФИО" editorOptions={{value: manager ? manager.fio : ''}}/>
                        <SimpleItem dataField='Email'  editorOptions={{value: manager ? manager.eMail : ''}}/>
                        <SimpleItem dataField="Телефон"  editorOptions={{value: manager ? manager.tel : ''}}/>
                    </GroupItem>
                </Form>
            </div>
        </div>
        : <AccessDenied/>}</>
    )
}

export default Dogovor
