//import {PointsTree} from "../../components/points-tree/points-tree"
import {Button} from 'devextreme-react/button'
import DataGrid, {Button as GridButton, Column, Editing, Pager, Paging} from 'devextreme-react/data-grid'
import LoadPanel from 'devextreme-react/load-panel'
import {useEffect, useState} from "react"
import {getPoints} from "../../services/point.service"
import {getReads, sendIntegralAkt, sendIntegralReads, sendIntervalAkt} from "../../api/point"
import notify from "devextreme/ui/notify"
import {getTemplateIntegral, getTemplateInterval} from "../../api/template"
import PopupIntegral from "../../components/reads-popup-integral/reads-popup-integral"
import {getCurrentPeriod, getPointsTreePlain} from "../../api/dogovor"
import {useAuth} from "../../contexts/auth"
import PopupInterval from "../../components/reads-popup-interval/reads-popup-interval"
import {Popover} from 'devextreme-react/popover'
import {verifyAccess} from "../../utils/admin.utils"
import AccessDenied from "../../components/access-denied/access-denied"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareFromSquare} from "@fortawesome/free-solid-svg-icons";
import { parseNumber } from "devextreme/localization"
import PointsTreeHook from "../../components/points-tree/point-tree-hook"

const PokazaniaEditPage = () => {

    const [visibleGrid, setVisibleGrid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [selectedPoints, setSelectedPoints] = useState([])
    const [loadExcelVisible, setLoadExcelVisible] = useState(false)
    const [loadIntervalVisible, setLoadIntervalVisible] = useState(false)
    const [uploadMultiple, setUploadMultiple] = useState(false)
    const { selectedDog, updateCountNotReads } = useAuth()
    const [currentPeriod, setCurrentPeriod] = useState({})
    const [points, setPoints] = useState(null)
    const [popoverVisible, setPopoverVisible] = useState(false)
    const [visiblePage, setVisiblePage] = useState(true)

    const onTreeSelect = (mas) => {
        const points = getPoints(mas)
        setSelectedPoints(points)
        setVisibleGrid(points.length > 0)
    }

    useEffect(() => {
        const pageRoles = [2]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function () {
            setVisiblePage(true)
            setLoading(true)
            setPoints(null)
            setDataSource([])
            setSelectedPoints([])
            //получаем точки по договору
            const res = await getPointsTreePlain(selectedDog.kodDog, 'all')
            if (res.isOk) {
                setPoints(res.data)
            } else {
                notify(res.message, 'error', 5000)
            }

            //получаем период по договору
            const periodRes = await getCurrentPeriod(selectedDog.kodDog)
            if (periodRes.isOk) {
                setCurrentPeriod(periodRes.data)
            } else {
                notify(periodRes.message,'error',5000)
            }
            setLoading(false)
        })()
    }, [selectedDog])

    const onRefreshClick = async () => {
        setLoading(true)
        if (selectedPoints.length > 0) {
            const reads = await getReads(selectedPoints,currentPeriod.period,currentPeriod.period,'PRIEM')
            if (reads.isOk) setDataSource(reads.data)
            else notify(reads.message, 'error', 5000)
        } else setDataSource([])
        setLoading(false)
    }

    const loadExcel = () => {
        setUploadMultiple(false)
        setLoadExcelVisible(true)

    }

    const loadInterval = () => {
        setUploadMultiple(false)
        setLoadIntervalVisible(true)
    }

    const onHidingIntegralPopup = () => {
        setLoadExcelVisible(false)
    }

    const onHidingIntervalPopup = () => {
        setLoadIntervalVisible(false)
    }

    const loadTemplateIntegral = async () => {
        setLoading(true)
        console.log(selectedDog.kodDog)
        const res = await getTemplateIntegral(currentPeriod.period,selectedPoints,selectedDog.kodDog)
        if (res.isOk){
            const file = res.data.data
            const filename = res.data.filename
            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(file), filename)
        } else notify(res.message,'error',5000)
        setLoading(false)
    }

    const loadTemplateInterval = async (interval) => {
        setLoading(true)
        const res = await getTemplateInterval(currentPeriod.period,selectedPoints,interval,selectedDog.kodDog)
        if (res.isOk){
            const file = res.data.data
            const filename = res.data.filename
            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(file), filename)
        } else notify(res.message,'error',5000)
        setLoading(false)
    }

    const onSendIntegralFile = async (files) => {
        setLoading(true);

        const fd = new FormData()
        for (let i=0; i<files.length; i++) {
            fd.append('files',files[i])
        }
        fd.append('dogovor', selectedDog.kodDog)
        fd.append('period', currentPeriod.period)
        //console.log(fd)

        const result = await sendIntegralAkt(fd)
        if (result.isOk) {
            setLoadExcelVisible(false)
            notify(result.message, 'success', 5000)
            updateCountNotReads(selectedDog)
        } else {
            notify(result.message, 'error', 10000);
        }

        setLoading(false)
    }

    const onSendIntervalFile = async (files) => {
        setLoading(true);

        const fd = new FormData()
        for (let i=0; i<files.length; i++) {
            fd.append('files',files[i])
        }
        fd.append('dogovor', selectedDog.kodDog)
        fd.append('period', currentPeriod.period)
        //console.log(fd)

        const result = await sendIntervalAkt(fd)
        if (result.isOk) {
            setLoadIntervalVisible(false)
            notify(result.message, 'success', 5000)
            updateCountNotReads(selectedDog)
        } else {
            notify(result.message, 'error', 10000);
        }

        setLoading(false)
    }

    const pageSizes = [10, 25, 50, 100]

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const onSaveReads = async (e) => {
        const changes = e.changes
        console.log(changes)

        var reads = []
        for (let i=0; i<changes.length; i++) {
            const point = changes[i].key.kod_point
            const counter = changes[i].key.readlast
            const numPu = changes[i].key.num_pu
            const kodPointIni = changes[i].key.kod_point_ini
            const outadd = changes[i].key.outadd
            const lastCounter = changes[i].key.last_counter

            if (!counter && !outadd) {
                notify('Для передачи показаний необходимо ввести хотя бы одно редактируемое значение!','error',10000)
                return
            }

            if ((counter && !isNumeric(counter)) || (outadd && !isNumeric(outadd))) {
                notify('Показания могут содержать только цифры и знак разделителя "."!','error',5000)
                return
            }

            if (parseFloat(lastCounter) && (counter - parseFloat(lastCounter) < 0)) {
                // eslint-disable-next-line no-restricted-globals
                if (!confirm('Внимание, текущее показание меньше предыдущего! Продолжить?'))
                //notify('Текущие показания не могут быть меньше предыдущих!','error',5000)
                return
            }

            reads.push({
                point: point,
                num_pu: numPu,
                counter: counter ? counter : null,
                kod_point_ini: kodPointIni,
                outadd: outadd ? outadd : null
            })
        }
        setLoading(true)
        const resData = await sendIntegralReads(currentPeriod.period,reads)
        if (resData.isOk) {
            // notify('Показания переданы в биллинговую систему для проведения расчетов. '+
            //      'Все показания можно просмотреть на форме История показаний.','success',15000)
            setPopoverVisible(true)
        } else {
            notify(resData.message,'error',5000)
        }
        setLoading(false)
    }

    const popoverHiding = () => {
        setPopoverVisible(false)
    }

    const readlastRender = (data) => {
        return (<div style={{color: 'blue', cursor: 'pointer'}}>{data.data.readlast} &#9998;</div>)
    }

    const outaddRender = (data) => {
        return (<div style={{color: 'blue', cursor: 'pointer'}}>{data.data.outadd} &#9998;</div>)
    }

    function calcRashod(e){
        const readlast = e.readlast
        const lastCounter = e.last_counter
        const rkoeff = e.rkoeff
        const outadd = e.outadd
        const razr = e.razr
        const razr2 = e.razr2
        let res = null
        if (!!readlast) {
            const difference = (parseNumber(readlast.toString().replace('.',',')) - (lastCounter ? parseNumber(lastCounter.toString().replace('.',',')) : 0))
            if (difference >= 0){ //console.log(difference)
                res = difference * (rkoeff ? parseNumber(rkoeff.toString().replace('.',',')) : 1) + (outadd ? parseNumber(outadd.toString().replace('.',',')) : 0)}
            else 
                res = (difference + Math.pow(10, razr)) * parseNumber(rkoeff.toString().replace('.',',')) + (outadd ? parseNumber(outadd.toString().replace('.',',')) : 0)
        }
        return res ? res.toFixed(razr2 ? parseInt(razr2) : 6): null
    }

    return (
        <>{visiblePage ?
            <div className={'content-block dx-card responsive-paddings big-input'}>
                <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faShareFromSquare} />
                <h3><strong>Передача показаний</strong></h3>
                <div className={''}>
                    <LoadPanel visible={loading} />
                    <PopupIntegral
                        visible={loadExcelVisible}
                        title={'Загрузить файл интегральных показаний'}
                        onHiding={onHidingIntegralPopup}
                        multiple={uploadMultiple}
                        onSendFile={onSendIntegralFile}
                    />
                    <PopupInterval
                        visible={loadIntervalVisible}
                        title={'Загрузить файл интервальных показаний'}
                        onHiding={onHidingIntervalPopup}
                        multiple={uploadMultiple}
                        onSendFile={onSendIntervalFile}
                    />
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {/* <PointsTree selectionMode="multiple" onSelect={onTreeSelect} typePoints={'all'} points={points} /> */}
                            <PointsTreeHook selectionMode="multiple" onSelect={onTreeSelect} typePoints={'all'} points={points}/>
                        </div>
                    </div>


                    <div className="row mt-3">
                        <div className="col-md-12">
                            <Button className="float-right" disabled={selectedPoints.length===0 || currentPeriod === {}} text="Показать" icon="refresh" onClick={onRefreshClick} />
                            <div>
                                Период: <b>{currentPeriod ? currentPeriod.periodString : 'Не удалось загрузить период'}</b>
                            </div>
                            {/* <SelectPeriod mode="single" /> */}
                        </div>
                        <Popover
                            visible={popoverVisible}
                            target="#reads-grid"
                            position="top"
                            width={400}
                            onHiding={popoverHiding}
                            shading={true}
                            shadingColor="rgba(0, 0, 0, 0.5)"
                            showCloseButton={true}
                        >
                            <p>Показания переданы в биллинговую систему для проведения расчетов.</p>
                            <p>Все показания можно просмотреть на странице <a href="#/pokazania-history?tab=1">История показаний</a>.</p>
                        </Popover>
                    </div>

                        {
                            visibleGrid ?
                                    <DataGrid
                                        id="reads-grid"
                                        className={' mt20'}
                                        dataSource={dataSource}
                                        showBorders={true}
                                        //focusedRowEnabled={true}
                                        defaultFocusedRowIndex={0}
                                        columnAutoWidth={true}
                                        columnHidingEnabled={true}
                                        noDataText="Нет данных для отображения"
                                        wordWrapEnabled={true}
                                        //onRowUpdating={(data) => {onSaveReads(data); updateCountNotReads(selectedDog);}}
                                        onSaved={(data) => {onSaveReads(data); updateCountNotReads(selectedDog);}}
                                        //onEditingChange={calcRashod}
                                    >
                                        <Editing
                                            mode="batch"//batch//cell
                                            allowUpdating={true}
                                            allowAdding={false}
                                            allowDeleting={false}
                                            //selectTextOnEditStart={this.state.selectTextOnEditStart}
                                            //startEditAction={this.state.startEditAction}
                                            //onChangesChange={onSaveReads}
                                            
                                            //startEditAction={'click'}
                                        />
                                        <Column dataField="name" caption="Название ТУ" dataType="string" width={200} allowEditing={false}/>
                                        <Column dataField="nomer" caption="№ ТУ" dataType="string" width={100} allowEditing={false}/>
                                        <Column dataField="pus_num_pu" caption="№ ПУ" dataType="string" allowEditing={false}/>
                                        <Column dataField="interval_name" caption="Интервал" dataType="string" allowEditing={false}/>
                                        <Column dataField="last_counter" caption="Предыдущие" dataType="string" allowEditing={false}/>
                                        <Column dataField="readlast" caption="Текущие" dataType="string" cellRender={readlastRender} width={'150px'}></Column>
                                        {/* <Column dataField="out" caption="Расход" dataType="string" allowEditing={false}/> */}
                                        <Column dataField="outadd" caption="Доп. расход" dataType="string" cellRender={outaddRender}/>
                                        <Column dataField="out_lkk" caption="Расход" dataType="string" calculateCellValue={calcRashod} allowEditing={false}/>
                                        <Column dataField="pot_fix" caption="Потери" dataType="number" allowEditing={false}/>
                                        <Column dataField="pot_prc" caption="Потери (%)" dataType="number" />
                                        <Column type="buttons" visible={false}>
                                            <GridButton name="excel" text="" icon="xlsfile" onClick={loadExcel}></GridButton>
                                        </Column>
                                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                                        <Paging defaultPageSize={10} />
                                    </DataGrid>
                                :
                                'Для ввода интегральных показаний выберите точки учета и нажмите кнопку <Показать>..'
                        }


                    <div className="row row justify-content-around">
                        <div className="col-sm-6 col-md-4 mt-3">
                            <b>Массовая загрузка интегральных показаний:</b>
                            <Button className={'block mt-3'} id="load-integral-template" text="Скачать интегральный шаблон" icon="download" onClick={loadTemplateIntegral} />
                            <Button className={'block mt-3'} id="load-integral" text="Загрузить интегральные показания" icon="xlsfile" onClick={loadExcel} />
                        </div>
                        <div className="col-sm-6 col-md-4 mt-3">
                            <div><b>Массовая загрузка интервальных показаний:</b></div>
                            <div className="text">* Значения необходимо заполнять без учета потерь!</div>
                            <Button className={'block mt-3'} id="load-interval-template" text="Скачать шаблон (почасовки)" icon="download" onClick={() => {loadTemplateInterval(1)}} />
                            <Button className={'block mt-3'} id="load-interval-template" text="Скачать шаблон (получасовки)" icon="download" onClick={() => {loadTemplateInterval(0.5)}} />
                            <Button className={'block mt-3'} id="load-interval" text="Загрузить интервальные показания" icon="xlsfile" onClick={loadInterval} />
                        </div>
                    </div>
                </div>
            </div>
            : <AccessDenied/>}</>
    )
}

export default PokazaniaEditPage
