import SelectBox from 'devextreme-react/select-box'
import { Button } from 'devextreme-react/button'
import { useAuth } from '../../contexts/auth'
import StorageService from '../../services/storage.service'
import { useReqPopup } from '../../contexts/req-popup'
import { TextBox } from 'devextreme-react'

const SelectAgrDogovor = (props) => {

    const { agrDogovors, selectedAgrdog, setSelectedAgrdog} = useAuth()
    const { openReqPopup } = useReqPopup()
    const sd_visible = props.visible// ? 'block' : 'none'

    const onSelectDogovor = (e) => {
        let selectedDog = agrDogovors.find(dogovor => dogovor.kodDog === e.value)
        if (selectedDog) {
          StorageService.setLocalAgrDogovor(selectedDog)
          setSelectedAgrdog(selectedDog)
        }
    }

    const btnAddClick = () => {
      openReqPopup(true,4)
    }

    const renderField = (data) => {
      return (
          <div>
              <TextBox 
                defaultValue={data ? ('№'+(data.ndog ? data.ndog : '') + ': ' + (data.nameDog ? data.nameDog : '')) : ''} />
          </div>
      );
  }

  return (
    <>
    {
      sd_visible &&
      <div className={'select-dogovor content-block dx-card responsive-paddings big-input'}>
        <div className="bd-container-sidebar">
          <Button className={'float-right big-button'} icon="add" type={'success'} onClick={btnAddClick}/>
        </div>
        <div className="bd-container-body">
          <SelectBox 
            labelMode={'floating'} 
            label={'Договор управления спросом'}
            dataSource={agrDogovors}
            displayExpr="name"//{(itemData, itemIndex, itemElement)=>{return '№'+itemData.ndog+': '+itemData.name}}//"name"
            valueExpr="kodDog"
            defaultValue={selectedAgrdog ? selectedAgrdog.kodDog : null}
            value={selectedAgrdog ? selectedAgrdog.kodDog : null}
            //items={list_dogovors}
            itemTemplate={(itemData, itemIndex, itemElement)=>{itemElement.append('№'+itemData.ndog +  ': ' + itemData.nameDog);}}
            className="select-dogovor"
            placeholder="Выберите агрегированный договор.."
            onValueChanged={onSelectDogovor}
            noDataText="У Вас нет подключенных агрегированных договоров"
            fieldRender={renderField}
          />
        </div>
      </div>
    }
    </>
  )
}

export default SelectAgrDogovor