import React from 'react';
import SelectBox from 'devextreme-react/select-box';

import {themes2} from '../../data/themes.js';
import themes from "devextreme/ui/themes";
import ConditionalIcon from './conditionalIcon.js';
import Item from './item.js';
import {Template} from "devextreme-react/core/template";
import "./theme-select.scss"
import {RadioGroup} from "devextreme-react";

const classGroup = [
    { id: 0, text: 'Box', class:'container-lg' },
    { id: 1, text: 'Full-screen', class:'container-fluid' }
];
class ThemeSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: themes2[0],
            selectionClass: classGroup[0].class,
        };

        this.onValueChanged = this.onValueChanged.bind(this);
        this.renderConditionalIcon = this.renderConditionalIcon.bind(this);
        //восстанавливаем тему в стэйт при первом запуске (если меняли)
        this.state.value = "generic.light"//window.localStorage.getItem("dx-theme") ||
        this.state.selectionClass = window.localStorage.getItem("dx-theme-class") || "container-lg"
        this.changeSelectionPriority = this.changeSelectionPriority.bind(this);
        //перенесено в header
        //themes.current(this.state.value);

    }

    render() {
        return (<>
                <div>
                    <SelectBox dataSource={themes2}
                               value={this.state.value}
                               displayExpr='NameRus'
                               valueExpr='NameTheme'
                               itemRender={Item}
                               dropDownButtonTemplate='conditionalIcon'
                               onValueChanged={this.onValueChanged}
                               width={'100%'}
                    >
                        <Template name='conditionalIcon' render={this.renderConditionalIcon} />
                    </SelectBox>
                    <RadioGroup className="mt10" id="radio-group-with-selection" items={classGroup} value={this.state.selectionClass}
                                valueExpr="class" displayExpr="text" onValueChanged={this.changeSelectionPriority} />
                </div>
            </>
        );
    }
    changeSelectionPriority(e) {
        this.setState({
            selectionClass: e.value,
        });
        window.localStorage.setItem("dx-theme-class", e.value);
        window.location.reload();
    }
    onValueChanged(e) {
        //сохранение темы в state
        this.setState({value: e.value});
        //сохраняем текущую тему для перезапуска
        window.localStorage.setItem("dx-theme", e.value);
        //применяем тему
        themes.current(e.value);
        window.location.reload();
    }

    renderConditionalIcon() {
        return <ConditionalIcon value={this.state.value} />;
    }
}

export default ThemeSelect;
