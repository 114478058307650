import ccpa from "crypto-pro-cadesplugin";
import cadescomMethods from "crypto-pro-cadesplugin/dist/cadescomMethods"
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export const signFile = async ({ file = null, thumbprint }) => {
  if (file) {
    const header = ";base64,";
    const sFileData = await toBase64(file);
    const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);
    const type = sFileData.substr(0, sFileData.indexOf(header));
    const certsApi = await ccpa();
    const sign = await certsApi.signBase64(thumbprint, sBase64Data);
    const contentType = type.split(":")[1];
    const blob = b64toBlob(sign, contentType);
    return { blob, fileName: `${file.name}.1.sig` };
  }
  return { blob: null, fileName: null };
};

export const getInfoFromSignedData = async (sSignedMessage) => {
  let signersTxt;
  let messageErr = ""
  let CADESCOM_CADES_BES = 1;
  let CADESCOM_BASE64_TO_BINARY = 1;
  // Чтоб не гонять PDF лишний раз, суём рандомный Base64
  // В данном случае закодирована строка "Some Data."
  let dataInBase64 = "U29tZSBEYXRhLg==";
  let SigningTime = 'н/д';
  // try {
  //   let oSignedData2 = await cadescomMethods.oSignedData();
  //   try {
  //     await oSignedData2.VerifyCades(sSignedMessage, CADESCOM_CADES_BES);
  //   } catch (ex) {
  //     messageErr = ex.message;//certsApi.getLastError(ex);
  //   }
  //   let oSigners
  //   try {
  //     oSigners= await oSignedData2.Signers;
  //   } catch (ex){
  //     messageErr = ex.message;
  //   }
  //   let item = await oSigners.Item(1)
  //   SigningTime = await item.SigningTime;
  //   let z = SigningTime;
  //   alert(z)
  // } catch (ex){
  //   messageErr = ex.message;
  //   alert(messageErr)
  // }
  try {
    let oSignedData = await cadescomMethods.oSignedData();
    try {
      await oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
      await oSignedData.propset_Content(dataInBase64);
      await oSignedData.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);
    }
    catch (ex) {
        // Гасим ошибку если верификация не прошла,
        // а она не прошла, так как левые данные на проверке. :)
        // Нам важны только аттрибуты подписи.
      messageErr = "Ошибка при создании oSignedData: " + ex.message;//certsApi.getLastError(ex);
      //console.log(messageErr);
    }
    let signers, signersCnt, SubjectName, ValidFromDate, ValidToDate, SerialNumber, IssuerName, Version;
    try {
      signers =  await oSignedData.Signers;
      signersCnt =  await signers.Count;
    } catch (ex) {
      messageErr = "Ошибка в получении подписи или их количества: " + ex.message;//certsApi.getLastError(ex);
      //console.log(messageErr);
     }
      for (let i = 1; i <= signersCnt; i++) {
        let signer;
        try {
          signer =  await signers.Item(i);
          let cert =  await signer.Certificate;
          SubjectName =  await cert.SubjectName;
          ValidFromDate = new Date(await cert.ValidFromDate);
          ValidToDate = new Date(await cert.ValidToDate);
          SerialNumber = await cert.SerialNumber;
          IssuerName = await cert.IssuerName;
          Version = await cert.Version;
          // try {
          //   SigningTime = await signer.SigningTime;
          // }catch (ex){
          //   messageErr = ex.message;
          // }
          // Возвращаемый объект
          signersTxt = {
            "SubjectName": SubjectName,
            "ValidFromDate": ValidFromDate,
            "ValidToDate": ValidToDate,
            "SerialNumber": SerialNumber,
            "IssuerName": IssuerName,
            "Version": Version,
            "SigningTime":SigningTime
          };
        } catch (ex) {
          messageErr = "Ошибка при получении аттрибутов подписи: " + ex.message;
          console.log(messageErr);
          return;
        }
      }
    return signersTxt
  } catch (error) {
    throw new Error(error.message);
  }
}


const checkQuotes = str => {
  let result = 0;

  for (let i = 0; i < str.length; i++) if (str[i] === '"') result++;
  return !(result % 2);
};

export const extract = (from, what) => {
  let certName = "";

  const begin = from.indexOf(what) + what.length;

  if (begin >= 0) {
    let end = from.indexOf(", ", begin);
    while (end > 0) {
      if (checkQuotes(from.substr(begin, end - begin))) break;
      end = from.indexOf(", ", end + 1);
    }
    certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
  }

  return certName;
};
