import request from './api'

export async function getUserAgrDogovors() {
    try {
      const res = await request('/api/agrdog/user-agrdogs','POST', null)
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getEvents(kodDog, period) {
    try {
      const res = await request('/api/agrdog/events','POST', {dogovor: kodDog, period: period})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getNoremEvents(objId, period) {
    try {
      const res = await request('/api/agrdog/norem-events','POST', {obj: objId, period: period})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getCountNextEvents() {
    try {
      const res = await request('/api/agrdog/next-events-count','POST', null)
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getEnergosferaCounter(date, type, countVals, agrdog) {
    try {
      const res = await request('/api/agrdog/energo-counter','POST', {date: date, type: type, count_vals: countVals, agrdog: agrdog})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getNoremMonth(objId, period) {
    try {
      const res = await request('/api/agrdog/norem-month','POST', {obj: objId, period: period})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getNoremDay(objId, day) {
    try {
      const res = await request('/api/agrdog/norem-day','POST', {obj: objId, day: day})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getChartMonth(period, agrdog, obj) {
    try {
      const res = await request('/api/agrdog/chart-month','POST', {period: period, dogovor: agrdog, obj: obj})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getChartDay(day, agrdog, obj) {
    try {
      const res = await request('/api/agrdog/chart-day','POST', {day: day, dogovor: agrdog, obj: obj})
    
      return {
          isOk: true,
          data: res
      }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}