export function getFirstDayOfMounth(date) {
    date.setDate(1)
    date.setUTCHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export function getLastDayOfMounth(date, isNextPeriod = true) {// берем или начало следующего периода, или последний день текущего
    //ставим начало следующего периода, чтобы захватить последний день месяца
    var d = new Date(date.getFullYear(), date.getUTCMonth() + 1, (isNextPeriod ? 2 : 1))
    d.setUTCHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
}

export function getYm(date) {
    date = new Date(date)
    const ym = date.getFullYear() + '.' + (date.getMonth() + 1).toString().padStart(2,'0')
    return ym
}

export function getFirstDayOfMounthFix(date) {
    var d = new Date(date.getFullYear(), date.getMonth(), 2)
    d.setUTCHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
}