import DataGrid, {Column, Grouping, Paging} from 'devextreme-react/data-grid'
import {Button} from "devextreme-react/button"
import {Popup} from "devextreme-react"
import { useState } from 'react'
import { useAuth } from '../../contexts/auth'
import './agregator-events.scss'
import DayChart from './day-chart'

const DayEvents = (props) => {

    const dayEvents = props.dayEvents
    const {selectedAgrdog} = useAuth()

    const [visiblePopup, setVisiblePopup] = useState(false)
    
    // const statusRender = (row) => {
    //     const data = row.data
    //     const colorMas = ['red','orange','green']
    //     let color = 'black'
    //     if (data.status !== null) color = colorMas[parseInt(data.status)]
    //     return (
    //         <span style={{color: color}}>{data.different}</span>
    //     )
    // }

    const statusRender = (e) => {
        const colorMas = ['#dd3444','orange','#5cb85b']
        if (e.rowType === 'data' && e.column.dataField === 'different'){
            if (e.data && e.data.status != null) {
                e.cellElement.style.backgroundColor = colorMas[parseInt(e.data.status)]
                e.cellElement.style.fontWeight = 'bold'
            } 
        }
    }

    const onClickProfile = async () => {
        setVisiblePopup(true)
    }

    const onHiddenPopup = () => {
        setVisiblePopup(false)
    }

    return (
        <>
        { dayEvents &&
            <>
                <span><b>События по выбранному дню:</b></span>
                <div className='mt10 mb10'>
                    <div className='row d-flex' style={{alignItems: 'baseline', marginLeft: 'auto'}}>
                        <div className='square error'></div>
                        <span className='square-span'> - Не выполнено;</span>
                        <div className='square warning'></div>
                        <span className='square-span'> - Выполнено частично;</span>
                        <div className='square success'></div>
                        <span className='square-span'> - Выполнено</span>
                    </div>
                    
                </div>
                <DataGrid
                    id="day-table"
                    dataSource={dayEvents}
                    //keyExpr="setId"
                    //columnHidingEnabled={true}
                    //allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    //onContentReady={this.onContentReady}
                    wordWrapEnabled={true}
                    noDataText="Не найдено событий в выбранном дне!"
                    onCellPrepared={statusRender}
                >
                    <Grouping autoExpandAll={false} />
                    <Column dataField="date" caption="Время" dataType="datetime" />
                    <Column dataField="label" caption="Снижение" dataType="string" />
                    <Column dataField="gbn" caption="Плановый ГБН, МВт/ч" dataType="string"/>
                    <Column dataField="fact" caption="Фактическое значение потребления, МВт/ч" dataType="string"/>
                    <Column dataField="different" caption="Объем снижения потребления, МВт/ч" dataType="string"/>

                    {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />  */}
                    <Paging defaultPageSize={24} pageSize={24}/>
                </DataGrid>
                <div className="row mt20">
                    <div className={'col-sm-4'}>
                        <Button type={'default'} text="Текущий профиль потребления" icon="chart" onClick={onClickProfile}/>
                    </div>
                </div>
                <Popup 
                    closeOnOutsideClick={true} 
                    showCloseButton={true} 
                    title={"Суммарный профиль потребления по договору №" + selectedAgrdog.ndog}
                    visible={visiblePopup}    
                    onHiding={onHiddenPopup}
                >
                    <DayChart dogovor={selectedAgrdog} visible={visiblePopup} day={dayEvents[0].date}/>
                </Popup>
            </>
        }
        </>
    )
}

export default DayEvents