//import { PointsTree } from "../../components/points-tree/points-tree"
import Form, { GroupItem, ButtonItem, ButtonOptions, Label } from 'devextreme-react/form'
import { useEffect, useState } from "react"
import { useAuth } from "../../contexts/auth"
import LoadPanel from 'devextreme-react/load-panel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box , { Item } from "devextreme-react/box"
import './energoobjects.scss'
import { getPoint, getPointPus } from '../../api/point.js'
import notify from "devextreme/ui/notify"
import { /*getDogovorPoints,*/ /*getCurrentPeriod,*/ getEnergoobject, /*getPointsTree,*/ getPointsTreePlain } from "../../api/dogovor"
import { useReqPopup } from "../../contexts/req-popup"
import {faBuilding, faGaugeHigh, faLocationDot, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import { verifyAccess } from "../../utils/admin.utils"
import AccessDenied from "../../components/access-denied/access-denied"
import {Button} from "devextreme-react/button"
import EnergosferaChart from "../../components/energosfera-chart/energosfera-chart"
import PointsTreeHook from "../../components/points-tree/point-tree-hook"

const Energoobjects = () => {

    const { selectedDog } = useAuth()
    const { openReqPopup, setSelectedTu } = useReqPopup()
    const [selectedObj, setSelectedObj] = useState({})
    const [selectedTu, setSelectedLocalTu] = useState({})
    const [visibleTu, setVisibleTu] = useState(false)
    const [visibleObj, setVisibleObj] = useState(false)
    const [pointPus, setPointPus] = useState({})
    const [loading, setLoading] = useState(false)
    const [points, setPoints] = useState(null)
    const [visibleEnergosfera, setVisibleEnergosfera] = useState(false)
    const [pointProfile, setPointProfile] = useState(null)
    // const [currPeriod1, setCurrPeriod1] = useState(new Date())
    // const [currPeriod2, setCurrPeriod2] = useState(new Date())
    const pointsType = 'all'

    const onTreeSelect = async (mas) => {
        const el = mas[0]
        //console.log(el)
    
        if (el && el.isG) { //если объект
            //const obj = el
            setVisibleObj(true)
            setVisibleTu(false)

            setLoading(true)
            const resObj = await getEnergoobject(el.id)
            if (resObj.isOk) setSelectedObj(resObj.data)
            else notify(resObj.message, 'error', 5000)
            setLoading(false)
            setPointPus()
        } else if (el && !el.isG) { //если точка
            setVisibleTu(true)
            setVisibleObj(false)        
            const kodPoint = el.id.split('_')[1]
            if (!kodPoint) return
            setLoading(true)
            const resPoint = await getPoint(kodPoint)
            if (resPoint.isOk) {
                setSelectedTu(resPoint.data.kodPoint)
                setSelectedLocalTu(resPoint.data)
                //достаем прибор учета
                const res = await getPointPus(kodPoint)
                if (res.isOk) {
                    setPointPus(res.data && res.data.kodPointPu ? res.data : null)
                }
                else {
                    notify(res.message, 'error', 5000)
                    setPointPus()
                }
            } else notify(resPoint.message, 'error', 5000)
        } else {
            setVisibleTu(false)
            setVisibleObj(false)
        }
        setLoading(false)
    }

    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [2]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function () {
            setVisiblePage(true)
            setVisibleTu(false)
            setVisibleObj(false)
            setPoints(null)
            setPointPus(null)
            //получаем точки по договору
            //const res = await getDogovorPoints(selectedDog.kodDog, pointsType)
            const resPointsTree = await getPointsTreePlain(selectedDog.kodDog, pointsType)
            if (resPointsTree.isOk) {
                setPoints(resPointsTree.data)
            } else {
                notify(resPointsTree.message, 'error', 5000)
            }
            // const resPeriod = await getCurrentPeriod(selectedDog.kodDog)
            // if (resPeriod.isOk) setCurrPeriod1(resPeriod.data.period)
         })()
    }, [selectedDog])

    function onOpenProfile() {
        if (selectedTu.existAskue){
            setVisibleEnergosfera(true)
            setPointProfile(selectedTu.kodPoint)
        } else {
            notify('Данная точка учета не подключена к сервису online-опроса счетчиков. Для подключения обратитесь к Вашему менеджеру по договору.', 'info', 8000)
        }
        
    }

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings big-input' /* d-flex bd-highlight'*/}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faMapLocationDot} />
            <h3><strong>Объекты энергоснабжения и точки учета</strong></h3>

            <LoadPanel visible={loading}/>
            <div className="row mt20">
                <div className="col-md-12">
                    {/* <PointsTree selectionMode="single" onSelect={onTreeSelect} typePoints={pointsType} points={points}/> */}
                    <PointsTreeHook points={points} onSelect={onTreeSelect} selectionMode="single"/>
                </div>
                <div className="col-md-12 mt20">
                    {
                        !visibleTu && !visibleObj
                            ?
                            <div>Выберите энергообъект или точку учета..</div>
                            :
                            <>
                                {visibleObj?
                                <div>
                                    <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faBuilding} />
                                    <h4><strong>Энергообъект:</strong></h4>
                                    <Form labelMode={'floating'} readOnly={true} formData={selectedObj}>
                                        <Item dataField="text"><Label text="Номер и наименование ЭО" /></Item>
                                        <Item dataField="adr"><Label text="Адрес ЭО" /></Item>
                                        <Item dataField="naznhouseName"><Label text="Назначение сооружения" /></Item>
                                        {/*<Item dataField="tarifName"><Label text="Тариф" /></Item>*/}
                                    </Form>
                                </div>:null}

                                {visibleTu?
                                <div className="mb20">
                                    <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faLocationDot} />
                                    <h4><strong>Точка учета:</strong></h4>
                                    <Form labelMode={'floating'} readOnly={true} formData={selectedTu}>
                                        <Item dataField="text"><Label text="Номер и наименование ТУ" /></Item>
                                        <Item dataField="namePwrcmp"><Label text="Принадлежность к сетевой компании" /></Item>
                                    </Form>
                                    <div className="row mt20 d-flex justify-content-end" style={{marginRight: 'auto'}}>
                                        <div className={'mb-2'}>
                                            <Button icon="chart" text="Текущий профиль потребления (online)" type={'default'} onClick={()=>onOpenProfile()}></Button>{/*disabled={selectedTu && !!!selectedTu.existAskue}*/}
                                            <EnergosferaChart visible={visibleEnergosfera} onClosed={()=> {setVisibleEnergosfera(false); setPointProfile(null)}} point={pointProfile} period1={new Date()} period2={new Date()}/>
                                        </div>
                                        <div className={'ml-2'}>
                                            <Button icon="edit" text="Редактировать информацию о ТУ" type={'default'} onClick={()=>{openReqPopup(true,6,null,[],[selectedTu])}}></Button>
                                        </div>
                                    </div>     
                                </div>:null}

                                {!!pointPus?
                                <div>
                                    <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faGaugeHigh} />
                                    <h4><strong>Прибор учета:</strong></h4>
                                    <Form labelMode={'floating'} readOnly={true} formData={pointPus}>
                                        <GroupItem caption="">
                                            <GroupItem colCount={3} >
                                                <Item dataField="numPu"><Label text="№ прибора учета" /></Item>
                                                <Item dataField="datS"><Label text="Дата установки" /></Item>
                                                <Item dataField="rkoeff"><Label text="Расчетный коэф." /></Item>
                                            </GroupItem>
                                            <GroupItem colCount={3}>
                                                <Item dataField="fullname"><Label text="Наименование" /></Item>
                                                <Item dataField="voltageName"><Label text="Уровень напряжения" /></Item>
                                                <Item dataField="mpi"><Label text="Межповерочный интервал (лет)"/></Item>
                                            </GroupItem> 
                                            <GroupItem colCount={4}>
                                                <Item dataField="godPp"><Label text="Год последней поверки" /></Item>
                                                <Item dataField="kvartalPp"><Label text="Квартал последней поверки"/></Item>
                                                <Item dataField="godOp"><Label text="Год очередной поверки"/></Item>
                                                <Item dataField="kvartalPp"><Label text="Квартал очередной поверки"/></Item>
                                            </GroupItem>
                                            <ButtonItem>
                                                <ButtonOptions
                                                    type={'default'}
                                                    disabled={false}
                                                    //useSubmitBehavior={true}
                                                    text="Сообщить о замене ПУ"
                                                    icon="edit"
                                                    onClick={()=>{openReqPopup(true,5,null,[],[selectedTu],true,pointPus)}}
                                                />
                                            </ButtonItem>
                                        </GroupItem>
                                    </Form>
                                </div>:null}
                            </>
                    }
                </div>
            </div>
            <Box
                direction={"row"}
                width="100%"
                //height={800}
            >

                
                <Item ratio={1} >
                    <div /*className="p-2 flex-shrink-1 bd-highlight"*/>

                    </div>
                </Item>
                <Item ratio={2}>

                </Item>
            </Box>
        </div>
        : <AccessDenied/>}</>
    )
}

export default Energoobjects
