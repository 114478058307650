import Form, { ButtonItem, ButtonOptions, Item, Label } from "devextreme-react/form"
import LoadIndicator from "devextreme-react/load-indicator"
import Popup from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import { useRef, useState } from "react"
import { sendHelpRequest } from "../../api/request"
import { useReqPopup } from "../../contexts/req-popup"
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faPenClip} from "@fortawesome/free-solid-svg-icons";

const HelpPopup = () => {

    const {visibleHelpPopup, setVisibleHelpPopup} = useReqPopup()
    const [loading, setLoading] = useState(false)
    const formData = useRef({})

    function hidingPopup() {
        setVisibleHelpPopup(false)
    }

    const onSendRequest = async (e) => {
        e.preventDefault()
        const {message, attachments} = formData.current

        if (!message) {
            notify('Введите текст сообщения!', 'error', 5000)
            return
        }

        setLoading(true)
        const fd = new FormData()
        fd.append('message', message)
        //прикрепляем к заявке вложения
        if (attachments)
            for (let i=0; i<attachments.length; i++) {
                fd.append('file',attachments[i])
            }

        const result = await sendHelpRequest(fd)
        if (result.isOk) { //если успешно создали заявку
            setVisibleHelpPopup(false)
            notify(result.message, 'success', 5000)
        } else {
            notify(result.message, 'error', 5000);
        }

        setLoading(false)
    }

    const messageOptions = {

    }

    const fileOptions = {
        selectButtonText: "Выберите файлы", 
        labelText: "Или перетащите файлы сюда",
        accept: "*/*",
        multiple: true,
        maxFileSize: 10000000,
        uploadMode: "useForm",
        //value: defaultFiles ? defaultFiles : []
    }

    return (
        <div>
            <Popup
                id="help-popup"
                visible={visibleHelpPopup}
                onHiding={hidingPopup}
                closeOnOutsideClick={true}
                height='auto'
                maxHeight="800px" //форма с прибором не входит никак иначе
                maxWidth="800px"
                title="Написать в тех. поддержку"
                showCloseButton={true}
                titleRender={()=>PopupTitleWithIcon(faPenClip, "Написать в тех. поддержку",hidingPopup)}
            >
                <ScrollView width='100%' height='100%'>
                    <form onSubmit={onSendRequest}>
                        <Form labelMode={'floating'} formData={formData.current}>
                            <Item dataField="message" editorType="dxTextArea" editorOptions={messageOptions}>
                                <Label text="Текст запроса"/>
                                {/* <RequiredRule message="Введите текст сообщения!"/> */}
                            </Item>
                            <Item helpText={'Вложения'} cssClass={'fileuploader-container'} dataField="attachments" editorType="dxFileUploader" editorOptions={fileOptions}>
                                <Label text="Вложения"/>
                            </Item>
                            <ButtonItem horizontalAlignment="left">
                                <ButtonOptions
                                    type={'default'}
                                    useSubmitBehavior={true}
                                    text="Отправить запрос"
                                    icon="email"
                                    disabled={loading}
                                >
                                </ButtonOptions>
                            </ButtonItem>
                        </Form>
                    </form>
                    <LoadIndicator width={'24px'} height={'24px'} visible={loading}/>
                </ScrollView>
            </Popup>
        </div>
    )
}

export default HelpPopup
