import { faCoins } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import DataGrid, { Column, Format, Grouping, Paging } from 'devextreme-react/data-grid'
import { LoadPanel } from 'devextreme-react/load-panel'
import { useAuth } from "../../contexts/auth"
import { getDolgSf } from "../../api/dogovor"
import notify from "devextreme/ui/notify"
import { verifyAccess } from "../../utils/admin.utils"
import AccessDenied from "../../components/access-denied/access-denied"

const DebtPage = () => {
    const {selectedDog} = useAuth()

    const [loadingDocs, setLoadingDocs] = useState(false)
    const [dataDocs, setDataDocs] = useState([])

    const [visiblePage, setVisiblePage] = useState(true)

    useEffect(() => {
        const pageRoles = [3,4]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            setLoadingDocs(true)
            //вытаксиваем документы по долгу
            if (selectedDog) {
                const resSf = await getDolgSf(selectedDog.kodDog)
                if (resSf.isOk) {
                setDataDocs(resSf.data)
                } else {
                notify(resSf.message,'error',10000)
                setDataDocs([])
                }
            }
            setLoadingDocs(false)
        })()
    },[selectedDog])

    return (
        <>{visiblePage ? 
        <div className={'content-block dx-card responsive-paddings'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faCoins} />
            <h3><strong>Задолженность</strong></h3>
            {!loadingDocs ? 
                <DataGrid
                    dataSource={dataDocs}
                    noDataText="Документов по задолженности не найдено"
                    keyExpr="kod_sf"
                    columnHidingEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    wordWrapEnabled={true} width={"100%"}
                >
                    <Grouping autoExpandAll={false} />

                    <Column dataField="ym" caption="Период выставления" dataType="string" width="120px" 
                        cellRender={e => {
                            return e.value.toString().endsWith('.1') ? e.value.toString() + '0' : e.value.toString()
                        }}
                    />
                    <Column dataField="name_doc" caption="Тип документа" dataType="string" width="200px"/>
                    {/* <Column dataField="name_kod_doc" caption="Тип документа" dataType="string" width="120px"/>
                    <Column dataField="vidrealcode" caption="Вид реализации" dataType="string" width="100px"/>                               */}
                    <Column dataField="nom_sf" caption="Номер документа начисления" dataType="string" width="150px"/>
                    <Column dataField="dat_sf" caption="Дата документа начисления" dataType="date" width="120px"/>           
                    <Column dataField="sum_nach" caption="Начислено с налогом" dataType="numeric" width="120px">
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>
                    <Column dataField="sum_opl" caption="Сумма оплаты" dataType="numeric" width="150px">
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>
                    <Column dataField="sum_dolg" caption="Задолженность" dataType="numeric" width="150px">
                        <Format type="currency" precision={2} currency={'RUB'}/>
                    </Column>
                    <Paging defaultPageSize={10} />
                </DataGrid>
                :
                <LoadPanel visible={true} />
            }
        </div>
        : <AccessDenied/>}</>
    )
}

export default DebtPage
